// Button.js
import React from 'react';

const Button = ({ icon, color, onClick }) => {
  return (
    <button
      className={`bg-${color}-500 hover:bg-${color}-600 text-white font-bold py-3 px-3 rounded-full shadow-md transition duration-300 ease-in-outn`}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        {icon}
      </svg>
    </button>
  );
};

export default Button;
