import React from 'react';
import { Document, Page, Text, View, StyleSheet, pdf, Font } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { useAuthStore } from 'store/use-auth.store';
import { FaFilePdf } from 'react-icons/fa'; 

// Define font
Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf' },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', fontWeight: 500 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ],
});

const Header = ({ title, currentDate, createdBy , totalRecord}) => (
  <View style={styles.header}>
    <Text style={styles.title}>DAWEO</Text>
    <Text style={styles.title}>{title}</Text>
    <View style={styles.userInfo}>
      <Text style={styles.userInfoText}>Date: {currentDate}</Text>
      <Text style={styles.userInfoText}>Total Records: {totalRecord}</Text>
    </View>
  </View>
);

const DownloadPDF = ({ DownloadData, headers, keys, title, pdfName ,totalRecord }) => {
  const { user } = useAuthStore();
  const currentDate = new Date().toLocaleDateString();
  const createdBy = user?.username;

  const filteredData = DownloadData?.map(item => {
    const filteredItem = {};
    headers?.forEach((header, index) => {
      const key = keys[index];
      const keyParts = key?.split('.');
      let value = item;
      keyParts?.forEach(part => {
        value = value?.[part];
      });
      filteredItem[header] = value;
    });
    return filteredItem;
  });

  const handleDownloadPDF = async () => {
    const pdfDoc = (
      <Document>
        <Page style={styles.page} orientation="landscape">
          <View style={styles.table}>
            <Header title={title} currentDate={currentDate} createdBy={createdBy}  totalRecord={totalRecord}  />
            <View fixed style={styles.headerRow}>
              {headers?.map((header, index) => (
                <Text key={index} style={styles.headerCell}>{header}</Text>
              ))}
            </View>
            {filteredData.map((item, rowIndex) => (
              <View key={rowIndex} style={styles.row} wrap={false}>
                {headers?.map((header, colIndex) => (
                  <Text key={colIndex} style={styles.cell}>
                    {header === 'client_cases' && Array.isArray(item[header]) ? (
                      item[header]?.map((caseItem, caseIndex) => (
                        <Text key={caseIndex}>
                          {caseItem?.propertyName}: {caseItem?.propertyValue}
                        </Text>
                      ))
                    ) : (
                      item[header]
                    )}
                  </Text>
                ))}
              </View>
            ))}
          </View>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
        </Page>
      </Document>
    );
    const blob = await pdf(pdfDoc).toBlob();
    saveAs(blob, `${pdfName}.pdf`);
  };

  return (
    <div>
      <button onClick={handleDownloadPDF} style={{ background: 'none', border: 'none', cursor: 'pointer', outline: 'none', display: 'flex', alignItems: 'center' }}>
        <FaFilePdf size={30} color="red" /> {/* Render PDF icon */}
        <span style={{ marginLeft: '0.5rem' }}>PDF File</span> {/* Text indicating PDF download */}
      </button>
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 5,
    flexDirection: 'row',
    fontFamily: 'Roboto',
  },
  header: {
    marginBottom: 10
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 10
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderCollapse: 'collapse',
    width: '100%'
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: 'rgb(199, 210, 254)'
  },
  headerCell: {
    flex: 1,
    padding: 2,
    fontWeight: 'bold',
    border: '1px solid #000',
    textAlign: 'center',
    fontSize: 8
  },
  row: {
    flexDirection: 'row',
    breakInside: 'avoid'
  },
  cell: {
    flex: 1,
    padding: 2,
    border: '1px solid #000',
    textAlign: 'center',
    fontSize: 8
  },
  userInfo: {
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  userInfoText: {
    fontSize: 10
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  }
});

export default DownloadPDF;
