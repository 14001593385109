import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  ModalBody,
  Box,
  Text,
  Divider,
  Flex,
  Heading,
  useToast 
} from "@chakra-ui/react";
import { FaFilePdf } from "react-icons/fa";

export default function ViewPssModal({ isOpen, onClose, beneficiary }) {
  const toast = useToast();
  const handleClick = (e) => {
    if (!beneficiary?.form_attachment) {
      e.preventDefault();
      toast({
        title: "No File Attachment",
        description: "There is no file attachment for this record.",
        position: 'top',
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleClickAttach = (e) => {
    if (!beneficiary?.beneficiaryCase[0]?.case_attachment) {
      e.preventDefault();
      toast({
        title: "No File Attachment",
        description: "There is no file attachment for this record.",
        position: 'top',
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  if (!beneficiary) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="4xl">
      <ModalOverlay />
      <ModalContent bg="white" borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          PSS Beneficiary Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={{ base: "column", md: "row" }}>
            {/* First Column */}
            <Box flex="1" pr={{ md: 4 }}>
              <Box mb={6}>
                <Text fontWeight="bold">Name:</Text>
                <Text>{beneficiary?.name}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Father's Name:</Text>
                <Text>{beneficiary?.father_name}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Age:</Text>
                <Text>{beneficiary?.age}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Gender:</Text>
                <Text>{beneficiary?.gender}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Marital Status:</Text>
                <Text>{beneficiary?.marital_status}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Educational Degree:</Text>
                <Text>{beneficiary?.educational_degree}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Address:</Text>
                <Text>{beneficiary?.address}</Text>
              </Box>
            </Box>
            {/* Second Column */}

            <Box flex="1" pl={{ md: 4 }}>
              <Box mb={6}>
                <Text fontWeight="bold">Phone:</Text>
                <Text>{beneficiary?.phone}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Job:</Text>
                <Text>{beneficiary?.job}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Tazkira Number:</Text>
                <Text>{beneficiary?.tazkira_number}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Total Family Members:</Text>
                <Text>{beneficiary?.total_family_members}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Female Family Members:</Text>
                <Text>{beneficiary?.female_family_members}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Male Family Members:</Text>
                <Text>{beneficiary?.male_family_members}</Text>
              </Box>
              <Divider />
              <Box mb={6} mt={6}>
                <Text fontWeight="bold">Form Attachment:</Text>
                <Button
                  as="a"
                  href={beneficiary?.form_attachment}
                  target="_blank"
                  leftIcon={<FaFilePdf />}
                  variant="link"
                  colorScheme="blue"
                  size="lg"
                  onClick={handleClick}
                >
                  View Form Attachment
                </Button>
              </Box>
            </Box>
          </Flex>
          {/* Displaying Beneficiary Cases */}
          <Box mt={6}>
            <Divider />
            <Heading
              className="pt-8 pb-5 text-center"
              fontWeight="bold"
              fontSize="lg"
            >
              Beneficiary Case
            </Heading>
            {beneficiary.beneficiary_cases.map((beneficiaryCase) => (
              <Flex flexDirection={{ base: "column", md: "row" }}>
                {/* First Column */}
                <Box flex="1" pr={{ md: 4 }}>
                  <Box mb={6}>
                    <Text fontWeight="bold">Registration Date:</Text>
                    <Text>{beneficiaryCase?.registration_date}</Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Contact Type:</Text>
                    <Text>{beneficiaryCase?.contact_type}</Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Used Tool:</Text>
                    <Text>{beneficiaryCase?.used_tool}</Text>
                  </Box>
                  <Divider />

                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">
                      Appropriate Psychological Intervention:
                    </Text>
                    <Text>
                      {beneficiaryCase?.appropriate_psychological_intervention}
                    </Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Intervention Type:</Text>
                    <Text>{beneficiaryCase?.intervention_type}</Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Psychological Issue Type:</Text>
                    <Text>{beneficiaryCase?.psychological_issue_type}</Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Case Description:</Text>
                    <Text>{beneficiaryCase?.case_description}</Text>
                  </Box>
                </Box>
                {/* Second Column */}

                <Box flex="1" pl={{ md: 4 }}>
                  <Box mb={6}>
                    <Text fontWeight="bold">Doctor:</Text>
                    <Text>
                      {beneficiaryCase?.doctor?.name}{" "}
                      {beneficiaryCase?.doctor?.lastname}
                    </Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Examination Result:</Text>
                    <Text>{beneficiaryCase?.examination_result}</Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Case Status:</Text>
                    <Text>{beneficiaryCase?.case_status}</Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Closing Date:</Text>
                    <Text>{beneficiaryCase?.closing_date}</Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Referred Organization:</Text>
                    <Text>{beneficiaryCase?.referred_organization}</Text>
                  </Box>
                  <Divider />
                  <Box mb={6} mt={6}>
                    <Text fontWeight="bold">Referred Description:</Text>
                    <Text>{beneficiaryCase?.referred_description}</Text>
                  </Box>
                  <Divider />
                  <Box
                    mb={6}
                    mt={6}
                  >
                    <Text fontWeight="bold">Case Attachment:</Text>

                    <Button
                      as="a"
                      href={beneficiaryCase?.case_attachment}
                      target="_blank"
                      leftIcon={<FaFilePdf />}
                      variant="link"
                      colorScheme="blue"
                      size="lg"
                      onClick={handleClickAttach}
                    >
                      View Attachment
                    </Button>
                  </Box>
                </Box>
              </Flex>
            ))}
            {beneficiary?.beneficiary_cases.map((beneficiaryCase) => (
              <Box key={beneficiaryCase?.id} mt={4}>
                {/* Displaying Visits */}
                <Box mt={6}>
                  <Divider></Divider>
                  <Heading
                    fontWeight="bold"
                    className="pt-8 text-center"
                    fontSize="lg"
                  >
                    Doctor Visits
                  </Heading>
                  {beneficiaryCase?.visits.map((visit, index) => (
                    <Box key={visit.id} mt={2}>
                      <Text fontWeight="bold" className="py-2">
                        Visit {index + 1}
                      </Text>
                      <Flex flexDirection={{ base: "column", md: "row" }}>
                        {/* First Column */}
                        <Box flex="1" pr={{ md: 4 }}>
                          <Box mb={6}>
                            <Text fontWeight="bold">Visit Date:</Text>
                            <Text> {visit?.date}</Text>
                          </Box>
                          <Divider />
                          <Box height={20} mb={6} mt={6}>
                            <Text fontWeight="bold">Main Activity:</Text>
                            <Text> {visit?.main_activity}</Text>
                          </Box>

                          <Divider />
                        </Box>
                        {/* Second Column */}

                        <Box flex="1" pl={{ md: 4 }}>
                          <Box mb={6}>
                            <Text fontWeight="bold">Session Duration:</Text>
                            <Text>{visit?.session_duration}</Text>
                          </Box>
                          <Divider />
                          <Box height={20} mb={6} mt={6}>
                            <Text fontWeight="bold">Result:</Text>
                            <Text> {visit?.result}</Text>
                          </Box>
                          <Divider />
                        </Box>
                      </Flex>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
          {/* Displaying Branch Details */}
          <Box mt={6}>
            <Divider></Divider>
            <Heading
              fontWeight="bold"
              className="pt-8 text-center"
              fontSize="lg"
            >
              Other details
            </Heading>
            <Flex flexDirection={{ base: "column", md: "row" }}>
              {/* First Column */}
              <Box flex="1" pr={{ md: 4 }}>
                <Box my={6}>
                  <Text fontWeight="bold">Branch Name:</Text>
                  <Text>{beneficiary?.branch?.branch_name}</Text>
                </Box>
                <Divider />
              </Box>
              {/* Second Column */}

              <Box flex="1" pl={{ md: 4 }}>
                <Box my={6}>
                  <Text fontWeight="bold">Created by:</Text>
                  <Text>{beneficiary?.created_by?.username}</Text>
                </Box>
                <Divider />
              </Box>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
