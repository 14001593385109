import { Box, Text, VStack, HStack, Stat, StatNumber } from '@chakra-ui/react';
import { Card } from '@chakra-ui/card';
import React from 'react';
import { FaUsers } from 'react-icons/fa';

const colorToGradient = {
    blue: 'linear(to-br, blue.400, blue.600)',
    green: 'linear(to-br, green.400, green.600)',
    red: 'linear(to-br, red.400, red.600)',
    purple: 'linear(to-br, purple.400, purple.600)',
    cyan: 'linear(to-br, cyan.400, cyan.600)',
    orange: 'linear(to-br, orange.600, orange.700)',
};

function CardDesign({ title, totalLabel, totalCount, beneficiaryLabel, beneficiaryCount, bgColor }) {
    const gradient = colorToGradient[bgColor] || colorToGradient['blue'];

    return (
        <Box className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
            <Card p={6} rounded="lg" boxShadow="lg" bgGradient={gradient} color="white">
                <Text fontSize="2xl" fontWeight="bold" mb={6} textAlign="center">{title}</Text>
                <VStack spacing={6} mt={6}>
                    <HStack
                        className="rounded-lg bg-white bg-opacity-25 p-4 w-full justify-between"
                        spacing={4}
                    >
                        <Box className="flex items-center">
                            <FaUsers size="24" className="text-gray-200 mr-3" />
                            <Text fontSize="lg" fontWeight="semibold" color="gray.200">{totalLabel}</Text>
                        </Box>
                        <Stat>
                            <StatNumber fontSize="2xl" fontWeight="bold">{totalCount + " " + "Persons"}</StatNumber>
                        </Stat>
                    </HStack>
                    <HStack
                        className="rounded-lg bg-white bg-opacity-25 p-4 w-full justify-between"
                        spacing={4}
                    >
                        <Box className="flex items-center">
                            <FaUsers size="24" className="text-gray-200 mr-3" />
                            <Text fontSize="lg" fontWeight="semibold" color="gray.200">{beneficiaryLabel} </Text>
                        </Box>
                        <Stat>
                            <StatNumber fontSize="2xl" fontWeight="bold">{beneficiaryCount}</StatNumber>
                        </Stat>
                    </HStack>
                </VStack>
            </Card>
        </Box>
    );
}

export default CardDesign;
