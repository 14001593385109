import React from "react";
import { useToast } from "@chakra-ui/react";

const ToastNotification = ({ status, title, description }) => {
  const toast = useToast();

  React.useEffect(() => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  }, [status,title, description, toast]);

  return null;
};

export default ToastNotification;