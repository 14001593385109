import React from "react";
import Chart from "react-apexcharts";

const GeneralPieChart = ({ data, title, colors , total , totalTitle }) => {
  const labels = data.map(({ label }) => label);
  const values = data.map(({ value }) => parseFloat(value));

  return (
    <div>
       <h2 className="text-xl font-semibold mb-4" style={{ padding:"15px" }}>{title}</h2>
      <Chart
        options={{
          labels: labels,
          colors: colors,
          legend: {
            show: true,
            position: "bottom",
          },
        }}
        series={values}
        type="pie"
        width="100%"
        height="300px"
      />
      <h5 className="mb-4 mt-2" style={{ paddingLeft:"70px" }} > {totalTitle} : {total}</h5>
    </div>
  );
};

export default GeneralPieChart;
