import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { postRequest } from "utils/http";
import { deleteRequest } from "utils/http";
import { getRequest } from "utils/http";

export const getMnEData = async (
  current,
  pageSize,
  searchValue,
  status,
  filterDateRange,
  gender,
  branch
) => {
  return await getRequest("app/monitoring-evaluation", {
    current: current,
    pageSize: pageSize,
    searchValue,
    status,
    filterDateRange,
    gender,
    branch,
  });
};

export const useGetMnUData = (
  current,
  pageSize,
  searchValue,
  status,
  filterDateRange,
  gender,
  branch
) => {
  return useQuery({
    queryKey: [
      "fetch-MnE",
      current,
      pageSize,
      searchValue,
      status,
      filterDateRange,
      gender,
      branch,
    ],
    queryFn: () =>
      getMnEData(
        current,
        pageSize,
        searchValue,
        status,
        filterDateRange,
        gender,
        branch
      ),
  });
};

// get M&E Officer data

export const getMnEOfficers = async () => {
  return await getRequest("app/getMnEOfficers");
};
export const useGetMnEOfficers = () => {
  return useQuery({
    queryKey: ["fetch-MnEOfficers"],
    queryFn: () => getMnEOfficers(),
  });
};
// get Provincial Manager data

export const getProvincialManager = async () => {
  return await getRequest("app/getProvincialManager");
};
export const useGetProvincialManager = () => {
  return useQuery({
    queryKey: ["fetch-ProvincialManager"],
    queryFn: () => getProvincialManager(),
  });
};

export const MnECreate = (params) => {
  return postRequest(`app/monitoring-evaluation`, params);
};

export const useMnECreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => MnECreate(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-MnE"]);
    },
  });
};

export const useMnESingleDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => singleDelete(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-MnE"]);
    },
  });
};

const singleDelete = async (id) => {
  return deleteRequest(`app/monitoring-evaluation/${id}`, {
    params: {
      id,
    },
  });
};

export const deleteMnErecords = async (ids) => {
  return deleteRequest(`app/deleteMnErecords`, {
    params: {
      ids: ids.join(","),
    },
  });
};

export const useMnEMultiDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids) => deleteMnErecords(ids),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-MnE"]);
    },
  });
};

export const pssBeneficiaryUpdate = (params) => {
  return postRequest(`app/pss-beneficiary/${params.get("id")}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const usePssBeneficiaryUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => pssBeneficiaryUpdate(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-PssBeneficiaries"]);
    },
  });
};

export const MnEUpdate = (params) => {
  return postRequest(`app/monitoring-evaluation/${params.get("id")}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useMnEUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => MnEUpdate(params),
    onSuccess: () => {
      queryClient.invalidateQueries("fetch-MnE");
    },
  });
};
