import React from 'react'

function SquareButton({ color, iconName, text, onClick }){
  return (
    <button className={`bg-${color}-500 hover:bg-${color}-700 text-white font-bold py-2 px-4 rounded`}
        onClick={onClick}
    >
     <i className="fas fa-user mb-1"></i>
      {text}
    </button>
  )
}

export default SquareButton