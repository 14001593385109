import axios from 'axios'
import { logout, useAuthStore } from 'store/use-auth.store';

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

/**
* Add a request interceptor
*/
http.interceptors.request.use((request) => {
  if (!request.headers) {
    request.headers = {};
  }
  const token = useAuthStore.getState().token;
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});


/**
 * Add a response interceptor
 */
http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      logout();
    }
    if (error.response.status === 403) {

    }
  }
);




/**
* Create a http requests
*/
export async function getRequest(endpoint, params) {
  const response = await http.get(endpoint, {
    params: { ...params },
  });
  return response.data;

}

export async function postRequest(endpoint, params) {
  const response = await http.post(endpoint, params);

  return response.data;

}
export async function deleteRequest(endpoint, params) {
  const response = await http.delete(endpoint, params);

  return response.data;

}
export async function updateRequest(endpoint, params) {
  const response = await http.update(endpoint, params);

  return response.data;

}
