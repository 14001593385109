import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useAuthStore } from "store/use-auth.store";
import { useFemaleDoctorsCreate } from "queries/female_doctors.query";
import { useGetFemaleDoctor } from "queries/female_doctors.query";
import DatePicker from "react-datepicker";

function AddFemaleDoctors({ isOpen, onClose, onSuccess, checkData }) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useFemaleDoctorsCreate();
  const { data: doctors } = useGetFemaleDoctor();
  const { user } = useAuthStore();
  const toast = useToast();
  const [diagnosisDate, setDiagnosisDate] = useState(null);
  const handleDiagnosisDateChange = (date) => {
    setDiagnosisDate(date);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      const formattedDiagnosisDate = diagnosisDate
        ? `${diagnosisDate.getFullYear()}-${(diagnosisDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${diagnosisDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : "";
      formData.append("diagnosis_date", formattedDiagnosisDate);
      if (data.form_attachment) {
        formData.append("form_attachment", data.form_attachment[0]); // Append only the first file if multiple files are selected
      }
      mutate(formData, {
        onSuccess: () => {
          showToast({
            status: "success",
            description: "Female Doctor added successfully",
          });
          onSuccess();
          setIsLoading(false);
          onClose();
          reset();
        },
        onError: (error) => {
          setIsLoading(false);
          showToast({
            status: "error",
            description: "Failed to add Female Doctor",
          });
        },
      });
    } catch (error) {
      showToast({
        status: "error",
        description: "Failed to add Female Doctor",
      });
      setIsLoading(false);
    }
  };
  const showToast = ({ status, description }) => {
    toast({
      title: status === "success" ? "Success" : "Error",
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right", // Set the position to "top"
    });
  };

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          Creating New Female Doctor Record
        </ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose();
            reset();
          }}
        />
        <ModalBody style={{ overflowX: "auto", maxHeight: "650px" }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto max-w-screen-xl rounded bg-white p-4 shadow-md"
          >
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">
                  Name <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="name"
                  placeholder="Name"
                  {...register("name", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.father_name}>
                <FormLabel htmlFor="father_name">
                  Father's Name <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="father_name"
                  placeholder="Father's Name"
                  {...register("father_name", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.father_name && errors.father_name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.age}>
                <FormLabel htmlFor="age">
                  Age <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="age"
                  placeholder="Age"
                  {...register("age", {
                    required: "This is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid age",
                    },
                    maxLength: {
                      value: 3,
                      message: "Age should be at most 3 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.age && errors.age.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.address}>
                <FormLabel htmlFor="address">
                  Address <span className="text-red-500">*</span>
                </FormLabel>
                <Textarea
                  id="address"
                  placeholder="Address"
                  {...register("address", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>

              
              <FormControl isInvalid={errors.project}>
                <FormLabel htmlFor="project">
                  Project <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="project"
                  placeholder="Select project"
                  {...register("project", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="UN WOMEN">UN WOMEN</option>
                  <option value="UNDP">UNDP</option>
                  <option value="IDLO">IDLO</option>
                </Select>
                <FormErrorMessage>
                  {errors.project && errors.project.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.visit_frequency}>
                <FormLabel htmlFor="visit_frequency">
                  Visit Frequency <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="visit_frequency"
                  placeholder="visit frequency"
                  {...register("visit_frequency", {
                    required: "This is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid visit frequency",
                    },
                   
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.visit_frequency && errors.visit_frequency.message}
                </FormErrorMessage>
              </FormControl>

              
              <FormControl isInvalid={errors.diagnosis}>
                <FormLabel htmlFor="diagnosis">
                  Diagnosis <span className="text-red-500">*</span>
                </FormLabel>
                <Textarea
                  id="Diagnosis"
                  placeholder="diagnosis"
                  {...register("diagnosis", {
                    required: "This is required",
                  })}
                  className="input-field"
                  style={{ height: "100px" }}
                />
                <FormErrorMessage>
                  {errors.diagnosis && errors.diagnosis.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.prescription}>
                <FormLabel htmlFor="prescription">
                  Prescription <span className="text-red-500">*</span>
                </FormLabel>
                <Textarea
                  id="prescription"
                  placeholder="Prescription"
                  {...register("prescription", {
                    required: "This is required",
                  })}
                  className="input-field"
                  style={{ height: "100px" }}
                />
                <FormErrorMessage>
                  {errors.prescription && errors.prescription.message}
                </FormErrorMessage>
              </FormControl>
              {(user?.role === "ADMIN" ||
                (user?.role === "MANAGER" &&
                  user?.branch?.branch_name === "KABUL")) && (
                <FormControl isInvalid={errors.branch_id}>
                  <FormLabel htmlFor="branch_id">
                    Branch <span className="text-red-500">*</span>
                  </FormLabel>
                  <Select
                    id="branch_id"
                    placeholder="Select Branch"
                    {...register("branch_id", {
                      required: "This is required",
                    })}
                    className="input-field"
                  >
                    <option value="1">Kabul</option>
                    <option value="2">Bamyan</option>
                    <option value="3">Paktia</option>
                  </Select>
                  <FormErrorMessage>
                    {errors.branch_id && errors.branch_id.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              {(user?.role === "ADMIN" || user?.role === "MANAGER") && (
                <FormControl isInvalid={errors.doctor_id}>
                  <FormLabel htmlFor="doctor_id">
                    Female Doctor<span className="text-red-500">*</span>
                  </FormLabel>
                  <Select
                    id="doctor_id"
                    placeholder="Select Female Doctor "
                    {...register("doctor_id", {
                      required: "This is required",
                    })}
                    className="input-field"
                  >
                    {doctors?.map((doctorItem) => (
                      <option key={doctorItem.id} value={doctorItem.id}>
                        {doctorItem.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.doctor_id && errors.doctor_id.message}
                  </FormErrorMessage>
                </FormControl>
              )}

              <FormControl isInvalid={errors.diagnosis_date}>
                <FormLabel htmlFor="diagnosis_date">Diagnosis Date</FormLabel>
                <DatePicker
                  id="diagnosis_date"
                  selected={diagnosisDate}
                  onChange={handleDiagnosisDateChange}
                  placeholderText="Select Class End Date"
                  dateFormat="yyyy-MM-dd"
                  className="input-field"
                  customInput={<Input />}
                />
                <FormErrorMessage>
                  {errors.diagnosis_date && errors.diagnosis_date.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.form_attachment}>
                <FormLabel htmlFor="form_attachment">Form Attachment</FormLabel>
                <Input
                  id="form_attachment"
                  type="file"
                  {...register("form_attachment", {})}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.form_attachment && errors.form_attachment.message}
                </FormErrorMessage>
              </FormControl>
            </div>
            <div className="mt-8 flex flex-row-reverse">
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
              <Button
                colorScheme="teal"
                isLoading={isSubmitting || isLoading} 
                type="submit"
                className=" mr-2"
              >
                Submit
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AddFemaleDoctors;
