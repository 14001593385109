import { Button, ChakraProvider, Menu, MenuButton, MenuItem, MenuList, Spinner, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "components/card";
import FilterButton from "components/Buttons/FilterButton";
import CreateButton from "components/Buttons/CreateButton";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import ViewButton from "components/Buttons/ViewButton";
import DataTable from "components/DataTable/DataTable";
import CustomPagination from "components/CustomPagenation/CustomPagenation";
import { FiSearch } from "react-icons/fi";
import DeleteConfirmation from "components/DeleteConfirmation/DeleteConfirmation";
import { useGetLegalAdvisorsData } from "queries/legal_advisor.query";
import { useSingleDelete } from "queries/legal_advisor.query";
import { useMultiDelete } from "queries/legal_advisor.query";
import AddLegalAdivsor from "./components/add.legalAdvisor.form";
import EditLegalAdvisor from "./components/edit.leagalAdvisor.form";
import ViewLegalAdvisorModal from "./components/view.legalAdvisor.modal";
import DownloadPDF from "views/DownloadPDF";
import FilterModal from "components/filter/filter";
import { useAuthStore } from "store/use-auth.store";
import DownloadExcel from "views/DownloadExcel";
import { ChevronDownIcon } from "@chakra-ui/icons";


function LegalAdvisor() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    searchValue: null,
    status: [],
    filterDateRange: null,
    gender: [],
    branch: [],
  });
  const { data, isLoading, isError } = useGetLegalAdvisorsData(
    pagination.current,
    pagination.pageSize,
    pagination.searchValue,
    pagination.status,
    pagination.filterDateRange,
    pagination.gender,
    pagination.branch
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const { mutate: singleDeleteMutation } = useSingleDelete();
  const { mutate: multiDeleteMutation } = useMultiDelete();
  const [selectedLegalAdvisor, setSelectedLegalAdvisor] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { user } = useAuthStore();


  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const DownloadData = data?.data;
  const pdfName = "legal_advisor";
  const xlFile = "legal_advisor";
  const totalRecord = data?.data?.length;
  const legal_status = [
    { title: "PENDING", value: "PENDING" },
    { title: "IN PROGRESS", value: "IN PROGRESS" },
    { title: "CLOSED", value: "CLOSED" },
  ];

 
  const onPageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
  };

  const onPageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value });
  };

  const onSelectRow = (selectedRows) => {
    setSelectedRowKeys(selectedRows);
  };
  const onSuccessCreate = () => {
    setPagination({ ...pagination, current: 1 });
  };
  const onSuccessEdit = () => {
    setEditSuccess(!editSuccess); // Toggle editSuccess to trigger reload
    setSelectedRowKeys([]);
  };

  const onCloseAlert = () => {
    setAlertOpen(false);
  };
  const handleEdit = () => {
    if (selectedRowKeys.length === 1) {
      const selectedLegalAdvisor = data?.data.find(
        (employee) => employee.id === selectedRowKeys[0]
      );
      setSelectedLegalAdvisor(selectedLegalAdvisor);
      setEditModalOpen(true);
    } else if(selectedRowKeys.length > 1){
      toast({
        title: "Selected More Than One",
        description: "Please select Exactly one record to Edit.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }else {
      toast({
        title: "NO Recode Selected",
        description: "Please Selete At Least One Record To Edit.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleDelete = () => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.length === 1) {
        setConfirmMessage("Are you sure you want to delete this record?");
      } else {
        setConfirmMessage(
          `Are you sure you want to delete ${selectedRowKeys.length} records?`
        );
      }
      setAlertOpen(true);
    } else {
      toast({
        title: "NO Recode Selected",
        description: "Please select at least one record to delete.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleFilter = () => {
    setFilterModalOpen(true);
  };
  const onSearch = (e) => {
    if (e.target.value.trim() === "") {
      setSearchInput(e.target.value);
      setPagination({ ...pagination, searchValue: e.target.value });
    }
    if (e.key === "Enter") {
      setSearchInput(e.target.value);
      setPagination({ ...pagination, searchValue: e.target.value });
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.length === 1) {
        singleDeleteMutation(selectedRowKeys[0], {
          onSuccess: () => {
            toast({
              title: "Delete",
              description: "One record was successfully deleted.",
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
            setSelectedRowKeys([]);
            onCloseAlert();
          },
          onError: () => {
            toast({
              title: "Delete",
              description: "Failed to delete the record.",
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          },
        });
      } else {
        multiDeleteMutation(selectedRowKeys, {
          onSuccess: () => {
            toast({
              title: "Delete",
              description: `${selectedRowKeys.length} Records were successfully  deleted.`,
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
            setSelectedRowKeys([]);
            onCloseAlert();
          },
          onError: () => {
            toast({
              title: "Delete",
              description: `${selectedRowKeys.length} Failed to delete the records.`,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          },
        });
      }
    } else {
      toast({
        title: "Delete",
        description: "Please select at least one record to delete.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const branchSpecificHeaders =
    user.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name ==="KABUL") ? ["BRANCH"] : [];
  const branchSpecificKeys =
    user.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name ==="KABUL") ? ["branch.branch_name"] : [];
  const headers = [
    "ID",
    "NAME",
    "FATHER NAME",
    "LASTNAME",
    "AGE",
    "MARITAL STATUS",
    "FAMILY MEMBERS#",
    'SIDE NAME' ,
    'REG DATE',
    'CON DATE',
    'STATUS',
    ...branchSpecificHeaders
  ];

  const keys = [
    "id",
    "name",
    "father_name",
    "last_name",
    "age",
    "marital_status",
    "total_family_members",
    'client_cases.0.opposite_side.name',
    'client_cases.0.registration_date',
    'client_cases.0.consultancy_date',
    'client_cases.0.status',
    ...branchSpecificKeys
  ];
  const Dheaders = [
    "NAME",
    "FATHER NAME",
    "LASTNAME",
    'SIDE NAME' ,
    'REG DATE',
    'CON DATE',
    'STATUS',
    "FAMILY VIOLENCE ROOTS",
    "VIOLENCE DIAGNOSIS",
    'PROVIDE LEGAL ADVICE',
    'ADVICE RESULT', 
    ...branchSpecificHeaders
  ];
  const Dkeys = [
    "name",
    "father_name",
    "last_name",
    'client_cases.0.opposite_side.name',
    'client_cases.0.registration_date',
    'client_cases.0.consultancy_date',
    'client_cases.0.status',
    'client_cases.0.family_violence_roots',
    'client_cases.0.violence_diagnosis',
    'client_cases.0.provided_legal_advice',
    'client_cases.0.advice_result',
    ...branchSpecificKeys
  ];
  const Eheaders =[
    "NAME",
    "FATHER NAME",
    "LASTNAME",
    "GRAND FATHER NAME",
    "AGE",
    "PHONE",
    "JOB",
    "MARITAL STATUS",
    "EDUCATION DEGREE",
    "BIRTH PLACE",
    "FAMILY MEMBERS#",
    "MALE MEMBERS#",
    "FEMALE MEMBERS#",
    "MAIN RECIDENCE",
    "CURRENT RECIDENCE",
    'SIDE NAME' ,
    "SIDE FATHER NAME",
    "SIDE LASTNAME",
    "SIDE GRAND FATHER NAME",
    "SIDE AGE",
    "SIDE PHONE",
    "SIDE JOB",
    "SIDE MAIN RECIDENCE",
    "SIDE CURRENT RECIDENCE",
    "ADVISOR NAME",
    'REG DATE',
    'CON DATE',
    "FAMILY VIOLENCE ROOTS",
    "VIOLENCE DIAGNOSIS",
    "FIRST MEETING",
    "SECOND MEETING",
    "THIRD MEETING",
    "FOURTH MEETING",
    "PROVIEDED LEGAL ADVICE",
    "ADVICE RESULT",
    'STATUS',
    ...branchSpecificHeaders
  ];
  const Ekeys = [
    "name",
    "father_name",
    "last_name",
    "grand_father_name",
    "age",
    "phone",
    "job",
    "marital_status",
    "educational_degree",
    "birth_place",
    "total_family_members",
    "male_family_members",
    "female_family_members",
    "main_residence",
    "current_residence",
    'client_cases.0.opposite_side.name',
    'client_cases.0.opposite_side.father_name',
    'client_cases.0.opposite_side.last_name',
    'client_cases.0.opposite_side.grand_father_name',
    'client_cases.0.opposite_side.age',
    'client_cases.0.opposite_side.phone',
    'client_cases.0.opposite_side.job',
    'client_cases.0.opposite_side.main_residence',
    'client_cases.0.opposite_side.current_residence',
    'client_cases.0.legal_advisor.name',
    'client_cases.0.registration_date',
    'client_cases.0.consultancy_date',
    'client_cases.0.family_violence_roots',
    'client_cases.0.violence_diagnosis',
    'client_cases.0.first_meeting',
    'client_cases.0.second_meeting',
    'client_cases.0.third_meeting',
    'client_cases.0.fourth_meeting',
    'client_cases.0.provided_legal_advice',
    'client_cases.0.advice_result',
    'client_cases.0.status',
    ...branchSpecificKeys
  ];

  const title = "Legal Advisor";

  const handleView = () => {
    if (selectedRowKeys.length === 1) {
      const selectedLegalAdvisor = data?.data.find(
        (employee) => employee.id === selectedRowKeys[0]
      );
      setSelectedLegalAdvisor(selectedLegalAdvisor);
      setViewModalOpen(true);
      setSelectedRowKeys([]);
    } else if(selectedRowKeys.length > 1){
      toast({
        title: "Selected More Than One",
        description: "Please select Exactly one record to View.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }else {
      toast({
        title: "NO Recode Selected",
        description: "Please Selete At Least One Record To View.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isError) {
    return <div>Error loading employee</div>;
  }

  return (
    <div className="mt-5 flex w-full flex-col gap-5">
      <Card>
        <div className="flex justify-between  p-4">
          <div className="flex items-center "></div>
          <div className="flex items-center space-x-1">
          <Menu className="bg-blue" isLazy>
              <MenuButton  as={Button} rightIcon={<ChevronDownIcon />} colorScheme='blue'>
                Download Reports
              </MenuButton>
              <MenuList>
                <MenuItem>
                  {DownloadData && (
                    <DownloadPDF headers={Dheaders} DownloadData={DownloadData} keys={Dkeys} title={title} pdfName={pdfName} totalRecord={totalRecord} />
                  )}
                </MenuItem>
                <MenuItem>
                  <DownloadExcel data={DownloadData} headers={Eheaders} keys={Ekeys} xlFile={xlFile} title={title} totalRecord={totalRecord} />
                </MenuItem>
              </MenuList>
            </Menu>
            <FilterButton onClick={handleFilter} />
            <CreateButton onClick={onOpen} />
            <AddLegalAdivsor
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={onSuccessCreate}
              checkData={data?.data}
            />
          </div>
        </div>
      </Card>
      <Card>
        <div className="flex justify-between bg-indigo-200 p-4">
          <div className="flex items-center">
            <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => onSearch(e)}
                onKeyDown={(e) => onSearch(e)}
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <EditButton onClick={handleEdit} />
            <DeleteButton onClick={handleDelete} />
            <ViewButton onClick={handleView} />
          </div>
        </div>
        {isLoading ? (
          <div className="flex h-40 items-center justify-center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </div>
        ) : (
          <ChakraProvider>
            <div style={{ overflowX: "auto", maxHeight: "700px" }}>
              <DataTable
                data={data?.data}
                headers={headers}
                keys={keys}
                selectedRowKeys={selectedRowKeys}
                onSelect={onSelectRow}
              />
            </div>
            <CustomPagination
              pageSize={pagination.pageSize}
              onPageSizeChange={onPageSizeChange}
              current={pagination.current}
              onPageChange={onPageChange}
              total={data.total}
            />
          </ChakraProvider>
        )}
      </Card>
      <DeleteConfirmation
        isOpen={alertOpen}
        onClose={onCloseAlert}
        confirmMessage={confirmMessage}
        onConfirm={handleDeleteConfirm}
      />
      <EditLegalAdvisor
        isOpen={EditModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSuccessEdit={onSuccessEdit}
        LegalAdvisor={selectedLegalAdvisor}
        checkData={data?.data}
      />
      <ViewLegalAdvisorModal
        isOpen={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        LegalAdvisor={selectedLegalAdvisor}
      />
      <FilterModal
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        status={legal_status}
        pagination={pagination} // Pass pagination as prop to FilterModal
        updatePagination={setPagination} // Pass the function to update pagination
      />
    </div>
  );
}

export default LegalAdvisor;
