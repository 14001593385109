import React from 'react';
import ExcelJS from 'exceljs';
import logoPath from '../assets/img/logo/Logo.png'; // Update the path to your logo
import { FaFileExcel } from 'react-icons/fa';

const DownloadExcel = ({ data, headers, keys  , title , xlFile , totalRecord}) => {
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(`${title}`);

    // Convert image to base64
    const base64Image = await fetch(logoPath)
      .then(response => response.blob())
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });

    // Add logo image
    const logoImage = workbook.addImage({
      base64: base64Image.split(',')[1], // Remove the data:image/png;base64, prefix
      extension: 'png',
    });

    sheet.addImage(logoImage, {
      tl: { col: 0, row: 0 },
      ext: { width: 200, height: 70 },
    });

    // Merge cells for the header section
    sheet.mergeCells('A1:H4');
    sheet.mergeCells('I1:AZ4');
    sheet.mergeCells('C5:AZ5');
    sheet.getCell('A1').value = `${title} Reports`;
    sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    sheet.getCell('A1').font = { size: 24, bold: true };

    // sheet.mergeCells('A5:G5');
    sheet.getCell('A5').value = `Report Date: ${new Date().toISOString().split('T')[0]}`;
    sheet.getCell('A5').alignment = { horizontal: 'left' };
    
    sheet.getCell('B5').value = `Total Records: ${totalRecord}`;
    sheet.getCell('B5').alignment = { horizontal: 'left' };


    // Style for header
    const headerStyle = {
      font: { bold: true, color: { argb: 'FFFFFF' } }, // White bold font
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0072C6' } }, // Blue background color
    };

    // Add headers with styling
    const headerRow = sheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.fill = headerStyle.fill;
      cell.font = headerStyle.font;
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    // Add data rows
    data.forEach((row) => {
      const rowData = keys.map((key) => {
        // Access nested properties if any
        const nestedKeys = key.split('.');
        let value = row;
        nestedKeys?.forEach((nestedKey) => {
          value = value?.[nestedKey];
        });
        return value;
      });
      sheet.addRow(rowData);
    });

    // Auto size all columns
    sheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${xlFile}.xlsx`;
    a.click();
  };

  return (
    <button onClick={downloadExcel} style={{ background: 'none', border: 'none', cursor: 'pointer', outline: 'none', display: 'flex', alignItems: 'center' }}>
    <FaFileExcel size={30} color="green" /> {/* Render Excel icon */}
    <span style={{ marginLeft: '0.5rem' }}>Excel File</span> {/* Text indicating Excel file */}
  </button>
  );
};

export default DownloadExcel;
