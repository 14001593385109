import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Heading,
  Text,
  Divider,
  Box,
  Center,
  Flex,
  useToast 
} from "@chakra-ui/react";
import { FaFilePdf } from 'react-icons/fa';

function ViewVTBenficiary({ isOpen, onClose, vTraining }) {
  const toast = useToast();
  const hasAttachment = vTraining?.attachment;
  const handleClick = (e) => {
    if (!hasAttachment) {
      e.preventDefault();
      toast({
        title: "No File Attachment",
        description: "There is no file attachment for this record.",
        position: 'top',
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!vTraining) {
    return null;
  }

  const isPackageReceived = vTraining?.is_package_received == 1; // Use loose equality to handle both numeric and string "1"
  const becameSelfEmployed = vTraining?.became_self_employeed == 1; // Use loose equality to handle both numeric and string "1"

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg={"gray.100"} borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          Vocational Training Beneficiary Details
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Stack spacing={8}>
            <Section title="Personal Info">
              <InfoCard>
                <Field label="Name" value={vTraining?.name} />
                <Field label="Father's Name" value={vTraining?.father_name} />
                <Field label="Age" value={vTraining?.age} />
                <Field label="Gender" value={vTraining?.gender} />
                <Field label="Marital Status" value={vTraining?.marital_status} />
                <Field label="Educational Degree" value={vTraining?.educational_degree} />
                <Field label="Address" value={vTraining?.address} />
                <Field label="Phone" value={vTraining?.phone} />
                <Field label="Identification Type" value={vTraining?.identification_type || 'N/A'} />
                <Field label="Identification Number" value={vTraining?.identification_number || 'N/A'} />
                <Field label="Livelihood Related Work" value={vTraining?.livelihood_related_work} />
                <Field label="If in the home, what activity?" value={vTraining?.home_activity} />
                <Field label="If outside the home, what activity?" value={vTraining?.outside_activity} />
                <Field label="Most interested skills in learning" value={vTraining?.learning_skill_interest} />
                <Field label="Does anyone have land in house?" value={vTraining?.does_anyone_have_land_in_house} />
                <Field label="What is the land mainly used for?" value={vTraining?.main_usages_of_land} />
                <Field label="Does personally agricultural activities?" value={vTraining?.do_you_personally_do_agricultural_activities} />
                <Field label="Need to training?" value={vTraining?.need_training} />
                <Field label="Is allowed to go to the training?" value={vTraining?.is_allowed_to_go_training} />
                <Field label="Branch" value={vTraining?.branch?.branch_name} />
              </InfoCard>
            </Section>
            <Section title="Family Info">
              <InfoCard>
                <Field label="Family Nature" value={vTraining?.family_nature || 'N/A'} />
                <Field label="Family Working Members" value={vTraining?.family_working_members_number || 'N/A'} />
                <Field label="Household Head" value={vTraining?.house_hold_head || 'N/A'} />
                <Field label="House Head Job" value={vTraining?.house_head_job || 'N/A'} />
                <Field label="Total Family Members" value={vTraining?.house_hold_size || 'N/A'} />
                <Field label="Girls Number" value={vTraining?.girls_number || 'N/A'} />
                <Field label="Boys Number" value={vTraining?.boys_number || 'N/A'} />
              </InfoCard>
            </Section>
            <Section title="Class Info">
              <InfoCard>
                <Field label="Trainer Name" value={vTraining?.trainer?.name} />
                <Field label="Class" value={vTraining?.classes?.name} />
                <Field label="Session" value={vTraining?.session} />
                <Field label="Package Received" value={isPackageReceived ? "Yes" : "No"} />
                <Field label="Became Self Employed" value={becameSelfEmployed ? "Yes" : "No"} />
                <Field label="Training Benefit" value={vTraining?.training_benefit || 'N/A'} />
                <Center>
                  <Box>
                    <Button as="a" href={vTraining?.attachment} target='_blank' download="vocational_training.pdf" onClick={handleClick} leftIcon={<FaFilePdf />} variant="link" colorScheme="blue" size="lg">
                      View Attachment
                    </Button>
                  </Box>
                </Center>
              </InfoCard>
            </Section>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function Field({ label, value }) {
  return (
    <Flex justifyContent="space-between">
      <Text fontWeight="bold">{label}</Text>
      <Text>{value}</Text>
    </Flex>
  );
}

function Section({ title, children }) {
  return (
    <Box>
      <Heading size="md" mb={4}>{title}</Heading>
      <Divider />
      <Stack spacing={4} mt={4}>
        {children}
      </Stack>
    </Box>
  );
}

function InfoCard({ children }) {
  return (
    <Box boxShadow="md" p={6} borderRadius="md" bg="white">
      {children}
    </Box>
  );
}

export default ViewVTBenficiary;
