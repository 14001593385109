import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useToast,
  Input,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { useGetMnEOfficers } from "queries/monitoring_evaluations.query";
import { useGetProvincialManager } from "queries/monitoring_evaluations.query";
import { useMnECreate } from "queries/monitoring_evaluations.query";
import { useAuthStore } from "store/use-auth.store";

export default function CreateMnEFormModal({
  isOpen,
  onClose,
  onSuccess,
  checkData,
}) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm();

  const [updateDate, setUpdateDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: mne_officer,
  } = useGetMnEOfficers();
  const {
    data: provincialManager,
  } = useGetProvincialManager();

  const { user } = useAuthStore();
  const { mutate } = useMnECreate();
  const months = [
    { value: "JANUARY", name: "January" },
    { value: "FEBRUARY", name: "February" },
    { value: "MARCH", name: "March" },
    { value: "APRIL", name: "April" },
    { value: "MAY", name: "May" },
    { value: "JUNE", name: "June" },
    { value: "JULY", name: "July" },
    { value: "AUGUST", name: "August" },
    { value: "SEPTEMBER", name: "September" },
    { value: "OCTOBER", name: "October" },
    { value: "NOVEMBER", name: "November" },
    { value: "DECEMBER", name: "December" },
  ];
  const password = React.useRef({});
  password.current = watch("password", "");
  const toast = useToast();
  const handleUpdateDateChange = (date) => {
    setUpdateDate(date);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      const formattedUpdateDate = updateDate
        ? `${updateDate.getFullYear()}-${(updateDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${updateDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : "";
      formData.append("update_date", formattedUpdateDate);

      Object.keys(data).forEach((key) => {
        if (key === "attachment") {
          formData.append("attachment", data.attachment[0]);
        } else {
          formData.append(key, data[key]);
        }
      });

      mutate(formData, {
        onSuccess: () => {
          setIsLoading(false);
          showToast({
            status: "success",
            description: "M&E report added successfully",
          });
          onSuccess();
          onClose();
          setUpdateDate(false);
          reset();
        },
        onError: (error) => {
          setIsLoading(false);
          showToast({
            status: "error",
            description: "Failed to add M&E report",
          });
        },
      });
    } catch (error) {
      setIsLoading(false);
      showToast({ status: "error", description: "Failed to add M&E report" });
    }
  };
  const showToast = ({ status, description }) => {
    toast({
      title: status === "success" ? "Success" : "Error",
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          Creating New record for Monitoring and Evaluation
        </ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose();
            setUpdateDate(false);
            reset();
          }}
        />
        <ModalBody style={{ overflowX: "auto", maxHeight: "650px" }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto max-w-screen-xl rounded bg-white p-4 shadow-md"
          >
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
              {(user?.role === "ADMIN" || user?.role === "MANAGER") && (
                <FormControl isInvalid={errors.mne_officer_id}>
                  <FormLabel htmlFor="mne_officer_id">
                    M&E Officer <span className="text-red-500">*</span>
                  </FormLabel>
                  <Select
                    id="mne_officer_id"
                    placeholder="Select the M&E officer"
                    {...register("mne_officer_id", {
                      required: "This is required",
                    })}
                    className="input-field"
                  >
                    {mne_officer?.map((officerItem) => (
                      <option key={officerItem.id} value={officerItem.id}>
                        {officerItem.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.mne_officer_id && errors.mne_officer_id.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={errors.provincial_manager_id}>
                <FormLabel htmlFor="provincial_manager_id">
                  Line Manager <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="provincial_manager_id"
                  placeholder="Select the line manager"
                  {...register("provincial_manager_id", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  {provincialManager?.map((provincialManager) => (
                    <option
                      key={provincialManager.id}
                      value={provincialManager.id}
                    >
                      {provincialManager.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.provincial_manager_id &&
                    errors.provincial_manager_id.message}
                </FormErrorMessage>
              </FormControl>{" "}
              <FormControl isInvalid={errors.reporting_month}>
                <FormLabel htmlFor="reporting_month">
                  Reporting Month <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="reporting_month"
                  placeholder="Select the report month"
                  {...register("reporting_month", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.reporting_month && errors.reporting_month.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.update_date}>
                <FormLabel htmlFor="update_date">
                  Update Date <span className="text-red-500">*</span>
                </FormLabel>
                <DatePicker
                  id="update_date"
                  selected={updateDate}
                  onChange={handleUpdateDateChange}
                  placeholderText="Select update date"
                  dateFormat="yyyy-MM-dd"
                  className="input-field w-full"
                  customInput={<Input />}
                  {...{
                    required: "This is required",
                  }}
                />
                <FormErrorMessage>
                  {errors.update_date && errors.update_date.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors.key_achievements}
                className="col-span-2"
              >
                <FormLabel htmlFor="key_achievements">
                  Key Achievements/Tasks Completed{" "}
                  <span className="text-red-500">*</span>
                </FormLabel>
                <Textarea
                  id="key_achievements"
                  placeholder="Key achievements..."
                  {...register("key_achievements", {
                    required: "This is required",
                  })}
                  className="input-field w-full"
                />
                <FormErrorMessage>
                  {errors.key_achievements && errors.key_achievements.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors.visited_project_key_findings}
                className="col-span-2"
              >
                <FormLabel htmlFor="visited_project_key_findings">
                  Key finding and observation about each project visited{" "}
                  <span className="text-red-500">*</span>
                </FormLabel>
                <Textarea
                  id="visited_project_key_findings"
                  placeholder="Visited project key findings..."
                  {...register("visited_project_key_findings", {
                    required: "This is required",
                  })}
                  className="input-field w-full"
                />
                <FormErrorMessage>
                  {errors.visited_project_key_findings &&
                    errors.visited_project_key_findings.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors.key_challenges_and_solutions}
                className="col-span-2"
              >
                <FormLabel htmlFor="key_challenges_and_solutions">
                  Key Challenges, Strategic Concerns and Proposed Solutions/Need
                  for Support <span className="text-red-500">*</span>
                </FormLabel>
                <Textarea
                  id="key_challenges_and_solutions"
                  placeholder="Key challenges and solutions..."
                  {...register("key_challenges_and_solutions", {
                    required: "This is required",
                  })}
                  className="input-field w-full"
                />
                <FormErrorMessage>
                  {errors.key_challenges_and_solutions &&
                    errors.key_challenges_and_solutions.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors.next_month_visits_due}
                className="col-span-2"
              >
                <FormLabel htmlFor="next_month_visits_due">
                  M&E Visits Due in Next Month (Internal/ External){" "}
                  <span className="text-red-500">*</span>
                </FormLabel>
                <Textarea
                  id="next_month_visits_due"
                  placeholder="Next month visits due..."
                  {...register("next_month_visits_due", {
                    required: "This is required",
                  })}
                  className="input-field w-full "
                />
                <FormErrorMessage>
                  {errors.next_month_visits_due &&
                    errors.next_month_visits_due.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.attachment}>
                <FormLabel htmlFor="attachment">
                  Attachment (PDF only)
                </FormLabel>
                <Input
                  id="attachment"
                  type="file"
                  accept="application/pdf"
                  {...register("attachment", {})}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.attachment && errors.attachment.message}
                </FormErrorMessage>
              </FormControl>
           
            </div>
            <div className="mt-8 flex flex-row-reverse">
              <Button
                colorScheme="blue"
                onClick={() => {
                  onClose();
                  setUpdateDate(false);
                  reset();
                }}
              >
                Close
              </Button>
              <Button
                colorScheme="teal"
                isLoading={isSubmitting || isLoading} 
                type="submit"
                className=" mr-2"
              >
                Submit
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
