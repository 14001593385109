import authImg from "assets/img/logo/login_background.jpg";
import { Routes, Route, Navigate } from "react-router-dom";

import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-blue-50 dark:!bg-navy-900">
        <main className="mx-auto min-h-screen">
          <div
            style={{ backgroundImage: `url(${authImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            className="relative flex min-h-screen w-full"
          >
            <div className="mx-auto flex flex-col justify-center">
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/auth/sign-in" replace />}
                />
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
