// CreateButton.js
import React from 'react';
import SquareButton from './SquareButton';

const CreateButton = ({ onClick }) => {
  return <SquareButton color="green" iconName="plus" text="Create" onClick={onClick} />;

};

export default CreateButton;
