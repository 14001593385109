// DeleteButton.js
import React from 'react';
import Button from './Button';

const DeleteButton = ({ onClick }) => {
  const icon = (
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
  );
  return <Button icon={icon} color="red" onClick={onClick} />;
};

export default DeleteButton;
