import { Button, ChakraProvider, Menu, MenuButton, MenuItem, MenuList, Spinner, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "components/card";
import FilterButton from "components/Buttons/FilterButton";
import CreateButton from "components/Buttons/CreateButton";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import ViewButton from "components/Buttons/ViewButton";
import DataTable from "components/DataTable/DataTable";
import CustomPagination from "components/CustomPagenation/CustomPagenation";
import { FiSearch } from "react-icons/fi";
import DeleteConfirmation from "components/DeleteConfirmation/DeleteConfirmation";
import DownloadPDF from "views/DownloadPDF";
import FilterModal from "components/filter/filter";
import { useAuthStore } from "store/use-auth.store";
import ViewFemaleDoctors from "./components/viewFemaleDoctors";
import EditFemaleDoctors from "./components/editFemalDoctors";
import AddFemaleDoctors from "./components/addFemaleDoctors";
import { useGetFemaleDocotrsData } from "queries/female_doctors.query";
import { useSingleDelete } from "queries/female_doctors.query";
import { useMultiDelete } from "queries/female_doctors.query";
import DownloadExcel from "views/DownloadExcel";
import { ChevronDownIcon } from "@chakra-ui/icons";


function FemaleDoctors() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    searchValue: null,
    status: [],
    filterDateRange: null,
    gender: [],
    project: [],
    branch: [],
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const { mutate: singleDeleteMutation } = useSingleDelete();
  const { mutate: multiDeleteMutation } = useMultiDelete();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [selectedFDoctors, setSelectedFDoctorData] = useState(null);
  const [editSuccess, setEditSuccess] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { user } = useAuthStore();

  const toast = useToast();
  const { data, isLoading, isError } = useGetFemaleDocotrsData(
    pagination.current,
    pagination.pageSize,
    pagination.searchValue,
    pagination.status,
    pagination.filterDateRange,
    pagination.gender,
    pagination.project,
    pagination.branch
  );
  const DownloadData = data?.data;
  const title = "Female Doctors";
  const pdfName = "female_doctors";
  const xlFile = "female_docotrs";
  const totalRecord = data?.data?.length;

  const onCloseAlert = () => {
    setAlertOpen(false);
  };
  const handleEdit = () => {
    if (selectedRowKeys.length === 1) {
      const selectedFDoctorsData = data?.data.find(
        (employee) => employee.id === selectedRowKeys[0]
      );
      setSelectedFDoctorData(selectedFDoctorsData);
      setEditModalOpen(true);
    }  else if(selectedRowKeys.length > 1){
      toast({
        title: "Selected More Than One",
        description: "Please select Exactly one record to Edit.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }else {
      toast({
        title: "NO Recode Selected",
        description: "Please Selete At Least One Record To Edit.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = () => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.length === 1) {
        setConfirmMessage("Are you sure you want to delete this record?");
      } else {
        setConfirmMessage(
          `Are you sure you want to delete ${selectedRowKeys.length} records?`
        );
      }
      setAlertOpen(true);
    } else {
      toast({
        title: "NO Recode Selected",
        description: "Please select at least one record to delete.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.length === 1) {
        singleDeleteMutation(selectedRowKeys[0], {
          onSuccess: () => {
            toast({
              title: "Delete",
              description: "One record was successfully deleted.",
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
            setSelectedRowKeys([]);
            onCloseAlert();
          },
          onError: () => {
            toast({
              title: "Delete",
              description: "Failed to delete the record.",
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          },
        });
      } else {
        multiDeleteMutation(selectedRowKeys, {
          onSuccess: () => {
            toast({
              title: "Delete",
              description: `${selectedRowKeys.length} Records were successfully  deleted.`,
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
            setSelectedRowKeys([]);
            onCloseAlert();
          },
          onError: () => {
            toast({
              title: "Delete",
              description: `${selectedRowKeys.length} Failed to delete the records.`,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          },
        });
      }
    } else {
      toast({
        title: "Delete",
        description: "Please select at least one record to delete.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleView = () => {
    if (selectedRowKeys.length === 1) {
      const selectedFDoctorsData = data?.data.find(
        (employee) => employee.id === selectedRowKeys[0]
      );
      setSelectedFDoctorData(selectedFDoctorsData);
      setViewModalOpen(true);
      setSelectedRowKeys([]);
    } else if(selectedRowKeys.length > 1){
      toast({
        title: "Selected More Than One",
        description: "Please select Exactly one record to View.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }else {
      toast({
        title: "NO Recode Selected",
        description: "Please Selete At Least One Record To View.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleFilter = () => {
    setFilterModalOpen(true);
  };
  const onSearch = (e) => {
    if (e.target.value.trim() === "") {
      setSearchInput(e.target.value);
      setPagination({ ...pagination, searchValue: e.target.value });
    }
    if (e.key === "Enter") {
      setSearchInput(e.target.value);
      setPagination({ ...pagination, searchValue: e.target.value });
    }
  };

  const onPageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
  };
  const onPageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value });
  };

  const onSelectRow = (selectedRows) => {
    setSelectedRowKeys(selectedRows);
  };
  const onSuccessCreate = () => {
    setPagination({ ...pagination, current: 1 });
  };
  const onSuccessEdit = () => {
    setEditSuccess(!editSuccess); // Toggle editSuccess to trigger reload
    setSelectedRowKeys([]);
  };

  if (isError) {
    return <div>Error loading employee</div>;
  }

  const doctorSpecificHeaders =
    user.role === "ADMIN" || (user?.role ==="MANAGER") ? ["DOCTOR"] : [];
  const doctorSpecificKeys =
    user.role === "ADMIN" || (user?.role ==="MANAGER") ? ["doctor.name"] : [];
  const branchSpecificHeaders =
    user.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name ==="KABUL") ? ["BRANCH"] : [];
  const branchSpecificKeys =
    user.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name ==="KABUL") ? ["branch.branch_name"] : [];
  const headers = [
    "ID",
    "NAME",
    "FATHER NAME",
    "AGE",
    "ADDRESS",
    "PROJECT",
    "VISIT FREQUENCY",
    "DIAGNOSIS",
    "PRESCRIPTION",
    "DIAGNOSIS DATE",
    ...doctorSpecificHeaders,
    ...branchSpecificHeaders,
    "CREATED BY"
  ];
  
  const keys = [
    "id",
    "name",
    "father_name",
    "age",
    "address",
    "project",
    "visit_frequency",
    "diagnosis",
    "prescription",
    "diagnosis_date",
    ...doctorSpecificKeys,
    ...branchSpecificKeys,
    "created_by.username",
  ];
  const Dheaders = [
    "NAME",
    "FATHER NAME",
    "AGE",
    "ADDRESS",
    "VISIT FREQUENCY",
    "DIAGNOSIS",
    "PRESCRIPTION",
    "DIAGNOSIS DATE",
    ...doctorSpecificHeaders,
    "PROJECT",
    ...branchSpecificHeaders,
  ];

  const Dkeys = [
    "name",
    "father_name",
    "age",
    "address",
    "visit_frequency",
    "diagnosis",
    "prescription",
    "diagnosis_date",
    ...doctorSpecificKeys,
    "project",
    ...branchSpecificKeys,
  ];

  const Eheaders = [
    "NAME",
    "FATHER NAME",
    "AGE",
    "ADDRESS",
    "VISIT FREQUENCY",
    "DIAGNOSIS",
    "PRESCRIPTION",
    "DIAGNOSIS DATE",
    ...doctorSpecificHeaders,
    "PROJECT",
    ...branchSpecificHeaders,
  ];
  const Ekeys = [
    "name",
    "father_name",
    "age",
    "address",
    "visit_frequency",
    "diagnosis",
    "prescription",
    "diagnosis_date",
    ...doctorSpecificKeys,
    "project",
    ...branchSpecificKeys,
  ];

  return (
    <div className="mt-5 flex w-full flex-col gap-5">
      <Card>
        <div className="flex justify-between  p-4">
          <div className="flex items-center "></div>
          <div className="flex items-center space-x-1">
            <Menu className="bg-blue" isLazy>
              <MenuButton  as={Button} rightIcon={<ChevronDownIcon />} colorScheme='blue'>
                Download Reports
              </MenuButton>
              <MenuList>
                <MenuItem>
                  {DownloadData && (
                    <DownloadPDF headers={Dheaders} DownloadData={DownloadData} keys={Dkeys} title={title} pdfName={pdfName} totalRecord={totalRecord} />
                  )}
                </MenuItem>
                <MenuItem>
                  <DownloadExcel data={DownloadData} headers={Eheaders} keys={Ekeys} xlFile={xlFile} title={title} totalRecord={totalRecord} />
                </MenuItem>
              </MenuList>
            </Menu>
            <FilterButton onClick={handleFilter} />
            <CreateButton onClick={onOpen} />
            <AddFemaleDoctors
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={onSuccessCreate}
              pagination={pagination}
              setPagination={setPagination}
              checkData={data?.data}
            />
          </div>
        </div>
      </Card>
      <Card>
        <div className="flex justify-between bg-indigo-200 p-4">
          <div className="flex items-center">
            <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => onSearch(e)}
                onKeyDown={(e) => onSearch(e)}
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <EditButton onClick={handleEdit} />
            <DeleteButton onClick={handleDelete} />
            <ViewButton onClick={handleView} />
          </div>
        </div>
        {isLoading ? (
          <div className="flex h-40 items-center justify-center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </div>
        ) : (
          <ChakraProvider>
            <div style={{ overflowX: "auto", maxHeight: "700px" }}>
              <DataTable
                data={data?.data}
                headers={headers}
                keys={keys}
                selectedRowKeys={selectedRowKeys}
                onSelect={onSelectRow}
              />
            </div>
            <CustomPagination
              pageSize={pagination.pageSize}
              onPageSizeChange={onPageSizeChange}
              current={pagination.current}
              onPageChange={onPageChange}
              total={data.total}
            />
          </ChakraProvider>
        )}
      </Card>
      <DeleteConfirmation
        isOpen={alertOpen}
        onClose={onCloseAlert}
        confirmMessage={confirmMessage}
        onConfirm={handleDeleteConfirm}
      />

      <EditFemaleDoctors
        isOpen={EditModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSuccessEdit={onSuccessEdit}
        fDoctor={selectedFDoctors}
        checkData={data?.data}
      />
      <ViewFemaleDoctors
        isOpen={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        fDoctor={selectedFDoctors}
      />
      <FilterModal
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        pagination={pagination} 
        updatePagination={setPagination} 
        project
      />
    </div>
  );
}

export default FemaleDoctors;
