import React, { useState } from "react";
import Checkbox from "components/checkbox";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "store/use-auth.store";
import { useLoginUsers } from "queries/auth.query";
import { Box, Flex, Image, useToast, Text, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";

export default function SignIn() {
  const { login, token, isLoggedIn } = useAuthStore();
  const { mutate, isLoading } = useLoginUsers();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();

  const validateForm = () => {
    let isValid = true;

    if (!username.trim()) {
      setUsernameError("Username is required");
      isValid = false;
    } else {
      setUsernameError("");
    }

    if (!password.trim()) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (password.trim().length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const values = { username, password };
    mutate(values, {
      onSuccess: (data) => {
        const { token, user } = data;
        login({ isLoggedIn: true, token, user });
        toast({
          title: "Login Successfully",
          description: "Welcome to your account.",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error) => {
        if (error.response && error.response.status === 401 && error.response.data.message === "Your account is not active") {
          toast({
            title: "Login Not Allowed",
            description: "Your account is not active",
            status: "warning",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          setError("Wrong password or username.");
        }
      },
    });
  };

  if (isLoggedIn && token) {
    return <Navigate to={"/admin"} replace />;
  }

  return (
    <Flex minH="100vh" align="center" justify="center" py="12" px="6">
      <Box
        maxW="md"
        w="full"
        bg="white"
        boxShadow="xl"
        rounded="lg"
        p="6"
        textAlign="center"
      >
        <Image src={require('../../assets/img/logo/Logo.png')} alt="Logo" mb="4" mx="auto" />
        <Text fontSize="2xl" fontWeight="bold" mb="4">
        Let's sign in!
        </Text>

        <FormControl mb="4">
          <FormLabel textAlign="left">User Name*</FormLabel>
          <Input
            id="user-name"
            type="text"
            placeholder="Enter username..."
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {usernameError && (
            <Text color="red.500" fontSize="sm" mt="2">{usernameError}</Text>
          )}
        </FormControl>

        <FormControl mb="4">
          <FormLabel textAlign="left">Password*</FormLabel>
          <Input
            id="password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter password..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && (
            <Text color="red.500" fontSize="sm" mt="2">{passwordError}</Text>
          )}
        </FormControl>

        <Flex align="center" mb="4">
          <Checkbox onChange={() => setShowPassword(!showPassword)} />
          <Text ml="2" fontSize="sm" color="gray.600">
            Show Password
          </Text>
        </Flex>

        {error && (
          <Box mb="4" p="2" bg="red.100" borderRadius="md">
            <Text color="red.700">{error}</Text>
          </Box>
        )}

        <Button
          isLoading={isLoading}
          colorScheme="blue"
          className="mt-5"
          w="full"
          onClick={handleSubmit}
        >
          Sign In
        </Button>
      </Box>
    </Flex>
  );
}
