import React, { useState } from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Divider,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  Box,
  Heading,
  Img,
  AbsoluteCenter,
  useToast,
} from "@chakra-ui/react";

import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  StepSeparator,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import successSVG from "../../../assets/svg/success.svg";
import { useGetDoctors } from "queries/pss_beneficiary.query";
import { usePssBeneficiaryUpdate } from "queries/pss_beneficiary.query";
import { useAuthStore } from "store/use-auth.store";

const steps = [
  { title: "Basic Info" },
  { title: "Case Info" },
  { title: "Doc Visit" },
  { title: "Result" },
  { title: "Done" },
];

const initialFormData = {
  name: "",
  father_name: "",
  registration_date: "",
  contact_type: "",
  session_duration: "",
  main_activity: "",
  closing_date: null,
  visitDate: null,
  result: "",
};

export default function PssEditFormModal({
  isOpen,
  onClose,
  onSuccess,
  beneficiary,
  checkData,
}) {
  const { user } = useAuthStore();
  const [activeStep, setActiveStep] = useState(0);
  const [closeDate, setcloseDate] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(null);
  const [selectedTools, setSelectedTools] = useState([]);
  const [
    selectedPsychologicalInterventions,
    setSelectedPsychologicalInterventions,
  ] = useState([]);
  const [selectedPsychologicalIssues, setSelectPsychologicalIssueType] =
    useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { data: doctors } = useGetDoctors();
  const { mutate } = usePssBeneficiaryUpdate();
  const {
    register,
    reset,
    control,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm();
  const isDuplicateTazkiraNumber = (value) => {
    let count = 0;
    for (let i = 0; i < checkData.length; i++) {
      if (
        checkData[i].tazkira_number === value &&
        checkData[i].tazkira_number !== beneficiary?.tazkira_number
      ) {
        count++;
      }
    }
    return count > 0;
  };
  const toast = useToast();
  const showToast = ({ status, description }) => {
    toast({
      title: status === "success" ? "Success" : "Error",
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };
  function parseDefaultValue(defaultValueString) {
    if (!defaultValueString) return [];
    const values = defaultValueString.split(",");
    return values.map((value) => ({
      value,
      label: value,
    }));
  }

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      data.used_tool = selectedTools;
      data.appropriate_psychological_intervention =
        selectedPsychologicalInterventions;
      data.psychological_issue_type = selectedPsychologicalIssues;
      const formData = new FormData();
      formData.append("id", beneficiary.id);
      formData.append("_method", "PUT");

      data.visits.forEach((visit, index) => {
        formData.append(`visits[${index}][id]`, visit.id);
        formData.append(`visits[${index}][date]`, visit.date);
        formData.append(
          `visits[${index}][session_duration]`,
          visit.session_duration
        );
        formData.append(`visits[${index}][main_activity]`, visit.main_activity);
        formData.append(`visits[${index}][result]`, visit.result);
      });

      const formattedCloseDate = closeDate
        ? `${closeDate.getFullYear()}-${(closeDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${closeDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : "";
      formData.append("closing_date", formattedCloseDate);

      const formattedRegisterationDate = registrationDate
        ? `${registrationDate.getFullYear()}-${(registrationDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${registrationDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : "";
      formData.append("registration_date", formattedRegisterationDate);

      if (data.case_attachment) {
        formData.append("case_attachment", data.case_attachment[0]); // Append only the first file if multiple files are selected
      }

      if (data.form_attachment) {
        formData.append("form_attachment", data.form_attachment[0]); // Append only the first file if multiple files are selected
      }

      Object.entries(data).forEach(([key, value]) => {
        if (
          key !== "visits" &&
          key !== "case_attachment" &&
          key !== "form_attachment"
        ) {
          if (Array.isArray(value)) {
            formData.append(key, value.map((option) => option.value).join(","));
          } else {
            formData.append(key, value);
          }
        }
      });

      mutate(formData, {
        onSuccess: () => {
          setIsLoading(false);
          showToast({
            status: "success",
            description: "Pss Beneficiary was updated successfully.",
          });
          handleNext();
        },
        onError: (error) => {
          setIsLoading(false);
          showToast({
            status: "error",
            description: "Failed to Update Pss Beneficiary.",
          });
        },
      });
    } catch (error) {
      setIsLoading(false);
      showToast({
        status: "error",
        description: "Failed to update Pss Beneficiary.",
      });
    }
  };

  const handleDateChange = (date) => {
    setcloseDate(date);
  };
  const handleRegisterDateChange = (date) => {
    setRegistrationDate(date);
  };
  const handleNext = async () => {
    let fieldsToValidate = [];
    switch (activeStep) {
      case 0:
        fieldsToValidate = [
          "name",
          "father_name",
          "last_name",
          "gender",
          "age",
          "birth_place",
          "marital_status",
          "educational_degree",
          "job",
          "phone",
          "total_family_members",
          "male_member",
          "female_member",
          "branch_id",
          "address",
          "tazkira_number",
          "project",
        ];
        break;
      case 1:
        fieldsToValidate = [
          "registration_date",
          "contact_type",
          "used_tool",
          "intervention_type",
          "appropriate_psychological_intervention",
          "psychological_issue_type",
          "doctor_id",
        ];
        break;
      case 2:
        break;
      case 3:
        fieldsToValidate = ["examination_result", "case_status"];
        break;
      default:
        break;
    }
    const isValid = await trigger(fieldsToValidate);
    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  React.useEffect(() => {
    if (beneficiary && beneficiary?.beneficiary_cases[0]?.registration_date) {
      const startDateObj = new Date(
        beneficiary?.beneficiary_cases[0].registration_date
      );
      setRegistrationDate(startDateObj);
    } else {
      setRegistrationDate(null);
    }

    if (beneficiary && beneficiary?.beneficiary_cases[0]?.closing_date) {
      const closeDateObj = new Date(
        beneficiary?.beneficiary_cases[0].closing_date
      );
      setcloseDate(closeDateObj);
    } else {
      setcloseDate(null);
    }

    if (beneficiary && beneficiary.beneficiary_cases[0]?.used_tool) {
      const parsedTools = parseDefaultValue(
        beneficiary.beneficiary_cases[0].used_tool
      );
      setSelectedTools(parsedTools);
    }

    if (
      beneficiary &&
      beneficiary?.beneficiary_cases[0]?.appropriate_psychological_intervention
    ) {
      const parsedInterventions = parseDefaultValue(
        beneficiary?.beneficiary_cases[0]
          ?.appropriate_psychological_intervention
      );
      setSelectedPsychologicalInterventions(parsedInterventions);
    }

    if (
      beneficiary &&
      beneficiary?.beneficiary_cases[0]?.psychological_issue_type
    ) {
      const parsedIssues = parseDefaultValue(
        beneficiary?.beneficiary_cases[0]?.psychological_issue_type
      );
      setSelectPsychologicalIssueType(parsedIssues);
    }
  }, [beneficiary]);

  const handleClose = () => {
    onClose();
    reset();
    setActiveStep(0);
    if (beneficiary) {
      if (beneficiary?.beneficiary_cases[0]?.registration_date) {
        setRegistrationDate(
          new Date(beneficiary?.beneficiary_cases[0]?.registration_date)
        );
      } else {
        setRegistrationDate(null);
      }

      if (beneficiary?.beneficiary_cases[0]?.closing_date) {
        new Date(beneficiary?.beneficiary_cases[0]?.closing_date);
      } else {
        setcloseDate(null);
      }

      if (beneficiary?.beneficiary_cases[0]?.used_tool) {
        setSelectedTools(
          parseDefaultValue(beneficiary?.beneficiary_cases[0]?.used_tool)
        );
      } else {
        setSelectedTools([]);
      }

      if (
        beneficiary?.beneficiary_cases[0]
          ?.appropriate_psychological_intervention
      ) {
        setSelectedPsychologicalInterventions(
          parseDefaultValue(
            beneficiary?.beneficiary_cases[0]
              ?.appropriate_psychological_intervention
          )
        );
      } else {
        setSelectedPsychologicalInterventions([]);
      }

      if (beneficiary?.beneficiary_cases[0]?.psychological_issue_type) {
        setSelectPsychologicalIssueType(
          parseDefaultValue(
            beneficiary?.beneficiary_cases[0]?.psychological_issue_type
          )
        );
      } else {
        setSelectPsychologicalIssueType([]);
      }
    }
  };

  return (
    <>
      <Modal size="4xl" isOpen={isOpen} closeOnOverlayClick={false} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottomWidth="1px">
            Edit PSS Beneficiary
            <ModalCloseButton />
            <Divider className="mb-6 mt-3 w-full" />
            <Stepper index={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box flexShrink="0">
                    <StepTitle>{step?.title}</StepTitle>
                    <StepDescription>{step?.description}</StepDescription>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody
              style={{ height: "500px" }}
              maxHeight="500px"
              overflowY="auto"
            >
              {activeStep === 0 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <FormControl isInvalid={errors.name}>
                    <FormLabel htmlFor="name">
                      Name <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="name"
                      placeholder="Name"
                      defaultValue={beneficiary?.name}
                      {...register("name", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.father_name}>
                    <FormLabel htmlFor="father_name">
                      Father's Name <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="father_name"
                      placeholder="Father's Name"
                      defaultValue={beneficiary?.father_name}
                      {...register("father_name", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.father_name && errors.father_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.age}>
                    <FormLabel htmlFor="age">
                      Age <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="age"
                      placeholder="Age"
                      defaultValue={beneficiary?.age}
                      {...register("age", {
                        required: "This is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid age",
                        },
                        maxLength: {
                          value: 3,
                          message: "Age should be at most 3 digits long",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.age && errors.age.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.gender}>
                    <FormLabel htmlFor="gender">
                      Gender <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="gender"
                      placeholder="Select Gender"
                      defaultValue={beneficiary?.gender}
                      {...register("gender", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.gender && errors.gender.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.marital_status}>
                    <FormLabel htmlFor="marital_status">
                      Marital Status <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="marital_status"
                      placeholder="Select Marital Status"
                      defaultValue={beneficiary?.marital_status}
                      {...register("marital_status", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="SINGLE">Single</option>
                      <option value="MARRIED">Married</option>
                      <option value="DIVORCED">Divorced</option>
                      <option value="WIDOW">Widow</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.marital_status && errors.marital_status.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.educational_degree}>
                    <FormLabel htmlFor="educational_degree">
                      Educational Degree <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="educational_degree"
                      placeholder="Select Educational Degree"
                      defaultValue={beneficiary?.educational_degree}
                      {...register("educational_degree", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="ILLITERATE">Illiterate</option>
                      <option value="PRIMARY">Primary</option>
                      <option value="SECONDARY">Secondary</option>
                      <option value="HIGHER">Higher</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.educational_degree &&
                        errors.educational_degree.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.address}>
                    <FormLabel htmlFor="address">
                      Address <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="address"
                      placeholder="Address"
                      defaultValue={beneficiary?.address}
                      {...register("address", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.address && errors.address.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.job}>
                    <FormLabel htmlFor="job">Job</FormLabel>
                    <Input
                      id="job"
                      placeholder="Job"
                      defaultValue={beneficiary?.job}
                      {...register("job")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.job && errors.job.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.phone}>
                    <FormLabel htmlFor="phone">Phone</FormLabel>
                    <Input
                      id="phone"
                      placeholder="Phone"
                      defaultValue={beneficiary?.phone}
                      {...register("phone", {
                        pattern: {
                          value: /^[0-9+]+$/,
                          message: "Please enter only numbers and '+'",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.tazkira_number}>
                    <FormLabel htmlFor="tazkira_number">
                      Tazkira Number
                    </FormLabel>
                    <Input
                      id="tazkira_number"
                      placeholder="Tazkira Number"
                      defaultValue={beneficiary?.tazkira_number}
                      {...register("tazkira_number", {
                        pattern: {
                          value: /^[0-9-]+$/,
                          message: "Please enter only numbers and '-'",
                        },
                        validate: (value) => {
                          if (value === "") {
                            return true; // If the field is empty, no validation errors
                          }

                          const patternValid = /^[0-9-]+$/.test(value);
                          if (!patternValid) {
                            return "Please enter only numbers and '-'";
                          }

                          if (value === beneficiary?.tazkira_number) {
                            return true; // No error if the value matches employee.tazkira_number
                          }

                          return (
                            !isDuplicateTazkiraNumber(value) ||
                            "This Tazkira number is already in use"
                          );
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.tazkira_number && errors.tazkira_number.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.total_family_members}>
                    <FormLabel htmlFor="total_family_members">
                      Total Family Member{" "}
                      <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="total_family_members"
                      placeholder="Total family member"
                      defaultValue={beneficiary?.total_family_members}
                      {...register("total_family_members", {
                        required: "This is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid total family member",
                        },
                        maxLength: {
                          value: 3,
                          message:
                            "Total family member should be at most 3 digits long",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.total_family_members &&
                        errors.total_family_members.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.female_member}>
                    <FormLabel htmlFor="female_member">Female Member</FormLabel>
                    <Input
                      id="female_member"
                      placeholder=""
                      defaultValue={beneficiary?.female_family_members}
                      {...register("female_member", {
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid female family member",
                        },
                        maxLength: {
                          value: 3,
                          message:
                            "Female family member should be at most 3 digits long",
                        },
                      })}
                      className="input-field"
                    />

                    <FormErrorMessage>
                      {errors.female_member && errors.female_member.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.male_member}>
                    <FormLabel htmlFor="male_member">Male Member</FormLabel>

                    <Input
                      id="male_member"
                      placeholder=""
                      defaultValue={beneficiary?.male_family_members}
                      {...register("male_member", {
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid male family member",
                        },
                        maxLength: {
                          value: 3,
                          message:
                            "Male family member should be at most 3 digits long",
                        },
                      })}
                      className="input-field"
                    />

                    <FormErrorMessage>
                      {errors.male_member && errors.male_member.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.project}>
                    <FormLabel htmlFor="project">
                      Project <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="project"
                      defaultValue={beneficiary?.beneficiary_cases[0]?.project}
                      placeholder="Select project"
                      {...register("project", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="UN WOMEN">UN WOMEN</option>
                      <option value="UNDP">UNDP</option>
                      <option value="IDLO">IDLO</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.project && errors.project.message}
                    </FormErrorMessage>
                  </FormControl>

                  {(user?.role === "ADMIN" ||
                    (user?.role === "MANAGER" &&
                      user?.branch?.branch_name === "KABUL")) && (
                    <FormControl className="mb-8" isInvalid={errors.branch_id}>
                      <FormLabel htmlFor="branch_id">
                        Branch <span className="text-red-500">*</span>
                      </FormLabel>
                      <Select
                        id="branch_id"
                        defaultValue={beneficiary?.branch_id}
                        placeholder="Select Branch"
                        {...register("branch_id", {
                          required: "This is required",
                        })}
                        className="input-field"
                      >
                        <option value="1">Kabul</option>
                        <option value="2">Bamyan</option>
                        <option value="3">Paktia</option>
                      </Select>
                      <FormErrorMessage>
                        {errors.branch_id && errors.branch_id.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}

                  <FormControl isInvalid={errors.form_attachment}>
                    <FormLabel htmlFor="form_attachment">
                      Form Attachment (PDF only)
                    </FormLabel>
                    <Input
                      id="form_attachment"
                      type="file"
                      accept="application/pdf"
                      {...register("form_attachment", {})}
                      className="input-field"
                    />
                    {beneficiary?.form_attachment && (
                      <div className="attachment-info">
                        <span className="file-name">
                          {beneficiary?.form_attachment.split("/").pop()}{" "}
                        </span>
                      </div>
                    )}
                    <FormErrorMessage>
                      {errors.form_attachment && errors.form_attachment.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
              )}

              {activeStep === 1 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <FormControl isInvalid={errors.registration_date}>
                    <FormLabel htmlFor="registration_date">
                      Registration Date <span className="text-red-500">*</span>
                    </FormLabel>
                    <DatePicker
                      id="registration_date"
                      selected={registrationDate}
                      onChange={handleRegisterDateChange}
                      placeholderText="Select registration date"
                      dateFormat="yyyy-MM-dd"
                      className="input-field"
                      customInput={<Input />}
                      {...{
                        required: "This is required",
                      }}
                    />
                    <FormErrorMessage>
                      {errors.registration_date &&
                        errors.registration_date.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.contact_type}>
                    <FormLabel htmlFor="contact_type">
                      Contact Type <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="contact_type"
                      placeholder="Select contact type"
                      defaultValue={
                        beneficiary?.beneficiary_cases[0]?.contact_type
                      }
                      {...register("contact_type", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="PHONE">PHONE</option>
                      <option value="IN PERSON">IN PERSON</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.contact_type && errors.contact_type.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.used_tool}>
                    <FormLabel htmlFor="used_tool">
                      Used Tools <span className="text-red-500">*</span>
                    </FormLabel>
                    <ReactSelect
                      id="used_tool"
                      placeholder="Select tool(s)"
                      defaultValue={selectedTools}
                      value={selectedTools}
                      onChange={(selectedOptions) =>
                        setSelectedTools(selectedOptions || [])
                      }
                      className="input-field"
                      isMulti
                      options={[
                        { value: "GHQ-12", label: "GHQ-12" },
                        { value: "INTERVIEW", label: "DIAGNOSTIC INTERVIEW" },
                      ]}
                    />
                    <FormErrorMessage>
                      {errors.used_tool && errors.used_tool.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.intervention_type}>
                    <FormLabel htmlFor="intervention_type">
                      Intervention Type <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="intervention_type"
                      placeholder="Select intervention type"
                      defaultValue={
                        beneficiary?.beneficiary_cases[0]?.intervention_type
                      }
                      {...register("intervention_type", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="INDIVIDUAL">INDIVIDUAL</option>
                      <option value="GROUP">GROUP</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.intervention_type &&
                        errors.intervention_type.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={errors.appropriate_psychological_intervention}
                  >
                    <FormLabel htmlFor="appropriate_psychological_intervention">
                      Appropriate Psychological Intervention{" "}
                      <span className="text-red-500">*</span>
                    </FormLabel>
                    <ReactSelect
                      id="appropriate_psychological_intervention"
                      placeholder="Select psychological intervention"
                      defaultValue={selectedPsychologicalInterventions}
                      value={selectedPsychologicalInterventions}
                      onChange={(selectedOptions) =>
                        setSelectedPsychologicalInterventions(
                          selectedOptions || []
                        )
                      }
                      className="input-field"
                      isMulti
                      options={[
                        {
                          value: "PSYCHOTHERAPY COUNSELING",
                          label: "PSYCHOTHERAPY COUNSELING",
                        },
                        {
                          value: "FAMILY COUNSELING",
                          label: "FAMILY COUNSELING",
                        },
                        { value: "PM+", label: "PM+" },
                        { value: "TEACHING SKILLS", label: "TEACHING SKILLS" },
                        { value: "REFERRAL", label: "REFERRAL" },
                      ]}
                    />
                    <FormErrorMessage>
                      {errors.appropriate_psychological_intervention &&
                        errors.appropriate_psychological_intervention.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.psychological_issue_type}>
                    <FormLabel htmlFor="psychological_issue_type">
                      Psychological Issue Type{" "}
                      <span className="text-red-500">*</span>
                    </FormLabel>
                    <ReactSelect
                      id="psychological_issue_type"
                      placeholder="Select psychological issue type"
                      defaultValue={selectedPsychologicalIssues}
                      value={selectedPsychologicalIssues}
                      onChange={(selectedOptions) =>
                        setSelectPsychologicalIssueType(selectedOptions || [])
                      }
                      className="input-field"
                      isMulti
                      options={[
                        { value: "DEPRESSION", label: "DEPRESSION" },
                        { value: "ANXIETY", label: "ANXIETY" },
                        { value: "PTSD", label: "PTSD" },
                        {
                          value: "LACK OF LEARNING",
                          label: "LACK OF LEARNING",
                        },
                        { value: "STRESS", label: "STRESS" },
                        { value: "PSYCHOSIS", label: "PSYCHOSIS" },
                        { value: "ECONOMIC ISSUES", label: "ECONOMIC ISSUES" },
                        { value: "OCD", label: "OCD" },
                      ]}
                    />
                    <FormErrorMessage>
                      {errors.psychological_issue_type &&
                        errors.psychological_issue_type.message}
                    </FormErrorMessage>
                  </FormControl>

                  {(user?.role === "ADMIN" || user?.role === "MANAGER") && (
                    <FormControl isInvalid={errors.doctor_id}>
                      <FormLabel htmlFor="doctor_id">
                        Doctor <span className="text-red-500">*</span>
                      </FormLabel>
                      <Select
                        id="doctor_id"
                        placeholder="Select the doctor"
                        defaultValue={
                          beneficiary?.beneficiary_cases[0].doctor?.id
                        }
                        {...register("doctor_id", {
                          required: "This is required",
                        })}
                        className="input-field"
                      >
                        {doctors.map((doctorItem) => (
                          <option key={doctorItem.id} value={doctorItem.id}>
                            {doctorItem.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.doctor_id && errors.doctor_id.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  <FormControl isInvalid={errors.case_description}>
                    <FormLabel htmlFor="case_description">
                      Case Description
                    </FormLabel>
                    <Textarea
                      id="case_description"
                      placeholder="Case Description ..."
                      defaultValue={
                        beneficiary?.beneficiary_cases[0]?.case_description
                      }
                      {...register("case_description", {})}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.case_description &&
                        errors.case_description.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
              )}

              {activeStep === 2 && (
                <div style={{ height: "500px" }}>
                  {beneficiary?.beneficiary_cases?.[0].visits.map(
                    (visit, index) => (
                      <div key={index}>
                        <div className="my-2">
                          <Heading as="h5" size="sm" className="text-blue-600">
                            Meeting: {index + 1}
                          </Heading>
                        </div>
                        <FormControl>
                          <Input
                            type="hidden"
                            defaultValue={visit.id}
                            {...register(`visits.${index}.id`)}
                          />
                        </FormControl>

                        <FormControl isInvalid={errors.visits?.[index]?.date}>
                          <FormLabel htmlFor={`visits.${index}.date`}>
                            Visit Date <span className="text-red-500">*</span>
                          </FormLabel>
                          <Controller
                            name={`visits.${index}.date`}
                            control={control}
                            defaultValue={visit.date}
                            render={({ field }) => (
                              <Input type="text" {...field} size="lg" />
                            )}
                          />
                          <FormErrorMessage>
                            {errors.visits?.[index]?.date?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={errors.visits?.[index]?.session_duration}
                        >
                          <FormLabel
                            htmlFor={`visits.${index}.session_duration`}
                          >
                            Session Duration{" "}
                            <span className="text-red-500">*</span>
                          </FormLabel>
                          <Controller
                            name={`visits.${index}.session_duration`}
                            control={control}
                            defaultValue={visit?.session_duration}
                            render={({ field }) => (
                              <Input type="text" {...field} size="lg" />
                            )}
                          />
                          <FormErrorMessage>
                            {errors.visits?.[index]?.session_duration?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={errors.visits?.[index]?.main_activity}
                        >
                          <FormLabel htmlFor={`visits.${index}.main_activity`}>
                            Main Activities{" "}
                            <span className="text-red-500">*</span>
                          </FormLabel>
                          <Controller
                            name={`visits.${index}.main_activity`}
                            control={control}
                            defaultValue={visit?.main_activity}
                            render={({ field }) => (
                              <Textarea {...field} size="lg" />
                            )}
                          />
                          <FormErrorMessage>
                            {errors.visits?.[index]?.main_activity?.message}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={errors.visits?.[index]?.result}>
                          <FormLabel htmlFor={`visits.${index}.result`}>
                            Result <span className="text-red-500">*</span>
                          </FormLabel>
                          <Controller
                            name={`visits.${index}.result`}
                            control={control}
                            defaultValue={visit.result}
                            render={({ field }) => (
                              <Textarea {...field} size="lg" />
                            )}
                          />
                          <FormErrorMessage>
                            {errors.visits?.[index]?.result?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </div>
                    )
                  )}
                </div>
              )}

              {activeStep === 3 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <FormControl isInvalid={errors.examination_result}>
                    <FormLabel htmlFor="examination_result">
                      Examination Result <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="examination_result"
                      placeholder="Examination Result ..."
                      defaultValue={
                        beneficiary?.beneficiary_cases[0]?.examination_result
                      }
                      {...register("examination_result", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.examination_result &&
                        errors.examination_result.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.case_status}>
                    <FormLabel htmlFor="case_status">
                      Case Status <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="case_status"
                      defaultValue={
                        beneficiary?.beneficiary_cases[0]?.case_status
                      }
                      placeholder="Select Case Status"
                      {...register("case_status", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="IN PROCESS">In Process</option>
                      <option value="CLOSED">Closed</option>
                      <option value="REFERRED">Referred</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.case_status && errors.case_status.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.closing_date}>
                    <FormLabel htmlFor="closing_date">Closing Date</FormLabel>
                    <DatePicker
                      id="closing_date"
                      selected={closeDate}
                      onChange={handleDateChange}
                      placeholderText="Select Closing Date"
                      dateFormat="yyyy-MM-dd"
                      className="input-field"
                      customInput={<Input />}
                    />
                    <FormErrorMessage>
                      {errors.closing_date && errors.closing_date.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.referred_organization}>
                    <FormLabel htmlFor="referred_organization">
                      Referred Organization
                    </FormLabel>
                    <Input
                      id="referred_organization"
                      placeholder="Referred Organization"
                      defaultValue={
                        beneficiary?.beneficiary_cases[0]?.referred_organization
                      }
                      {...register("referred_organization", {})}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.referred_organization &&
                        errors.referred_organization.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.referred_description}>
                    <FormLabel htmlFor="referred_description">
                      Referred Description
                    </FormLabel>
                    <Textarea
                      id="referred_description"
                      placeholder="Referred Description ..."
                      defaultValue={
                        beneficiary?.beneficiary_cases[0]?.referred_description
                      }
                      {...register("referred_description", {})}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.referred_description &&
                        errors.referred_description.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.case_attachment}>
                    <FormLabel htmlFor="case_attachment">
                      Case Attachment (PDF only)
                    </FormLabel>
                    <Input
                      id="case_attachment"
                      type="file"
                      accept="application/pdf"
                      {...register("case_attachment", {})}
                      className="input-field"
                    />
                    {beneficiary?.beneficiary_cases[0]?.case_attachment && (
                      <div className="attachment-info">
                        <span className="file-name">
                          {beneficiary?.beneficiary_cases[0]?.case_attachment
                            .split("/")
                            .pop()}{" "}
                        </span>
                      </div>
                    )}
                    <FormErrorMessage>
                      {errors.case_attachment && errors.case_attachment.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
              )}

              {activeStep === 4 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <AbsoluteCenter>
                    <Img
                      alt="success"
                      width={300}
                      height={300}
                      src={successSVG}
                    />
                    <Heading size={"xs"}>
                      Pss Beneficiary has been successfully updated.
                    </Heading>
                  </AbsoluteCenter>
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              {activeStep !== 0 && activeStep < 4 && (
                <Button onClick={handleBack}>Back</Button>
              )}
              {activeStep === 3 && (
                <Button
                  colorScheme="teal"
                  isLoading={isSubmitting || isLoading}
                  type="submit"
                  ml={3}
                >
                  Update
                </Button>
              )}
              {activeStep < steps.length - 1 && activeStep !== 3 && (
                <Button
                  colorScheme="blue"
                  ml={3}
                  onClick={handleNext}
                  disabled={Object.values(fieldErrors).some((error) => !!error)}
                >
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button onClick={handleClose} ml={3}>
                  Close
                </Button>
              )}
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
