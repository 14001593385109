import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAuthStore = create(persist(
    (set) => ({
        token: null,
        user: null,
        isLoggedIn: false,
        login: ({token , user}) => set(() => ({ token: token, user:user, isLoggedIn: true })),
        logout: () => set({ token: null, user: null, isLoggedIn: false }),
    }), {
    name: 'auth',
},))

export const logout = useAuthStore.getState().logout;