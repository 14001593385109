import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Box,
  Divider,
  AbsoluteCenter,
  useToast,
  Img,
  InputRightElement,
  IconButton,
  InputGroup
} from "@chakra-ui/react";
import { useUpdateEmployee } from "queries/employee.query";
import { useAuthStore } from 'store/use-auth.store';
import { useCheckUser } from "queries/employee.query";
import { Switch } from "@chakra-ui/switch";
import { FaEye, FaEyeSlash } from "react-icons/fa";


export default function EditAdmin({ isOpen, onClose, onSuccessEdit, employee, checkData }) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm();
  const { mutate } = useUpdateEmployee();
  const password = useRef({});
  const { user } = useAuthStore();
  const { data: checkUser } = useCheckUser();
  password.current = watch("password", "");
  const current_password = useRef({});
  current_password.current = watch("current_password", "");
  const [showUserAccountFields, setShowUserAccountFields] = useState(false);
  const [showChangePasswordFields, setshowChangePasswordFields] = useState(false);
  const [isActive, setIsActive] = useState(employee?.user?.status === 'ACTIVE');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };
  
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const toast = useToast();
  const isDuplicateUsername = (value) => {
    return value !== employee.username && checkUser.some((user) => user.username === value);
  };
  const onCloseModal = () => {
    onClose();
    setShowUserAccountFields(false); // Reset to hide user account fields
    setshowChangePasswordFields(false); // Reset to hide change password fields if open
    reset(); // Reset form fields
  };


  const onSubmit = async (data) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("id", employee.id);
    formData.append("_method", "PUT");
    Object.keys(data).forEach((key) => {
      if (key === "photo") {
        formData.append("photo", data.photo[0]);
      } else {
        formData.append(key, data[key]);
      }
    });
    formData.append("status", isActive ? 'ACTIVE' : 'INACTIVE');
    mutate(formData, {
      onSuccess: () => {
        showToast({ status: "success", description: "Employee Edited Successfully" });
        onSuccessEdit();
        setIsLoading(false);
        onClose();
        reset();
        onCloseModal();
      },
      onError: (error) => {
        setIsLoading(false);
        showToast({ status: "error", description: "Failed to Edit Employee" });
      }
    });
  };
  const showToast = ({ status, description }) => {
    toast({
      title: status === "success" ? "Success" : "Error",
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };

  const isDuplicateTazkiraNumber = (value) => {
    let count = 0;
    for (let i = 0; i < checkData.length; i++) {
      if (checkData[i].tazkira_number === value && checkData[i].tazkira_number !== employee?.employee.tazkira_number) {
        count++;
      }
    }
    return count > 0;
  };
  const isDuplicatePhone = (value) => {
    let count = 0;
    for (let i = 0; i < checkData.length; i++) {
      if (checkData[i].phone === value && checkData[i].phone !== employee?.employee.phone) {
        count++;
      }
    }
    return count > 0;
  };
  const isDuplicateEmail = (value) => {
    let count = 0;
    for (let i = 0; i < checkData.length; i++) {
      if (checkData[i].email === value && checkData[i].email !== employee?.employee.email) {
        count++;
      }
    }
    return count > 0;
  };


  useEffect(() => {
    // Update isActive state when employee prop changes
    setIsActive(employee?.status === 'ACTIVE');
  }, [employee]);
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  if (!employee) {
    return null; // Render nothing if employee is null
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          Editing Profile
        </ModalHeader>
        <ModalCloseButton onClick={onCloseModal} />
        <ModalBody>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto max-w-screen-xl rounded bg-white p-4 shadow-md"
          >
            <Box className="w-full" position="relative">
              <Divider className="w-full mb-6 mt-3" />
              <AbsoluteCenter bg="white" px="4">
                <h3 style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
                   Personal Information
                </h3>
              </AbsoluteCenter>
            </Box>
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">

              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">Name <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="name"
                  placeholder="Name"
                  defaultValue={employee?.employee?.name}
                  {...register("name", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.father_name}>
                <FormLabel htmlFor="father_name">Father's Name <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="father_name"
                  defaultValue={employee?.employee.father_name}
                  placeholder="Father's Name"
                  {...register("father_name", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.father_name && errors.father_name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.lastname}>
                <FormLabel htmlFor="lastname">Last Name <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="lastname"
                  defaultValue={employee?.employee.lastname}
                  placeholder="Last Name"
                  {...register("lastname", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.last_name && errors.last_name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.age}>
                <FormLabel htmlFor="age">Age <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="age"
                  placeholder="Age"
                  defaultValue={employee?.employee.age}
                  {...register("age", {
                    required: "This is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid age",
                    },
                    maxLength: {
                      value: 3,
                      message: "Age should be at most 3 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.age && errors.age.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.gender}>
                <FormLabel htmlFor="gender">Gender <span className="text-red-500">*</span></FormLabel>
                <Select
                  id="gender"
                  placeholder="Select Gender"
                  defaultValue={employee?.employee.gender}
                  {...register("gender", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </Select>
                <FormErrorMessage>
                  {errors.gender && errors.gender.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.marital_status}>
                <FormLabel htmlFor="marital_status">Marital Status <span className="text-red-500">*</span></FormLabel>
                <Select
                  id="marital_status"
                  placeholder="Select Marital Status"
                  defaultValue={employee?.employee?.marital_status}
                  {...register("marital_status", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="SINGLE">Single</option>
                  <option value="MARRIED">Married</option>
                  <option value="DIVORCED">Divorced</option>
                  <option value="SEPARATED">Separated</option>
                  <option value="WIDOWED">Widowed</option>
                </Select>
                <FormErrorMessage>
                  {errors.marital_status && errors.marital_status.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.educational_degree}>
                <FormLabel htmlFor="educational_degree">
                  Educational Degree <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="educational_degree"
                  placeholder="Select Educational Degree"
                  defaultValue={employee?.employee.educational_degree}
                  {...register("educational_degree", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="ILLITERATE">Illiterate</option>
                  <option value="PRIMARY">Primary</option>
                  <option value="SECONDARY">Secondary</option>
                  <option value="HIGHER">Higher</option>
                </Select>
                <FormErrorMessage>
                  {errors.educational_degree &&
                    errors.educational_degree.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.address}>
                <FormLabel htmlFor="address">Address <span className="text-red-500">*</span></FormLabel>
                <Textarea
                  id="address"
                  placeholder="Address"
                  defaultValue={employee?.employee.address}
                  {...register("address", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.phone}>
                <FormLabel htmlFor="phone">Phone <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="phone"
                  placeholder="Phone"
                  defaultValue={employee?.employee.phone}
                  {...register("phone", {
                    required: "This is required",
                    pattern: {
                      value: /^[0-9+]+$/,
                      message: "Please enter only numbers and '+'",
                    },
                    validate: {
                      uniqueOrSameAsEmployeePhone: (value) => {
                        if (value === employee?.employee?.phone) {
                          return true; // No error if the value matches employee.phone
                        } else {
                          return !isDuplicatePhone(value) || "This phone number is already in use";
                        }
                      }
                    }
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.email}>
                <FormLabel htmlFor="email">Email <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="email"
                  placeholder="Email"
                  defaultValue={employee?.employee?.email}
                  type="email"
                  {...register("email", {
                    required: "This is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                    validate: {
                      uniqueOrSameAsEmployeeEmail: (value) => {
                        if (value === employee?.employee.email) {
                          return true; // No error if the value matches employee.emal
                        } else {
                          return !isDuplicateEmail(value) || "This Email  is already in use";
                        }
                      }
                    }
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.tazkira_number}>
                <FormLabel htmlFor="tazkira_number">Tazkira Number</FormLabel>
                <Input
                  id="tazkira_number"
                  placeholder="Tazkira Number"
                  defaultValue={employee?.employee.tazkira_number}
                  {...register("tazkira_number", {
                    pattern: {
                      value: /^[0-9-]+$/,
                      message: "Please enter only numbers and '-'",
                    },
                    validate: (value) => {
                      if (value === '') {
                        return true; // If the field is empty, no validation errors
                      }
                      
                      const patternValid = /^[0-9-]+$/.test(value);
                      if (!patternValid) {
                        return "Please enter only numbers and '-'";
                      }
                      
                      if (value === employee?.employee.tazkira_number) {
                        return true; // No error if the value matches employee.tazkira_number
                      }
                      
                      return !isDuplicateTazkiraNumber(value) || "This Tazkira number is already in use";
                    }
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.tazkira_number && errors.tazkira_number.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.position}>
                <FormLabel htmlFor="position">Position <span className="text-red-500">*</span></FormLabel>
                <Select
                  id="position"
                  placeholder="Select Position"
                  defaultValue={employee?.employee?.position}
                  {...register("position", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  {(user?.role === "ADMIN") && (<option value="ADMINISTRATOR">Administrator</option>)}
                  {(user?.role === "ADMIN" || (user?.role === "MANAGER" && user?.branch?.branch_name === "KABUL")) && (
                    <option value="PROVINCIAL MANAGER">Provincial Manager</option>
                  )}
                  <option value="VOCATIONAL TRAINER">Vocational Trainer</option>
                  <option value="MONITORING & EVALUATION">Monotoring & Evaluation</option>
                  <option value="PSYCHO-SOCIAL COUNSELLOR">Psycho-Social Counsellor</option>
                  <option value="LEGAL ADVISOR">Legal Advisor</option>
                  <option value="FEMALE DOCTOR">Femal Docotor</option>
                </Select>
                <FormErrorMessage>
                  {errors.position && errors.position.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.status}>
                <FormLabel htmlFor="status">Status <span className="text-red-500">*</span></FormLabel>
                <Select
                  id="emp_status"
                  placeholder="Select Status"
                  defaultValue={employee?.employee?.status}
                  {...register("emp_status", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="PENDING">Pending</option>
                  <option value="HIRED">Hired</option>
                  <option value="FIRED">Fired</option>
                </Select>
                <FormErrorMessage>
                  {errors.status && errors.status.message}
                </FormErrorMessage>
              </FormControl>
              {(user?.role === "ADMIN" || (user?.role === "MANAGER" && user?.branch?.branch_name === "KABUL")) && (
                <FormControl isInvalid={errors.branch_id}>
                  <FormLabel htmlFor="branch_id">Branch <span className="text-red-500">*</span></FormLabel>
                  <Select
                    id="branch_id"
                    placeholder="Select Branch"
                    defaultValue={employee?.employee?.branch_id}
                    {...register("branch_id", {
                      required: "This is required",
                    })}
                    className="input-field"
                  >
                    <option value="1">Kabul</option>
                    <option value="2">Bamyan</option>
                    <option value="3">Paktia</option>
                  </Select>
                  <FormErrorMessage>
                    {errors.branch_id && errors.branch_id.message}
                  </FormErrorMessage>
                </FormControl>
              )}

              <FormControl isInvalid={errors.photo}>
                <FormLabel htmlFor="photo">Photo <span className="text-red-500">*</span></FormLabel>
                <div className="flex items-center space-x-2"> {/* Added a div to wrap label and image */}
                  <Img src={`http://localhost:8000/${employee?.employee?.photo}`} alt="Employee Photo" className="h-12 w-12 rounded-full" />
                  <Input
                    id="photo"
                    type="file"
                    accept="image/*"
                    {...register("photo")}
                    className="input-field"
                  />
                </div>
                <FormErrorMessage>
                  {errors.photo && errors.photo.message}
                </FormErrorMessage>
              </FormControl>

            </div>

            <Box position="relative">
              <Divider className="w-full mt-10 mb-7" />
              <AbsoluteCenter bg="white" px="4">
                <h3 style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
                    User Account
                </h3>
              </AbsoluteCenter>
            </Box>
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="toggleUserAccount">User Account</FormLabel>
                <Switch
                  id="toggleUserAccount"
                  onChange={() => setShowUserAccountFields(!showUserAccountFields)}
                />
              </FormControl>

              {showUserAccountFields && (
                <>
                  <FormControl isInvalid={errors.username}>
                    <FormLabel htmlFor="username">Username <span className="text-red-500">*</span></FormLabel>
                    <Input
                      id="username"
                      placeholder="Username"
                      defaultValue={employee?.username}

                      {...register("username", {
                        required: "This is required",
                        validate: {
                          isDuplicateUsername: (value) => !isDuplicateUsername(value) || "Username already exists",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.username && errors.username.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="toggleUserAccount">Change Password</FormLabel>
                    <Switch
                      id="toggleUserAccount"
                      onChange={() => setshowChangePasswordFields(!showChangePasswordFields)}
                    />
                  </FormControl>
                  {showChangePasswordFields && (
                    <div>
                      <FormControl isInvalid={errors.password}>
                        <FormLabel htmlFor="password"> New Password <span className="text-red-500">*</span></FormLabel>
                        <InputGroup>
                          <Input
                            id="password"
                            type={showNewPassword ? "text" : "password"} // Updated
                            placeholder="Password"
                            {...register("password", {
                              required: "This is required",
                              minLength: {
                                value: 6,
                                message: "Password must have at least 6 characters",
                              },
                            })}
                            className="input-field"
                          />
                          <InputRightElement width="3rem">
                            <IconButton
                              aria-label={showNewPassword ? "Hide password" : "Show password"}
                              variant="ghost"
                              colorScheme="blue"
                              onClick={toggleNewPasswordVisibility}
                              icon={showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {errors.password && errors.password.message}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={errors.confirmPassword}>
                        <FormLabel htmlFor="confirmPassword">
                          Confirm Password <span className="text-red-500">*</span>
                        </FormLabel>
                        <InputGroup>
                          <Input
                              id="confirmPassword"
                              type={showConfirmPassword ? "text" : "password"} // Updated
                              placeholder="Confirm Password"
                              {...register("confirmPassword", {
                                required: "This is required",
                                validate: (value) =>
                                  value === password.current || "The passwords do not match",
                              })}
                              className="input-field"
                          />
                          <InputRightElement width="3rem">
                            <IconButton
                              aria-label={showConfirmPassword ? "Hide password" : "Show password"}
                              variant="ghost"
                              colorScheme="blue"
                              onClick={toggleConfirmPasswordVisibility}
                              icon={showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {errors.confirmPassword && errors.confirmPassword.message}
                        </FormErrorMessage>
                      </FormControl>
                    </div>
                  )
                  }
                  <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="toggleUserStatus">User Status</FormLabel>
                    <Switch
                      id="toggleUserStatus"
                      isChecked={isActive} // Set switch value based on isActive state
                      onChange={(e) => setIsActive(e.target.checked)} // Update isActive state
                    />
                  </FormControl>
                </>
              )}
            </div>
            <div className="mt-8 flex flex-row-reverse">
              <Button colorScheme="blue" onClick={onCloseModal}>
                Close
              </Button>
              <Button
                colorScheme="teal"
                isLoading={isSubmitting || isLoading} 
                type="submit"
                className=" mr-2"
              >
                Update
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

