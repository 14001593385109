import { useQuery , useMutation , useQueryClient} from "@tanstack/react-query";
import { postRequest } from "utils/http";
import { deleteRequest } from "utils/http";
import { getRequest } from "utils/http";

export const getPssBeneficiaryData = async (current, pageSize, searchValue , status, filterDateRange, gender , project, branch) => {
  return await getRequest("app/pss-beneficiary", {
    current: current,
    pageSize: pageSize,
    searchValue,
    status,
    filterDateRange,
    gender , project, branch
  });
};

export const useGetPssBeneficiaryData = (current, pageSize,searchValue , status, filterDateRange, gender , project, branch ) => {
  return useQuery({
    queryKey: ["fetch-PssBeneficiaries", current, pageSize,searchValue , status, filterDateRange, gender , project, branch],
    queryFn: () => getPssBeneficiaryData(current, pageSize,searchValue , status, filterDateRange, gender , project, branch),
  });
};

export const useSingleDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => singleDelete(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-PssBeneficiaries"]);
    },
  });
};

const singleDelete = async (id) => {
  return deleteRequest(`app/pss-beneficiary/${id}`, {
    params: {
      id,
    },
  });
};

export const deletePssBeneficiaries = async (ids) => {
  return deleteRequest(`app/deletePssBeneficiaries`, {
    params: {
      ids: ids.join(","),
    },
  });
};

export const useMultiDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids) => deletePssBeneficiaries(ids),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-PssBeneficiaries"]);
    },
  });
};


export const pssBeneficiaryCreate = (params) => {
  return postRequest(`app/pss-beneficiary`, params);
};
export const usePssBeneficiaryCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => pssBeneficiaryCreate(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-PssBeneficiaries"]);
    },
  });
};

export const pssBeneficiaryUpdate = (params) => {
  return postRequest(`app/pss-beneficiary/${params.get("id")}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const usePssBeneficiaryUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => pssBeneficiaryUpdate(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-PssBeneficiaries"]);
    },
  });
};



// get doctors data

export const getPssDoctors = async ()=>{
  return await getRequest("app/getPssDoctors");
}

export const useGetDoctors = () =>{
  return useQuery({
    queryKey: ["fetch-PssDoctors"],
    queryFn: () => getPssDoctors(),
  });
}

