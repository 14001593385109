import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { useAuthStore } from "store/use-auth.store";
import { PssEmployeeRoutes } from "routes";
import { FemaleDoctorEmployeeRoutes } from "routes";
import { LegalAdvisorEmployeeRoutes } from "routes";
import { VocationalTrainerEmployeeRoutes } from "routes";
import { MonitoringEvaluationEmployeeRoutes } from "routes";
import { managerRoutes } from "routes";
import { kabulManagerRoutes } from "routes";

export default function Admin(props) {
  const { ...rest } = props;
  const { token, isLoggedIn, user } = useAuthStore();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    if (user?.role === "ADMIN") {
      return routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route path={`/${prop.path}`} element={prop.component} key={key} />
          );
        } else {
          return null;
        }
      });
    }
    if (
      user?.role === "MANAGER" && user?.branch?.branch_name === 'KABUL'
    ) {
      return kabulManagerRoutes.map((prop, key) => {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      });
    }
    if (
      user?.role === "MANAGER"
    ) {
      return managerRoutes.map((prop, key) => {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      });
    }
    if (
      user?.role === "EMPLOYEE" &&
      user?.employee?.position === "PSYCHO-SOCIAL COUNSELLOR"
    ) {
      return PssEmployeeRoutes.map((prop, key) => {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      });
    }
    if (
      user?.role === "EMPLOYEE" &&
      user?.employee?.position === "FEMALE DOCTOR"
    ) {
      return FemaleDoctorEmployeeRoutes.map((prop, key) => {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      });
    }
    if (
      user?.role === "EMPLOYEE" &&
      user?.employee?.position === "LEGAL ADVISOR"
    ) {
      return LegalAdvisorEmployeeRoutes.map((prop, key) => {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      });
    }
    if (
      user?.role === "EMPLOYEE" &&
      user?.employee?.position === "VOCATIONAL TRAINER"
    ) {
      return VocationalTrainerEmployeeRoutes.map((prop, key) => {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      });
    }
    if (
      user?.role === "EMPLOYEE" &&
      user?.employee?.position === "MONITORING & EVALUATION"
    ) {
      return MonitoringEvaluationEmployeeRoutes.map((prop, key) => {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      });
    }
  };

  if (!token || !isLoggedIn) {
    return <Navigate to="/auth/sign-in" replace />;
  }
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"DAWEO"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
