import { MdPerson } from "react-icons/md";


import Widget from "components/widget/Widget";
import { useDashboard } from "queries/dashboard.query";
import { useState } from "react";
import { Button, Card, FormControl, Select } from "@chakra-ui/react";
import Statistic from "./components/Statistic";
import GeneralPieChart from "./components/GeneralPieChart";
import GraphStatusReports from "./components/GraphStatusReports";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { useAuthStore } from "store/use-auth.store";
import CardDesign from "./components/CardDesign";


const Dashboard = () => {
  const [branch, setBranch] = useState();
  const [year, setYear] = useState(new Date().getFullYear());
  const [focusedInput, setFocusedInput] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const { user } = useAuthStore();

  const { data, isError } = useDashboard(
    branch,
    year,
    dateRange
  );
  const totalRecord = data?.total;
  const vocational = data?.vocational;
  const employee = data?.employee;
  const pss = data?.pss;
  const legalAdvisor = data?.ligalAdviosr;
  const monitoring = data?.monitoring;
  const femaleDoctor = data?.fDoctors;

  // Get the current year dynamically
  const currentYear = new Date().getFullYear();
  const startYear = 2010;
  let years = ['Current Year'];

  // Generate the years array dynamically, excluding the current year
  for (let year = currentYear - 1; year >= startYear; year--) {
    years.push(year);
  }

  // State for selected year

  // Handle year change function
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleBranchChange = (e) => {
    setBranch(e.target.value);
    data({ branch: e.target.value }); // Sending branch_id to usedata
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };
  let isDateRangeFilled = dateRange.startDate && dateRange.endDate;
  const handleResetDates = () => {
    setDateRange({ startDate: null, endDate: null });
  };
  if (isError) {
    return <div>Error loading employee</div>;
  }

  return (
    <div>
      {/* select Branch */}
      <div className="mt-5 flex flex-col md:flex-row gap-5 justify-end">
        <div className="flex flex-col md:flex-row gap-5">
          {((user?.role === "ADMIN") || (user?.role === 'MANAGER' && user?.branch?.branch_name === 'KABUL')) && (
            <Card className="flex-1 flex-col md:flex-row gap-5" style={{ paddingTop: "4px" }}>
              <FormControl>
                <Select
                  id="branch"
                  placeholder="Select Branch"
                  className="input-field"
                  value={branch}
                  onChange={handleBranchChange}
                >
                  <option value="all">All</option>
                  <option value="1">Kabul</option>
                  <option value="2">Bamyan</option>
                  <option value="3">Paktia</option>
                </Select>
              </FormControl>
            </Card>
          )}
          <Card style={{ paddingTop: "4px" }}>
            <FormControl>
              <Select
                id="year"
                placeholder="Select Year"
                className="input-field"
                value={year}
                onChange={handleYearChange}
              >
                {years.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </Select>

            </FormControl>
          </Card>
          <Card>
            <FormControl>
              <DateRangePicker
                startDate={dateRange.startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={dateRange.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={handleDatesChange} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                isOutsideRange={() => false}
              />
              {isDateRangeFilled && (
                <Button onClick={handleResetDates} colorScheme="red" size="lg">Reset</Button>
              )}
            </FormControl>
          </Card>
        </div>
      </div>
      {/* Card widget */}


      {(user?.role === "ADMIN" || (user?.role === 'MANAGER' && user?.branch?.branch_name === 'KABUL')) && (
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
          <Widget
            icon={<MdPerson className="h-7 w-7" />}
            title={"Employee"}
            subtitle={totalRecord?.employee}
          />
          <Widget
            icon={<MdPerson className="h-6 w-6" />}
            title={"Pss Beneficiaries"}
            subtitle={totalRecord?.pss}
          />
          <Widget
            icon={<MdPerson className="h-7 w-7" />}
            title={"Vocational Training "}
            subtitle={totalRecord?.vtBeneficiary}
          />
          <Widget
            icon={<MdPerson className="h-6 w-6" />}
            title={"Legal Advisor"}
            subtitle={totalRecord?.legalAdvisor}
          />
          <Widget
            icon={<MdPerson className="h-7 w-7" />}
            title={"M&E Evaluations"}
            subtitle={totalRecord?.mne}
          />
          <Widget
            icon={<MdPerson className="h-6 w-6" />}
            title={"Female Doctors"}
            subtitle={totalRecord?.femaleDoctors}
          />
        </div>
      )}

      {/* Employees  */}

      {((user?.role === "ADMIN") || (user?.role === 'MANAGER' && user?.branch?.branch_name === 'KABUL')) && (
        <div className="mt-5 grid grid-cols-1 gap-5">
          <Card style={{ padding: "20px" }}>
            <h1 style={{ fontWeight: "bold", fontSize: "18px", paddingBottom: "10px" }}>Employees Statistics</h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
              <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
                <Statistic
                  title="Employees Count"
                  femaleCount={employee?.female}
                  maleCount={employee?.male}
                  otherCount={employee?.other}
                  totalCount={employee?.total}
                  bgColor="purple"
                />
              </div>
              <div className="grid grid-cols-1 md:col-span-2 gap-5 rounded-[20px] shadow-md">
                <GraphStatusReports
                  title="Employees' Positions Statistics"
                  data={
                    user?.role === "ADMIN" ?
                      [
                        { label: "Administrator", value: employee?.administrator },
                        { label: "Provincial Manager", value: employee?.provincial_manager },
                        { label: "Vocational Trainer", value: employee?.vocational_trainer },
                        { label: "Psycho Social Counsellor", value: employee?.psycho_social_counsellor },
                        { label: "Legal Advisor", value: employee?.legal_advisor },
                        { label: "Monitoring Evaluation", value: employee?.monitoring_evaluation },
                        { label: "Female Doctor", value: employee?.female_doctor },
                      ] :
                      [
                        { label: "Provincial Manager", value: employee?.provincial_manager },
                        { label: "Vocational Trainer", value: employee?.vocational_trainer },
                        { label: "Psycho Social Counsellor", value: employee?.psycho_social_counsellor },
                        { label: "Legal Advisor", value: employee?.legal_advisor },
                        { label: "Monitoring Evaluation", value: employee?.monitoring_evaluation },
                        { label: "Female Doctor", value: employee?.female_doctor },
                      ]
                  }
                  colors={["#e0b743", "#6AD2FF", "#33a65b"]}
                />
              </div>
              <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
                <GeneralPieChart
                  title="Employees Status Statistics"
                  data={[
                    { label: "Pending", value: employee?.pending },
                    { label: "Hired", value: employee?.hired },
                    { label: "Fired", value: employee?.fired },
                  ]}
                  total={employee?.total}
                  totalTitle="Total Employee"
                  colors={["#e0b743", "#24a67d", "#c94d34"]}
                />
              </div>
            </div>

          </Card>
        </div>
      )}
      {/* Pss  */}
      {(user?.role === "ADMIN" || user?.role === "MANAGER" || (user?.role === 'EMPLOYEE' && user?.employee?.position === 'PSYCHO-SOCIAL COUNSELLOR')) && (
        <div className="mt-5 grid grid-cols-1 gap-5">
          <Card style={{ padding: "20px" }}>
            <h1 style={{ fontWeight: "bold", fontSize: "18px", paddingBottom: "10px" }}>PSS Beneficiaries Statistics</h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
              {(user?.role === "ADMIN" || user?.role === "MANAGER") && (
                <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
                  <Statistic
                    title="Doctors Count"
                    femaleCount={pss?.doctors_female}
                    maleCount={pss?.doctors_male}
                    otherCount={pss?.doctors_other}
                    totalCount={pss?.doctors_total}
                    bgColor="cyan"
                  />
                </div>
              )}
              <div className="grid grid-cols-1 md:col-span-2 gap-5 rounded-[20px] shadow-md">
                <GraphStatusReports
                  title="PSS Beneficiaries Status Statistics"
                  data={[
                    { label: "IN PROCESS", value: pss?.cases_in_process },
                    { label: "REFERRED", value: pss?.cases_referred },
                    { label: "CLOSED", value: pss?.cases_closed },
                  ]}
                  colors={["#6AD2FF", "#6AD2FF", "#33a65b"]}
                />
              </div>
              <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
                <GeneralPieChart
                  title="Beneficiaries Gender Statistics"
                  data={[
                    { label: "Male", value: pss?.beneficiaries_male },
                    { label: "Female", value: pss?.beneficiaries_female },
                    { label: "Others", value: pss?.beneficiaries_other },
                  ]}
                  totalTitle="Total Beneficiary"
                  total={pss?.beneficiaries_total}
                  colors={["#4318FF", "#6AD2FF", "#33a65b"]}
                />
              </div>
            </div>

          </Card>
        </div>
      )}



      {/* Vocational Trainer  */}

      {(user?.role === "ADMIN" || user?.role === "MANAGER" || (user?.role === 'EMPLOYEE' && user?.employee?.position === 'VOCATIONAL TRAINER')) && (
        <div className="mt-5 grid grid-cols-1 gap-5">
          <Card style={{ padding: "20px" }}>
            <h1 style={{ fontWeight: "bold", fontSize: "18px", paddingBottom: "10px" }}>Vocational Trainings Statistics</h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
              {(user?.role === "ADMIN" || user?.role === "MANAGER") && (
                <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
                  <Statistic
                    title="Vocational Trainers Count"
                    femaleCount={vocational?.totalFemaleTrainers}
                    maleCount={vocational?.totalMaleTrainers}
                    otherCount={vocational?.totalOtherTrainers}
                    totalCount={vocational?.totalTrainers}
                    bgColor="blue"
                  />
                </div>
              )}
              <div className="grid grid-cols-1 md:col-span-2 gap-5 rounded-[20px] shadow-md">
                <GraphStatusReports
                  title="Vocational Beneficiaries Status Statistics"
                  data={[
                    { label: "Pending", value: vocational?.pending },
                    { label: "Ongoing", value: vocational?.ongoing },
                    { label: "Complete", value: vocational?.completed },
                    { label: "Package Received", value: vocational?.package_received },
                  ]}
                  colors={["#4318FF", "#6AD2FF", "#33a65b"]}
                />
              </div>
              <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
                <GeneralPieChart
                  title="Beneficiaries Gender Statistics"
                  data={[
                    { label: "Male", value: vocational?.pMale },
                    { label: "Female", value: vocational?.pFemale },
                    { label: "Others", value: vocational?.pOthers },
                  ]}
                  totalTitle="Total Beneficiary"
                  total={vocational?.pTotal}
                  colors={["#4318FF", "#6AD2FF", "#33a65b"]}
                />
              </div>
            </div>

          </Card>
        </div>

      )}

      {/* Legal Advisor */}
      {(user?.role === "ADMIN" || user?.role === "MANAGER" || (user?.role === 'EMPLOYEE' && user?.employee?.position === 'LEGAL ADVISOR')) && (
        <div className="mt-5 grid grid-cols-1 gap-5">
          <Card style={{ padding: "20px" }}>
            <h1 style={{ fontWeight: "bold", fontSize: "18px", paddingBottom: "10px" }}>Legal Advisors Statistics </h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
              {((user?.role === "ADMIN") || (user?.role === 'MANAGER')) && (
                <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
                  <Statistic
                    title="Legal Advisors Count"
                    femaleCount={legalAdvisor?.advisor_female}
                    maleCount={legalAdvisor?.advisor_male}
                    otherCount={legalAdvisor?.advisor_other}
                    totalCount={legalAdvisor?.advisor_total}
                    bgColor="orange"
                  />
                </div>
              )}
              <div className="grid grid-cols-1 md:col-span-2 gap-5 rounded-[20px] shadow-md">
                <GraphStatusReports
                  title="Legal Advisor Beneficiaries Status Statistics"
                  data={[
                    { label: "Pending", value: legalAdvisor?.legal_case_pending },
                    { label: "IN PROGRESS", value: legalAdvisor?.legal_case_in_process },
                    { label: "Closed", value: legalAdvisor?.legal_case_closed },
                  ]}
                  colors={["#154057", "#6AD2FF", "#33a65b"]}
                />
              </div>
              <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
                <GeneralPieChart
                  title="Beneficiaries Gender Statistics"
                  data={[
                    { label: "Female", value: legalAdvisor?.opposite_total }
                  ]}
                  totalTitle="Total Beneficiary"
                  total={legalAdvisor?.opposite_total}
                  colors={["#33a65b"]}
                />
              </div>
            </div>

          </Card>
        </div>
      )}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {(user?.role === "ADMIN" || user?.role === "MANAGER" || (user?.role === 'EMPLOYEE' && user?.employee?.position === 'FEMALE DOCTOR')) && (
          <CardDesign
            title="Female Doctors Statistics"
            totalLabel="Total Female Doctors"
            totalCount={femaleDoctor?.doctors}
            beneficiaryLabel="TOTAL DIAGNOSED PATIENTS"
            beneficiaryCount={femaleDoctor?.fd_count}
            bgColor="purple"
          />
        )}
        {((user?.role === "ADMIN") || (user?.role === 'EMPLOYEE' && user?.employee?.position === 'MONITORING & EVALUATION') || (user?.role === 'MANAGER' && user?.branch?.branch_name === 'KABUL')) && (
          <CardDesign
            title="Monitoring Evaluations Statistics"
            totalLabel="Total Monitoring Officers"
            totalCount={monitoring?.officer}
            beneficiaryLabel="Total Reports"
            beneficiaryCount={monitoring?.m_count}
            bgColor="cyan"
          />
        )}
      </div>

    </div>
  );
};

export default Dashboard;
