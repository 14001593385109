import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Box,
  Divider,
  AbsoluteCenter,
  useToast 
} from "@chakra-ui/react";
import { useEmployeeCreate } from "queries/employee.query";
import { useAuthStore } from 'store/use-auth.store';
import { useCheckUser } from "queries/employee.query";

export default function EmployeeFormModal({ isOpen, onClose , onSuccess ,checkData}) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm();
  const { mutate } = useEmployeeCreate();
  const { user } = useAuthStore();
  const {data:checkUser} = useCheckUser();
  const [isLoading, setIsLoading] = useState(false);


  const password = React.useRef({});
  password.current = watch("password", "");
  const toast = useToast();
  const isDuplicateUsername = (value) => {
    return checkUser.some((user) => user.username === value);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (key === "photo") {
          formData.append("photo", data.photo[0]);
        } else {
          formData.append(key, data[key]);
        }
      });

      mutate(formData, {
        onSuccess: () => {
          showToast({ status: "success", description: "Employee added successfully" });
          onSuccess();
          setIsLoading(false);
          onClose();
          reset();
        },
        onError: (error) => {
          setIsLoading(false);
          showToast({ status: "error", description:  "Failed to add employee"  });

        }
        });
    } catch (error) {
      setIsLoading(false);
        showToast({ status: "error", description: "Failed to add employee"  });
    }
  };
  const showToast = ({ status, description }) => {
    toast({
      title: status === "success" ? "Success" : "Error",
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right", // Set the position to "top"
    });
  };
  const isDuplicateTazkiraNumber = (value) => {
    let count = 0;
    for (let i = 0; i < checkData.length; i++) {
      if (checkData[i].tazkira_number === value) {
        count++;
      }
    }
    return count > 0;
  };
  const isDuplicatePhone = (value) => {
    let count = 0;
    for (let i = 0; i < checkData.length; i++) {
        if (checkData[i].phone === value) {
            count++;
        }
    }
    return count > 0;
};
  const isDuplicateEmail = (value) => {
    let count = 0;
    for (let i = 0; i < checkData.length; i++) {
      if (checkData[i].email === value) {
        count++;
      }
    }
    return count > 0;
  };
  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          Creating New Employee
        </ModalHeader>
        <ModalCloseButton    
          onClick={() => {
            onClose();
            reset();
          }}/>
        <ModalBody style={{ overflowX: 'auto', maxHeight: '650px' }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto max-w-screen-xl rounded bg-white p-4 shadow-md"
          >
              <Box className="w-full" position="relative">
                <Divider className="w-full mb-6 mt-3" />
                <AbsoluteCenter bg="white" px="4">
                  <h3 style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
                    Employee Personal Information
                  </h3>
                </AbsoluteCenter>
              </Box>
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
             
              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">Name <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="name"
                  placeholder="Name"
                  {...register("name", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.father_name}>
                <FormLabel htmlFor="father_name">Father's Name <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="father_name"
                  placeholder="Father's Name"
                  {...register("father_name", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.father_name && errors.father_name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.lastname}>
                <FormLabel htmlFor="lastname">Last Name <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="lastname"
                  placeholder="Last Name"
                  {...register("lastname", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.last_name && errors.last_name.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.age}>
                <FormLabel htmlFor="age">Age <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="age"
                  placeholder="Age"
                  {...register("age", {
                    required: "This is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid age",
                    },
                    maxLength: {
                      value: 3,
                      message: "Age should be at most 3 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.age && errors.age.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.gender}>
                <FormLabel htmlFor="gender">Gender <span className="text-red-500">*</span></FormLabel>
                <Select
                  id="gender"
                  placeholder="Select Gender"
                  {...register("gender", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </Select>
                <FormErrorMessage>
                  {errors.gender && errors.gender.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.marital_status}>
                <FormLabel htmlFor="marital_status">Marital Status <span className="text-red-500">*</span></FormLabel>
                <Select
                  id="marital_status"
                  placeholder="Select Marital Status"
                  {...register("marital_status", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="SINGLE">Single</option>
                  <option value="MARRIED">Married</option>
                  <option value="DIVORCED">Divorced</option>
                  <option value="SEPARATED">Separated</option>
                  <option value="WIDOWED">Widowed</option>
                </Select>
                <FormErrorMessage>
                  {errors.marital_status && errors.marital_status.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.educational_degree}>
                <FormLabel htmlFor="educational_degree">
                  Educational Degree <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="educational_degree"
                  placeholder="Select Educational Degree"
                  {...register("educational_degree", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="ILLITERATE">Illiterate</option>
                  <option value="PRIMARY">Primary</option>
                  <option value="SECONDARY">Secondary</option>
                  <option value="HIGHER">Higher</option>
                </Select>
                <FormErrorMessage>
                  {errors.educational_degree &&
                    errors.educational_degree.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.address}>
                <FormLabel htmlFor="address">Address <span className="text-red-500">*</span></FormLabel>
                <Textarea
                  id="address"
                  placeholder="Address"
                  {...register("address", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.phone}>
                <FormLabel htmlFor="phone">Phone <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="phone"
                  placeholder="Phone"
                  {...register("phone", {
                    required: "This is required",
                    pattern: {
                      value: /^[0-9+]+$/,
                      message: "Please enter only numbers and '+'",
                    },
                    validate: (value) => {
                      return isDuplicatePhone(value) ? "This phone number already exists." : true;
                  },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.email}>
                <FormLabel htmlFor="email">Email <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="email"
                  placeholder="Email"
                  type="email"
                  {...register("email", {
                    required: "This is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                    validate: (value) => {
                      return isDuplicateEmail(value) ? "This Email number already exists." : true;
                  },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.tazkira_number}>
                <FormLabel htmlFor="tazkira_number">Tazkira Number</FormLabel>
                <Input
                  id="tazkira_number"
                  placeholder="Tazkira Number"
                  {...register("tazkira_number",
                   {
                    pattern: {
                      value: /^[0-9-]+$/,
                      message: "Please enter only numbers and '-'",
                    },
                    validate: (value) => {
                      // If the value is provided, check for uniqueness
                      if (value && value.trim() !== "") {
                        return isDuplicateTazkiraNumber(value) ? "This tazkira number already exists." : true;
                      }
                      // If no value is provided, it's valid
                      return true;
                    }
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.tazkira_number && errors.tazkira_number.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.position}>
                <FormLabel htmlFor="position">Position <span className="text-red-500">*</span></FormLabel>
                <Select
                  id="position"
                  placeholder="Select Position"
                  {...register("position", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  {(user?.role === "ADMIN") && (<option value="ADMINISTRATOR">Administrator</option>)}
                 {(user?.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name === "KABUL")) && ( 
                  <option value="PROVINCIAL MANAGER">Provincial Manager</option>
                  )}
                  <option value="VOCATIONAL TRAINER">Vocational Trainer</option>
                  <option value="MONITORING & EVALUATION">Monotoring & Evaluation</option>
                  <option value="PSYCHO-SOCIAL COUNSELLOR">Psycho-Social Counsellor</option>
                  <option value="LEGAL ADVISOR">Legal Advisor</option>
                  <option value="FEMALE DOCTOR">Femal Docotor</option>
                </Select>
                <FormErrorMessage>
                  {errors.position && errors.position.message}
                </FormErrorMessage>
              </FormControl>
              {(user?.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name === "KABUL")) && (
                <FormControl isInvalid={errors.branch_id}>
                  <FormLabel htmlFor="branch_id">Branch <span className="text-red-500">*</span></FormLabel>
                  <Select
                    id="branch_id"
                    placeholder="Select Branch"
                    {...register("branch_id", {
                      required: "This is required",
                    })}
                    className="input-field"
                  >
                    <option value="1">Kabul</option>
                    <option value="2">Bamyan</option>
                    <option value="3">Paktia</option>
                  </Select>
                  <FormErrorMessage>
                    {errors.branch_id && errors.branch_id.message}
                  </FormErrorMessage>
                </FormControl>
              )}

              <FormControl isInvalid={errors.photo}>
                <FormLabel htmlFor="photo">Photo <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="photo"
                  type="file"
                  accept="image/*"
                  {...register("photo", {
                    required: "Please upload your photo",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.photo && errors.photo.message}
                </FormErrorMessage>
              </FormControl>
              </div>

              <Box position="relative">
                <Divider className="w-full mt-10 mb-7" />
                <AbsoluteCenter bg="white" px="4">
                  <h3 style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
                    User Account Creation
                  </h3>
                </AbsoluteCenter>
              </Box>
              <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
              <FormControl isInvalid={errors.username}>
                <FormLabel htmlFor="username">Username <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="username"
                  placeholder="Username"
                  {...register("username", {
                    required: "This is required",
                    validate: {
                      isDuplicateUsername: (value) => !isDuplicateUsername(value) || "Username already exists",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.username && errors.username.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.password}>
                <FormLabel htmlFor="password">Password <span className="text-red-500">*</span></FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Password"
                  {...register("password", {
                    required: "This is required",
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.confirmPassword}>
                <FormLabel htmlFor="confirmPassword">
                  Confirm Password <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    required: "This is required",
                    validate: (value) =>
                      value === password.current ||
                      "The passwords do not match",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.confirmPassword && errors.confirmPassword.message}
                </FormErrorMessage>
              </FormControl>
            </div>
            <div className="mt-8 flex flex-row-reverse">
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
              <Button
                colorScheme="teal"
                isLoading={isSubmitting || isLoading} 
                type="submit"
                className=" mr-2"
              >
                Submit
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
