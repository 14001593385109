import { Button, ChakraProvider, Menu, MenuButton, MenuItem, MenuList, Spinner, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  useGetVTBeneficiaryData,
  useMultiDelete,
  useSingleDelete,
} from "./../../queries/vt_benenficiary.query";
import Card from "components/card";
import FilterButton from "components/Buttons/FilterButton";
import CreateButton from "components/Buttons/CreateButton";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import ViewButton from "components/Buttons/ViewButton";
import DataTable from "components/DataTable/DataTable";
import CustomPagination from "components/CustomPagenation/CustomPagenation";
import { FiSearch } from "react-icons/fi";
import EditVTBeneficiary from "./components/edit.vtbeneficiary.form";
import ViewVTBenficiary from "./components/view.vtbeneficiary.modal";
import AddVTBeneficiary from "./components/add.vtbeneficiary.form";
import DeleteConfirmation from "components/DeleteConfirmation/DeleteConfirmation";
import DownloadPDF from "views/DownloadPDF";
import FilterModal from "components/filter/filter";
import { useAuthStore } from "store/use-auth.store";
import { ChevronDownIcon } from "@chakra-ui/icons";
import DownloadExcel from "views/DownloadExcel";


function VTBenificiary() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    searchValue: null,
    status: [],
    filterDateRange: null,
    gender: [],
    branch: [],
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const { mutate: singleDeleteMutation } = useSingleDelete();
  const { mutate: multiDeleteMutation } = useMultiDelete();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [selectedVTraining, setSelectedVTraining] = useState(null);
  const [editSuccess, setEditSuccess] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const { user } = useAuthStore();

  const toast = useToast();
  const { data, isLoading, isError } = useGetVTBeneficiaryData(
    pagination.current,
    pagination.pageSize,
    pagination.searchValue,
    pagination.status,
    pagination.filterDateRange,
    pagination.gender,
    pagination.branch
  );

  const vt_status = [
    { title: "ONGOING", value: "ONGOING" },
    { title: "PENDING", value: "PENDING" },
    { title: "COMPLETED", value: "COMPLETED" },
  ];
  const DownloadData = data?.data;
  const title = "Vocational Training";
  const pdfName = "vocational_training";
  const xlFile = "vocational_training";
  const totalRecord = data?.data?.length;
  const onCloseAlert = () => {
    setAlertOpen(false);
  };
  const handleEdit = () => {
    if (selectedRowKeys.length === 1) {
      const selectedVTrainingData = data?.data.find(
        (employee) => employee.id === selectedRowKeys[0]
      );
      setSelectedVTraining(selectedVTrainingData);
      setEditModalOpen(true);
    } else if(selectedRowKeys.length > 1){
      toast({
        title: "Selected More Than One",
        description: "Please select Exactly one record to Edit.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }else {
      toast({
        title: "NO Recode Selected",
        description: "Please Selete At Least One Record To Edit.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = () => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.length === 1) {
        setConfirmMessage("Are you sure you want to delete this record?");
      } else {
        setConfirmMessage(
          `Are you sure you want to delete ${selectedRowKeys.length} records?`
        );
      }
      setAlertOpen(true);
    } else {
      toast({
        title: "NO Recode Selected",
        description: "Please select at least one record to delete.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.length === 1) {
        singleDeleteMutation(selectedRowKeys[0], {
          onSuccess: () => {
            toast({
              title: "Delete",
              description: "One record was successfully deleted.",
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
            setSelectedRowKeys([]);
            onCloseAlert();
          },
          onError: () => {
            toast({
              title: "Delete",
              description: "Failed to delete the record.",
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          },
        });
      } else {
        multiDeleteMutation(selectedRowKeys, {
          onSuccess: () => {
            toast({
              title: "Delete",
              description: `${selectedRowKeys.length} Records were successfully  deleted.`,
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
            setSelectedRowKeys([]);
            onCloseAlert();
          },
          onError: () => {
            toast({
              title: "Delete",
              description: `${selectedRowKeys.length} Failed to delete the records.`,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          },
        });
      }
    } else {
      toast({
        title: "Delete",
        description: "Please select at least one record to delete.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleView = () => {
    if (selectedRowKeys.length === 1) {
      const selectedVTrainingData = data?.data.find(
        (employee) => employee.id === selectedRowKeys[0]
      );
      setSelectedVTraining(selectedVTrainingData);
      setViewModalOpen(true);
      setSelectedRowKeys([]);
    } else if(selectedRowKeys.length > 1){
      toast({
        title: "Selected More Than One",
        description: "Please select Exactly one record to View.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }else {
      toast({
        title: "NO Recode Selected",
        description: "Please Selete At Least One Record To View.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleFilter = () => {
    setFilterModalOpen(true);
  };
  const onSearch = (e) => {
    if (e.target.value.trim() === "") {
      setSearchInput(e.target.value);
      setPagination({ ...pagination, searchValue: e.target.value });
    }
    if (e.key === "Enter") {
      setSearchInput(e.target.value);
      setPagination({ ...pagination, searchValue: e.target.value });
    }
  };

  const onPageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
  };
  const applyFilter = (filterOption) => {
  };
  const onPageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value });
  };

  const onSelectRow = (selectedRows) => {
    setSelectedRowKeys(selectedRows);
  };
  const onSuccessCreate = () => {
    setPagination({ ...pagination, current: 1 });
  };
  const onSuccessEdit = () => {
    setEditSuccess(!editSuccess); // Toggle editSuccess to trigger reload
    setSelectedRowKeys([]);
  };

  if (isError) {
    return <div>Error loading employee</div>;
  }
  const is_package_received = data?.data?.is_package_received === 1? "YES":"NO";
  const became_self_employeed = data?.data?.became_self_employeed === 1? "YES":"NO";
  const branchSpecificHeaders =
    user.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name ==="KABUL")? ["BRANCH"] : [];
  const branchSpecificKeys =
    user.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name ==="KABUL") ? ["branch.branch_name"] : [];
  const headers = [
    "ID",
    "NAME",
    "FATHER NAME",
    "IDENTIFICATION TYPE",
    "IDENTIFICATION#",
    "AGE",
    "GENDER",
    "EDUCATION DEGREE",
    "MARITAL STATUS",
    "PHONE",
    "ADDRESS",
    "SURVEY TYPE",
    "FAMILY NATURE",
    "HOUSE HOLD HEAD",
    "HOUSE HEAD JOB",
    "FAMILY WORKING MEMBERS#",
    "CLASS",
    "CLASS SESSION",
    "CLASS STATUS",
    "START DATE",
    "COMPLETE DATE",
    "IS PACKAGE RECEIVED",
    "BECAME SELF EMPLOYEED",
    "TRAINER",
    "TRAINING BENEFIT",
    "CREATED BY",
    ...branchSpecificHeaders
  ];

  const keys = [
    "id",
    "name",
    "father_name",
    "identification_type",
    "identification_number",
    "age",
    "gender",
    "educational_degree",
    "marital_status",
    "phone",
    "address",
    "survey_type",
    "family_nature",
    "house_hold_head",
    "house_head_job",
    "family_working_members_number",
    "classes.name",
    "session",
    "class_status",
    "class_start_date",
    "complete_date",
    "is_package_received",
    "became_self_employeed",
    "trainer.name",
    "training_benefit",
    "created_by.username",
    ...branchSpecificKeys
  ];
  const Dheaders = [
    "NAME",
    "FATHER NAME",
    "IDENTIFICATION TYPE",
    "IDENTIFICATION#",
    "AGE",
    "GENDER",
    "EDUCATION DEGREE",
    "MARITAL STATUS",
    "PHONE",
    "ADDRESS",
    "CLASS",
    "TRAINER",
    ...branchSpecificHeaders
  ];

  const Dkeys = [
    "name",
    "father_name",
    "identification_type",
    "identification_number",
    "age",
    "gender",
    "educational_degree",
    "marital_status",
    "phone",
    "address",
    "classes.name",
    "trainer.name",
    ...branchSpecificKeys
  ];
  const Eheaders = [
    "NAME",
    "FATHER NAME",
    "IDENTIFICATION TYPE",
    "IDENTIFICATION#",
    "AGE",
    "GENDER",
    "EDUCATION DEGREE",
    "MARITAL STATUS",
    "PHONE",
    "ADDRESS",
    "ُSURVEY TYPE",
    "ُFAMILY NATURE",
    "HOUSE HOLD HEAD",
    "HOUSE HEAD JOB",
    "HOUSE HEAD SIZE",
    "FAMILY WORKING MEMBERS#",
    "BOYS MEMBERS#",
    "GIRLS MEMBERS#",
    "LIVELIHOOD RELATED WORK",
    "If IN THE HOME, WHAT ACTIVITY?",
    "IF OUTSIDE THE HOME, WHAT ACTIVITY?",
    "Most interested skills in learning",
    "DOSE ANYONE HAVE LAND IN HOUSE?",
    "WHAT IS THE LAND MAINLY USED FOR?",
    "DOSE PERSONALY AGRICULTURAL ACTIVITIES?",
    "NEED TO TRAINING?",
    "IS ALLOWED TO GOT THE TRARINING?",
    "TRAINER",
    "CLASS",
    "CLASS SESSION",
    "CLASS STATUS",
    "START DATE",
    "COMPLETE DATE",
    "IS PACKAGE RECEIVED",
    "BECAME SELF EMPLOYEED",
    "TRAINING BENEFIT",
    ...branchSpecificHeaders
  ];

  const Ekeys = [
    "name",
    "father_name",
    "identification_type",
    "identification_number",
    "age",
    "gender",
    "educational_degree",
    "marital_status",
    "phone",
    "address",
    "survey_type",
    "family_nature",
    "house_hold_head",
    "house_head_job",
    "house_hold_size",
    "family_working_members_number",
    "boys_number",
    "girls_number",
    "livelihood_related_work",
    "home_activity",
    "outside_activity",
    "learning_skill_interest",
    "does_anyone_have_land_in_house",
    "main_usages_of_land",
    "do_you_personally_do_agricultural_activities",
    "need_training",
    "is_allowed_to_go_training",
    "trainer.name",
    "classes.name",
    "session",
    "class_status",
    "class_start_date",
    "complete_date",
    "is_package_received",
    "became_self_employeed",
    "training_benefit",
    ...branchSpecificKeys
  ];
  return (
    <div className="mt-5 flex w-full flex-col gap-5">
      <Card>
        <div className="flex justify-between  p-4">
          <div className="flex items-center "></div>
          <div className="flex items-center space-x-1">
          <Menu className="bg-blue" isLazy>
              <MenuButton  as={Button} rightIcon={<ChevronDownIcon />} colorScheme='blue'>
                Download Reports
              </MenuButton>
              <MenuList>
                <MenuItem>
                  {DownloadData && (
                    <DownloadPDF headers={Dheaders} DownloadData={DownloadData} keys={Dkeys} title={title} pdfName={pdfName} totalRecord={totalRecord} />
                  )}
                </MenuItem>
                <MenuItem>
                  <DownloadExcel data={DownloadData} headers={Eheaders} keys={Ekeys} xlFile={xlFile} title={title} totalRecord={totalRecord} />
                </MenuItem>
              </MenuList>
            </Menu>
            <FilterButton onClick={handleFilter} />
            <CreateButton onClick={onOpen} />
            <AddVTBeneficiary
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={onSuccessCreate}
              pagination={pagination}
              setPagination={setPagination}
              checkData={data?.data}
            />
          </div>
        </div>
      </Card>
      <Card>
        <div className="flex justify-between bg-indigo-200 p-4">
          <div className="flex items-center">
            <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => onSearch(e)}
                onKeyDown={(e) => onSearch(e)}
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <EditButton onClick={handleEdit} />
            <DeleteButton onClick={handleDelete} />
            <ViewButton onClick={handleView} />
          </div>
        </div>
        {isLoading ? (
          <div className="flex h-40 items-center justify-center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </div>
        ) : (
          <ChakraProvider>
            <div style={{ overflowX: "auto", maxHeight: "700px" }}>
              <DataTable
                data={data?.data}
                headers={headers}
                keys={keys}
                selectedRowKeys={selectedRowKeys}
                onSelect={onSelectRow}
              />
            </div>
            <CustomPagination
              pageSize={pagination.pageSize}
              onPageSizeChange={onPageSizeChange}
              current={pagination.current}
              onPageChange={onPageChange}
              total={data.total}
            />
          </ChakraProvider>
        )}
      </Card>
      <DeleteConfirmation
        isOpen={alertOpen}
        onClose={onCloseAlert}
        confirmMessage={confirmMessage}
        onConfirm={handleDeleteConfirm}
      />

      <EditVTBeneficiary
        isOpen={EditModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSuccessEdit={onSuccessEdit}
        vTraining={selectedVTraining}
        checkData={data?.data}
      />
      <ViewVTBenficiary
        isOpen={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        vTraining={selectedVTraining}
      />
      <FilterModal
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        status={vt_status}
        gender
        filterOption={applyFilter}
        pagination={pagination} // Pass pagination as prop to FilterModal
        updatePagination={setPagination} // Pass the function to update pagination
      />
    </div>
  );
}

export default VTBenificiary;
