import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Divider,
  Box,
  Flex,
  Button,
  useToast 
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { FaFilePdf } from "react-icons/fa";

export default function ViewMonitoringModal({
  isOpen,
  onClose,
  mneData, // pass M&E data as props
}) {

  const toast = useToast();
  const hasAttachment = mneData?.attachment;
  const handleClick = (e) => {
    if (!hasAttachment) {
      e.preventDefault();
      toast({
        title: "No File Attachment",
        description: "There is no file attachment for this record.",
        position: 'top',
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  if (!mneData) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={false}> 
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          View M&E Record
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody style={{ overflowX: "auto", maxHeight: "650px" }}>
          <Flex flexDirection={{ base: "column", md: "row" }}>
            {/* First Column */}
            <Box flex="1" pr={{ md: 4 }}>
              <Box mb={3} mt={3}>
                <Text fontWeight="bold">M&E Officer:</Text>
                <Text>
                  {mneData?.mne_officer.name} {mneData?.mne_officer.lastname}
                </Text>
              </Box>
              <Divider />
              <Box mb={3} mt={3}>
                <Text fontWeight="bold">Reporting Month:</Text>
                <Text>{mneData?.reporting_month}</Text>
              </Box>
              <Divider />
            </Box>
            {/* Second Column */}

            <Box flex="1" pl={{ md: 4 }}>
              <Box mb={3} mt={3}>
                <Text fontWeight="bold">Branch:</Text>
                <Text>{mneData?.branch.branch_name}</Text>
              </Box>
              <Divider />
              <Box mb={3} mt={3}>
                <Text fontWeight="bold">Line Manager:</Text>
                <Text>
                  {mneData?.line_manager.name} {""}{" "}
                  {mneData?.line_manager.lastname}
                </Text>
              </Box>
              <Divider />
              <Box mb={3} mt={3}>
                <Text fontWeight="bold">Update Date:</Text>
                <DatePicker
                  selected={new Date(mneData?.update_date)}
                  dateFormat="yyyy-MM-dd"
                  className="input-field w-full"
                  disabled
                />
              </Box>
              <Divider />
            </Box>
          </Flex>

          
            <Box mb={6} mt={6} >
              <Text fontWeight="bold">Key Achievements/Tasks Completed:</Text>
              <Text >{mneData?.key_achievements}</Text>
            </Box>
            <Divider />
            <Box mb={6} mt={6}>
            <Text fontWeight="bold">
              Key finding and observation about each project visited:
            </Text>
            <Text>{mneData?.visited_project_key_findings}</Text>
            </Box>
            <Divider />

            <Box mb={6} mt={6}>
            <Text fontWeight="bold">
              Key Challenges, Strategic Concerns and Proposed Solutions/Need for
              Support:
            </Text>
            <Text>{mneData?.key_challenges_and_solutions}</Text>
            </Box>
            <Divider />

            
            <Box mb={6} mt={6}>
            <Text fontWeight="bold">
              M&E Visits Due in Next Month (Internal/ External):
            </Text>
            <Text>{mneData?.next_month_visits_due}</Text>
            </Box>
            <Divider />

            
            <Box mb={6} mt={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
            <Text fontWeight="bold">Attachment:</Text>

              <Button as="a" href={mneData?.attachment} onClick={handleClick} target='_blank'  leftIcon={<FaFilePdf />} variant="link" colorScheme="blue" size="lg">
                      View Attachment
              </Button>
            </Box>

        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
