import { useMutation} from "@tanstack/react-query"
import { postRequest } from "utils/http";


export const authenticateUser = async (username, password) => {
    return postRequest(`/login`,{
      username,
      password
    })
  };
export const useLoginUsers = ()=>
{
    return useMutation({
        mutationFn:(params)=>authenticateUser(params?.username,params?.password)})
}
