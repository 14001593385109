import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Heading,
  Text,
  Divider,
  Box,
  Flex,
  Center,
  useToast 
} from "@chakra-ui/react";
import { FaFilePdf } from 'react-icons/fa';

function ViewFemaleDoctors({ isOpen, onClose, fDoctor }) {
  const toast = useToast();
  const handleClick = (e) => {
    const hasAttachment = fDoctor?.form_attachment;
    if (!hasAttachment) {
      e.preventDefault();
      toast({
        title: "No File Attachment",
        description: "There is no file attachment for this record.",
        position: 'top',
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  if (!fDoctor) {
    return null;
  }


  const {
    name,
    father_name,
    age,
    address,
    diagnosis,
    prescription,
    form_attachment,
    doctor,
    project,
    visit_frequency,
  } = fDoctor;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg={"gray.100"} borderRadius="lg">
        <ModalHeader textAlign="center" borderBottomWidth="1px" pb={4}>
          Female Doctor Details
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Stack spacing={8}>
            <Section title="Patient Information">
              <InfoCard>
                <Field label="Name: " value={name} />
                <Field label="Father Name: " value={father_name} />
                <Field label="Age: " value={age} />
                <Field label="Address: " value={address} />
                <Field label="Project: " value={project} />
                <Field label="Visit Frequency: " value={visit_frequency} />
                <Field label="Diagnosis: " value={diagnosis} />
                <Field label="Prescription: " value={prescription} />
                <Center>
                  <Box>
                    <Button as="a" href={form_attachment} target='_blank' onClick={handleClick} download="female_doctor.pdf" leftIcon={<FaFilePdf />} variant="link" colorScheme="blue" size="sm" mt={4}>
                        View Attachment
                    </Button>
                  </Box>
                </Center>
              </InfoCard>
            </Section>
            <Section title="Doctor Information">
              <InfoCard>
                <Field label="Doctor Name: " value={doctor?.name} />
                <Field label="Doctor Email: " value={doctor?.email} />
                <Field label="Doctor Position: " value={doctor?.position} />
              </InfoCard>
            </Section>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose} size="md">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function Field({ label, value }) {
  return (
    <Flex justifyContent="flex-start" alignItems="center" mb={2}>
      <Text fontWeight="bold" minWidth="100px">{label}</Text>
      <Text paddingRight="10px">{value}</Text>
    </Flex>
  );
}

function Section({ title, children }) {
  return (
    <Box>
      <Heading size="md" mb={4}>{title}</Heading>
      <Divider />
      <Stack spacing={4} mt={4} paddingRight="10px">
        {children}
      </Stack>
    </Box>
  );
}

function InfoCard({ children }) {
  return (
    <Box boxShadow="md" p={6} borderRadius="md" bg="white">
      {children}
    </Box>
  );
}

export default ViewFemaleDoctors;
