import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Heading,
  Text,
  Divider,
  Card,
  useToast, 
  Center,
  Box
} from "@chakra-ui/react";
import { FaFilePdf } from 'react-icons/fa';

function ViewLegalAdvisorModal({ isOpen, onClose, LegalAdvisor }) {
  const toast = useToast();
  console.log("att",LegalAdvisor);
  const handleClick = (e) => {
    const hasAttachment = LegalAdvisor?.clientCases[0]?.attachment;
    if (!hasAttachment) {
      e.preventDefault();
      toast({
        title: "No File Attachment",
        description: "There is no file attachment for this record.",
        position: 'top',
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  if (!LegalAdvisor) {
    return null;
  }
  const { name, job, phone, father_name,last_name, client_cases } = LegalAdvisor;
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent  bg={"gray.100"} borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          View Legal Advisor
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody style={{ overflowX: 'auto', maxHeight: '650px' }}>
          <Stack spacing={6}>
            <Card>
              <Heading size="md" style={{padding:'10px'}}>Legal Advisor Client Info:</Heading>
              <Text style={{paddingLeft:'10px'}}><strong>Name:</strong> {name}</Text>
              <Text style={{paddingLeft:'10px'}}><strong>LastName:</strong> {last_name}</Text>
              <Text style={{paddingLeft:'10px'}}><strong>FatherName:</strong> {father_name}</Text>
              <Text style={{paddingLeft:'10px'}}><strong>Phone:</strong> {phone}</Text>
              <Text style={{paddingLeft:'10px'}}><strong>Job:</strong> {job}</Text>
              <Text style={{paddingLeft:'10px'}}><strong>Branch:</strong> {LegalAdvisor.branch.branch_name}</Text>


            </Card>
            <Divider />
            <Card>
              <Heading size="md" style={{padding:'10px'}} >Client Cases:</Heading>
                {client_cases.map((clientCase, index) => (
                  <React.Fragment key={index} >
                    <div style={{padding:'10px'}}>
                      <Text><strong>Advisor:</strong> {clientCase?.legal_advisor?.name + clientCase?.legal_advisor?.lastname }</Text>
                      <Text><strong>Registration Date:</strong> {clientCase?.registration_date}</Text>
                      <Text><strong>Consultancy Date:</strong> {clientCase?.consultancy_date}</Text>
                      <Text><strong>Status:</strong> {clientCase.status}</Text>
                      <Text><strong>Opposite Side:</strong> {clientCase?.opposite_side?.name + "" + clientCase.opposite_side?.last_name }</Text>
                      <Text><strong>Opposite Side Job:</strong> {clientCase?.opposite_side?.job}</Text>
                      <Text><strong>Opposite Side Phone:</strong> {clientCase?.opposite_side?.phone}</Text>
                      <Text><strong>Current Residence :</strong> {clientCase?.opposite_side?.current_residence}</Text>
                      <Text><strong>Main Residence:</strong> {clientCase?.opposite_side?.main_residence}</Text>
                      <Text><strong>Family Violence Roots:</strong> {clientCase?.family_violence_roots}</Text>
                      <Text><strong>Violence Diagnosis:</strong> {clientCase?.violence_diagnosis}</Text>
                      <Text><strong>First Meeting:</strong> {clientCase?.first_meeting}</Text>
                      <Text><strong>Second Meeting:</strong> {clientCase?.second_meeting}</Text>
                      <Text><strong>Third Meeting:</strong> {clientCase?.third_meeting}</Text>
                      <Text><strong>Fourth Meeting:</strong> {clientCase?.fourth_meeting}</Text>
                      <Text><strong>Provided Legal Advice:</strong> {clientCase?.provided_legal_advice}</Text>
                      <Text><strong>Advice Result:</strong> {clientCase?.advice_result}</Text>
                      <Center>
                        <Box>
                          <Button className="center" as="a" href={clientCase?.attachment} target='_blank' onClick={handleClick}  leftIcon={<FaFilePdf />} variant="link" colorScheme="blue" size="lg">
                            View Case Attachment
                          </Button>
                        </Box>
                      </Center>
                    </div>  
                  </React.Fragment>
                ))}
            </Card>

          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ViewLegalAdvisorModal;
