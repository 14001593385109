import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useToast,
  Divider,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  Box,
  StepTitle,
  StepDescription,
  StepIcon,
  StepNumber,
  StepSeparator,
  AbsoluteCenter,
  ModalFooter,
  Heading,
  Img
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { useUpdateLegalAdvisor } from "queries/legal_advisor.query";
import { useGetAdvisors } from "queries/legal_advisor.query";
import successSVG from "../../../assets/svg/success.svg";
import { useAuthStore } from 'store/use-auth.store';


function EditLegalAdvisor({ isOpen, onClose, onSuccessEdit, LegalAdvisor, checkData }) {
  const initialFormData = {
    name: "",
    father_name: "",
    grand_father_name: "",
    last_name: ""
  };
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useUpdateLegalAdvisor();
  const toast = useToast();
  const { data: advisor} = useGetAdvisors();
  const { user } = useAuthStore();
  const [activeStep, setActiveStep] = useState(0);
  const [closeDate, setcloseDate] = useState(null);
  const [roomRecords, setRoomRecords] = useState([initialFormData]);
  const [formData, setFormData] = useState(initialFormData);
  const [registrationDate, setRegistrationDate] = useState(null);
  const [consultancyDate, setConsultancyDate] = useState(null);
  const [selectedTools, setSelectedTools] = useState([]);
  const [selectedInterventions, setSelectedInterventions] = useState([]);
  const [selectedPsychologicalIssues, setSelectPsychologicalIssueType] = useState(
    []
  );
  const [fieldErrors, setFieldErrors] = useState({});

   // steps
   const steps = [
    { title: "Cleint Info" },
    { title: "Opposite Info" },
    { title: "Client Case" },
    { title: "Done" },
  ];

  const validateFamilyMembers = (value) => {
    const totalFamilyMembers = parseInt(getValues("total_family_members"));
    const maleFamilyMembers = parseInt(getValues("male_family_members"));
    const femaleFamilyMembers = parseInt(value);

    // Check if the sum of maleFamilyMembers and femaleFamilyMembers is equal to totalFamilyMembers
    if (maleFamilyMembers + femaleFamilyMembers !== totalFamilyMembers) {
      return "The sum of male and female family members should be equal to total family members";
    }

    // Check if femaleFamilyMembers is greater than totalFamilyMembers - maleFamilyMembers
    if (femaleFamilyMembers > totalFamilyMembers - maleFamilyMembers) {
      return "The number of female family members should be equal to or less than the remaining space after counting male family members";
    }

    return true;
  };

  const handleRegistrationDateChange = (date) => {
    setRegistrationDate(date);
  };
  const handleConsultancyDateChange = (date) => {
    setConsultancyDate(date);
  };
  const handleNext = async () => {
    let fieldsToValidate = [];
    switch (activeStep) {
      case 0:
        fieldsToValidate = 
        [
          "name","father_name",
          "last_name","grand_father_name",
          "age","birth_place","marital_status",
          "educational_degree","job","phone","total_family_members",
          "male_family_members","female_family_members",
          "branch_id","main_residence",
          "current_residence"      
        ];
        break;
      case 1:
        fieldsToValidate =  
        [
          "opp_name","opp_father_name",
          "opp_last_name","opp_grand_father_name",
          "opp_age","opp_educational_degree","opp_job",
          "opp_phone","opp_main_residence","opp_current_residence"    
        ];
        break;
      case 2:
        fieldsToValidate = 
         [
          "legal_advisor","registration_date",
          "family_violence_roots","violence_diagnosis",
          "first_meeting","second_meeting",
          "provided_legal_advice","advice_result","status"    
        ];
        break;
      default:
        break;
    }
    const isValid = await trigger(fieldsToValidate);
    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    let isValid = true;
    const formData = new FormData();
    formData.append("id", LegalAdvisor?.id); // Make sure LegalAdvisor?.id is not null
    formData.append("_method", "PUT");
   

    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "This field is required",
        }));
        isValid = false;
      }

        formData.append(key, data[key]);
    });
    const formattedRegisterDate = registrationDate
    ? `${registrationDate.getFullYear()}-${(registrationDate.getMonth() + 1).toString().padStart(2, '0')}-${registrationDate.getDate().toString().padStart(2, '0')}`
    : "";
    formData.append("registration_date", formattedRegisterDate);

  const formattedConsulateDate = consultancyDate
      ? `${consultancyDate.getFullYear()}-${(consultancyDate.getMonth() + 1).toString().padStart(2, '0')}-${consultancyDate.getDate().toString().padStart(2, '0')}`
      : "";
      formData.append("consultancy_date", formattedConsulateDate);
      if (data.case_attachment) {
        formData.append("case_attachment", data.case_attachment[0]); // Append only the first file if multiple files are selected
      }
    mutate(formData, {
      onSuccess: () => {
        setIsLoading(false);
        showToast({ status: "success", description: "Legal Advisor edited successfully" });
        onSuccessEdit();
        onClose();
        setActiveStep(0);
      },
      onError: (error) => {
        setIsLoading(false);
        showToast({ status: "error", description: "Failed to edit Legal Advisor" });
      }
    });
  };
  const showToast = ({ status, description }) => {
    toast({
      title: status === "success" ? "Success" : "Error",
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right", // Set the position to "top"
    });
  };
  React.useEffect(() => {
    if (LegalAdvisor && LegalAdvisor?.client_cases[0]?.registration_date) {
      const regisDateObj = new Date(LegalAdvisor?.client_cases[0]?.registration_date);
      setRegistrationDate(regisDateObj);
    } else {
      setRegistrationDate(null);
    }
  
    if (LegalAdvisor && LegalAdvisor?.client_cases[0]?.consultancy_date) {
      const consulDateObj = new Date(LegalAdvisor?.client_cases[0]?.consultancy_date);
      setConsultancyDate(consulDateObj);
    } else {
      setConsultancyDate(null);
    }
  }, [LegalAdvisor]);
  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <div>
     <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent borderRadius="md">
          <ModalHeader textAlign="center" borderBottomWidth="1px">
            Update Legal Advisor
            <ModalCloseButton    
                  onClick={() => {
                    onClose();
                    reset();
                    setActiveStep(0);
                    setcloseDate(null);
                    setSelectPsychologicalIssueType([]);
                    setSelectedTools([]);
                    setSelectedInterventions([]);
                  }}/>
            <Divider className="mb-6 mt-3 w-full" />
            <Stepper index={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody style={{ overflowX: 'auto', maxHeight: '500px' }}>
              {activeStep === 0 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <FormControl isInvalid={errors.name}>
                    <FormLabel htmlFor="name">
                      Name <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="name"
                      placeholder="Name"
                      defaultValue={LegalAdvisor?.name}
                      {...register("name", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.father_name}>
                    <FormLabel htmlFor="father_name">
                      Father's Name <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="father_name"
                      placeholder="Father's Name"
                      defaultValue={LegalAdvisor?.father_name}
                      {...register("father_name", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.father_name && errors.father_name.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.last_name}>
                    <FormLabel htmlFor="last_name">
                      Last Name 
                    </FormLabel>
                    <Input
                      id="last_name"
                      placeholder="Last Name"
                      defaultValue={LegalAdvisor?.last_name}
                      {...register("last_name")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.last_name && errors.last_name.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.grand_father_name}>
                    <FormLabel htmlFor="grand_father_name">
                      Grand Father's Name 
                    </FormLabel>
                    <Input
                      id="grand_father_name"
                      defaultValue={LegalAdvisor?.grand_father_name}
                      placeholder="Father's Name"
                      {...register("grand_father_name")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.grand_father_name && errors.grand_father_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.age}>
                    <FormLabel htmlFor="age">
                      Age <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="age"
                      placeholder="Age"
                      defaultValue={LegalAdvisor?.age}
                      {...register("age", {
                        required: "This is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid age",
                        },
                        maxLength: {
                          value: 3,
                          message: "Age should be at most 3 digits long",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.age && errors.age.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.birth_place}>
                    <FormLabel htmlFor="birth_place">
                      Birth Place <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="birth_place"
                      placeholder="Father's Name"
                      defaultValue={LegalAdvisor?.birth_place}
                      {...register("birth_place", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.birth_place && errors.birth_place.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.marital_status}>
                    <FormLabel htmlFor="marital_status">
                      Marital Status <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="marital_status"
                      defaultValue={LegalAdvisor?.marital_status}
                      placeholder="Select Marital Status"
                      {...register("marital_status", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="SINGLE">Single</option>
                      <option value="MARRIED">Married</option>
                      <option value="DIVORCED">Divorced</option>
                      <option value="SEPARATED">Separated</option>
                      <option value="WIDOWED">Widowed</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.marital_status && errors.marital_status.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.educational_degree}>
                    <FormLabel htmlFor="educational_degree">
                      Educational Degree <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="educational_degree"
                      defaultValue={LegalAdvisor?.educational_degree}
                      placeholder="Select Educational Degree"
                      {...register("educational_degree", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="ILLITERATE">Illiterate</option>
                      <option value="PRIMARY">Primary</option>
                      <option value="SECONDARY">Secondary</option>
                      <option value="HIGHER_SECONDARY">Higher Secondary</option>
                      <option value="UNIVERSITY">University</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.educational_degree &&
                        errors.educational_degree.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.job}>
                    <FormLabel htmlFor="job">
                      Job
                    </FormLabel>
                    <Input
                      id="job"
                      placeholder="Job"
                      defaultValue={LegalAdvisor?.job}
                      {...register("job")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.job && errors.job.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.phone}>
                    <FormLabel htmlFor="phone">
                    </FormLabel>
                    <Input
                      id="phone"
                      placeholder="Phone"
                      defaultValue={LegalAdvisor?.phone}
                      {...register("phone", {
                        pattern: {
                          value: /^[0-9+]+$/,
                          message: "Please enter only numbers and '+'",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.total_family_members}>
                    <FormLabel htmlFor="total_family_members">
                      Family Members# <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="total_family_members"
                      defaultValue={LegalAdvisor?.total_family_members}
                      placeholder="Total Family Members"
                      {...register("total_family_members", {
                        required: "This is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid Family Members",
                        },
                        maxLength: {
                          value: 3,
                          message: "Family Members should be at most 3 digits long",
                        },
                        
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.total_family_members && errors.total_family_members.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.male_family_members}>
                    <FormLabel htmlFor="male_family_members">
                      Male Members# 
                    </FormLabel>
                    <Input
                      id="male_family_members"
                      placeholder="Male Members"
                      defaultValue={LegalAdvisor?.male_family_members}
                      {...register("male_family_members", {
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid Male Members",
                        },
                        maxLength: {
                          value: 3,
                          message: "Family Members should be at most 3 digits long",
                        },
                        validate: {
                          lessThanOrEqualTotal: (value) =>
                            value ? parseInt(value) <= parseInt(getValues("total_family_members")) || "Male members should be equal to or less than total family members" : true,
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.male_family_members && errors.male_family_members.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.female_family_members}>
                    <FormLabel htmlFor="female_family_members">
                      Female Members# 
                    </FormLabel>
                    <Input
                      id="female_family_members"
                      placeholder="Female Members"
                      defaultValue={LegalAdvisor?.female_family_members}
                      {...register("female_family_members", {
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid Female Members",
                        },
                        maxLength: {
                          value: 3,
                          message: "Female Members should be at most 3 digits long",
                        },
                        validate: {
                          validateFamilyMembers: (value) =>
                            value ? validateFamilyMembers(value) : true,
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.female_family_members && errors.female_family_members.message}
                    </FormErrorMessage>
                  </FormControl>
                  {(user?.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name === "KABUL")) && (
                    <FormControl isInvalid={errors.branch_id}>
                        <FormLabel htmlFor="branch_id">Branch <span className="text-red-500">*</span></FormLabel>
                        <Select
                            id="branch_id"
                            defaultValue={LegalAdvisor?.branch_id}
                            placeholder="Select Branch"
                            {...register("branch_id", {
                                required: "This is required",
                            })}
                            className="input-field"
                        >
                            <option value="1">Kabul</option>
                            <option value="2">Bamyan</option>
                            <option value="3">Paktia</option>
                        </Select>
                        <FormErrorMessage>
                            {errors.branch_id && errors.branch_id.message}
                        </FormErrorMessage>
                    </FormControl>
                )}

                  <FormControl isInvalid={errors.main_residence}>
                    <FormLabel htmlFor="main_residence">
                      Main Residence
                    </FormLabel>
                    <Textarea
                      id="main_residence"
                      placeholder="Main Residence"
                      defaultValue={LegalAdvisor?.main_residence}
                      {...register("main_residence")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.main_residence && errors.main_residence.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.current_residence}>
                    <FormLabel htmlFor="current_residence">
                      Current Residence 
                    </FormLabel>
                    <Textarea
                      id="current_residence"
                      placeholder="Current Residence"
                      defaultValue={LegalAdvisor?.current_residence}

                      {...register("current_residence")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.current_residence && errors.current_residence.message}
                    </FormErrorMessage>
                  </FormControl>

                </div>
              )}
              {activeStep === 1 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <FormControl isInvalid={errors.opp_name}>
                    <FormLabel htmlFor="opp_name">
                      Name <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="opp_name"
                      placeholder="Name"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.name}
                      {...register("opp_name", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_name && errors.opp_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.opp_father_name}>
                    <FormLabel htmlFor="opp_father_name">
                      Father's Name <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="opp_father_name"
                      placeholder="Father's Name"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.father_name}
                      {...register("opp_father_name", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_father_name && errors.opp_father_name.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.opp_last_name}>
                    <FormLabel htmlFor="opp_last_name">
                      Last Name
                    </FormLabel>
                    <Input
                      id="opp_last_name"
                      placeholder="Last Name"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.last_name}
                      {...register("opp_last_name")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_last_name && errors.opp_last_name.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.opp_grand_father_name}>
                    <FormLabel htmlFor="opp_grand_father_name">
                      Grand Father's Name
                    </FormLabel>
                    <Input
                      id="opp_grand_father_name"
                      placeholder="Father's Name"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.grand_father_name}
                      {...register("opp_grand_father_name")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_grand_father_name && errors.opp_grand_father_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.opp_age}>
                    <FormLabel htmlFor="age">
                      Age <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="opp_age"
                      placeholder="Age"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.age}
                      {...register("opp_age", {
                        required: "This is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid age",
                        },
                        maxLength: {
                          value: 3,
                          message: "Age should be at most 3 digits long",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_age && errors.opp_age.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.opp_educational_degree}>
                    <FormLabel htmlFor="opp_educational_degree">
                      Educational Degree <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="opp_educational_degree"
                      placeholder="Select Educational Degree"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.educational_degree}
                      {...register("opp_educational_degree", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="ILLITERATE">Illiterate</option>
                      <option value="PRIMARY">Primary</option>
                      <option value="SECONDARY">Secondary</option>
                      <option value="HIGHER_SECONDARY">Higher Secondary</option>
                      <option value="UNIVERSITY">University</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.opp_educational_degree &&
                        errors.opp_educational_degree.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.opp_job}>
                    <FormLabel htmlFor="opp_job">
                      Job 
                    </FormLabel>
                    <Input
                      id="opp_job"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.job}
                      placeholder="Job"
                      {...register("opp_job")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_job && errors.opp_job.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.opp_phone}>
                    <FormLabel htmlFor="phone">
                      Phone
                    </FormLabel>
                    <Input
                      id="opp_phone"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.phone}
                      placeholder="Phone"
                      {...register("opp_phone", {
                        pattern: {
                          value: /^[0-9+]+$/,
                          message: "Please enter only numbers and '+'",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_phone && errors.opp_phone.message}
                    </FormErrorMessage>
                  </FormControl>
                 
                  <FormControl isInvalid={errors.opp_main_residence}>
                    <FormLabel htmlFor="opp_main_residence">
                      Main Residence
                    </FormLabel>
                    <Textarea
                      id="opp_main_residence"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.main_residence}
                      placeholder="Main Residence"
                      {...register("opp_main_residence")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_main_residence && errors.opp_main_residence.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.opp_current_residence}>
                    <FormLabel htmlFor="opp_current_residence">
                      Current Residence
                    </FormLabel>
                    <Textarea
                      id="opp_current_residence"
                      defaultValue={LegalAdvisor?.client_cases[0]?.opposite_side?.current_residence}
                      placeholder="Current Residence"
                      {...register("opp_current_residence")}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.opp_current_residence && errors.opp_current_residence.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
              )}
              {activeStep === 2 && (
                <>
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "100px" }}
                >
                {((user?.role === "ADMIN" ) || (user?.role === 'MANAGER')) && (
                  <FormControl isInvalid={errors.legal_advisor}>
                    <FormLabel htmlFor="leagl_advisor">Legal Advisor<span className="text-red-500">*</span></FormLabel>
                    <Select
                      id="legal_advisor"
                      defaultValue={LegalAdvisor?.client_cases[0]?.legal_advisor?.id}
                      placeholder="Select Legal Advisor "
                      {...register("legal_advisor", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      {advisor?.map((legalItem) => (
                        <option key={legalItem.id} value={legalItem.id}>
                          {legalItem.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors.legal_advisor && errors.legal_advisor.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
                  <FormControl isInvalid={errors.registration_date}>
                    <div className="flex flex-row space-x-4">
                      <div className="flex flex-col">
                        <FormLabel htmlFor="registration_date">Registration Date <span className="text-red-500">*</span></FormLabel>
                        <DatePicker
                          id="registration_date"
                          selected={registrationDate}
                          onChange={handleRegistrationDateChange}
                          placeholderText="Select Registration Date"
                          dateFormat="yyyy-MM-dd"
                          className="input-field"
                          customInput={<Input />}
                        />
                        <FormErrorMessage>
                          {errors.registration_date && errors.registration_date.message}
                        </FormErrorMessage>
                      </div>
                      <div className="flex flex-col">
                        <FormLabel htmlFor="cosultancy_date">Consultancy Date</FormLabel>
                        <DatePicker
                          id="cosultancy_date"
                          selected={consultancyDate}
                          onChange={handleConsultancyDateChange}
                          placeholderText="Select Consultancy Date"
                          dateFormat="yyyy-MM-dd"
                          className="input-field"
                          customInput={<Input />}
                        />
                      </div>
                    </div>
                  </FormControl>
                </div>
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6"
                >
                <FormControl isInvalid={errors.family_violence_roots}>
                    <FormLabel htmlFor="family_violence_roots">
                      Family Violence Roots <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="family_violence_roots"
                      placeholder="Family Violence Roots"
                      defaultValue={LegalAdvisor?.client_cases[0]?.family_violence_roots}

                      {...register("family_violence_roots", {
                        required: "This is required",
                      })}
                      className="input-field"
                      style={{ height: "150px" }}
                    />
                    <FormErrorMessage>
                      {errors.family_violence_roots && errors.family_violence_roots.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.violence_diagnosis}>
                    <FormLabel htmlFor="violence_diagnosis">
                      Violence Diagnosis <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="violence_diagnosis"
                      placeholder="Violence Diagnosiss"
                      defaultValue={LegalAdvisor?.client_cases[0]?.violence_diagnosis}
                      {...register("violence_diagnosis", {
                        required: "This is required",
                      })}
                      className="input-field"
                      style={{ height: "150px" }}
                    />
                    <FormErrorMessage>
                      {errors.violence_diagnosis && errors.violence_diagnosis.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.first_meeting}>
                    <FormLabel htmlFor="first_meeting">
                      First Meeting <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="first_meeting"
                      placeholder="First Meetings"
                      defaultValue={LegalAdvisor?.client_cases[0]?.first_meeting}
                      {...register("first_meeting", {
                        required: "This is required",
                      })}
                      className="input-field"
                      style={{ height: "150px" }}
                    />
                    <FormErrorMessage>
                      {errors.first_meeting && errors.first_meeting.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.second_meeting}>
                    <FormLabel htmlFor="second_meeting">
                      Second Meeting <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="second_meeting"
                      placeholder="Second Meetings"
                      defaultValue={LegalAdvisor?.client_cases[0]?.second_meeting}

                      {...register("second_meeting", {
                        required: "This is required",
                      })}
                      className="input-field"
                      style={{ height: "150px" }}
                    />
                    <FormErrorMessage>
                      {errors.second_meeting && errors.second_meeting.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.third_meeting}>
                    <FormLabel htmlFor="third_meeting">
                      Thirth Meeting 
                    </FormLabel>
                    <Textarea
                      id="third_meeting"
                      placeholder="Thirth Meetings"
                      defaultValue={LegalAdvisor?.client_cases[0]?.third_meeting}
                      {...register("third_meeting")}
                      className="input-field"
                      style={{ height: "150px" }}
                    />
                    {/* <FormErrorMessage>
                      {errors.third_meeting && errors.third_meeting.message}
                    </FormErrorMessage> */}
                  </FormControl>
                  <FormControl isInvalid={errors.fourth_meeting}>
                    <FormLabel htmlFor="fourth_meeting">
                      Forth Meeting 
                    </FormLabel>
                    <Textarea
                      id="fourth_meeting"
                      placeholder="Forth Meetings"
                      defaultValue={LegalAdvisor?.client_cases[0]?.fourth_meeting}

                      {...register("fourth_meeting")}
                      className="input-field"
                      style={{ height: "150px" }}
                    />
                    {/* <FormErrorMessage>
                      {errors.fourth_meeting && errors.fourth_meeting.message}
                    </FormErrorMessage> */}
                  </FormControl>
                  <FormControl isInvalid={errors.provided_legal_advice}>
                    <FormLabel htmlFor="provided_legal_advice">
                      Provided Legal Advice <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="provided_legal_advice"
                      placeholder="Provided Legal Advices"
                      defaultValue={LegalAdvisor?.client_cases[0]?.provided_legal_advice}
                      {...register("provided_legal_advice", {
                        required: "This is required",
                      })}
                      className="input-field"
                      style={{ height: "150px" }}
                    />
                    <FormErrorMessage>
                      {errors.provided_legal_advice && errors.provided_legal_advice.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.advice_result}>
                    <FormLabel htmlFor="advice_result">
                      Advice Result <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="advice_result"
                      placeholder="Advice Results"
                      defaultValue={LegalAdvisor?.client_cases[0]?.advice_result}

                      {...register("advice_result", {
                        required: "This is required",
                      })}
                      className="input-field"
                      style={{ height: "150px" }}
                    />
                    <FormErrorMessage>
                      {errors.advice_result && errors.advice_result.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2"
                  style={{ height: "100px" }}
                >
                   <FormControl isInvalid={errors.status}>
                    <FormLabel htmlFor="status">Status <span className="text-red-500">*</span></FormLabel>
                    <Select
                      id="status"
                      placeholder="Select Status"
                      defaultValue={LegalAdvisor?.client_cases[0]?.status}

                      {...register("status", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="PENDING">Pending</option>
                      <option value="IN PROGRESS">In Progress</option>
                      <option value="CLOSED">Closed</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.status && errors.status.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.case_attachment}>
                    <FormLabel htmlFor="case_attachment">
                      Form Attachment
                    </FormLabel>
                    <Input
                      id="case_attachment"
                      // defaultValue={LegalAdvisor?.client_cases[0]?.attachment}
                      type="file"
                      accept="application/pdf"
                      {...register("case_attachment", {})}
                      className="input-field"
                    />
                    {LegalAdvisor?.client_cases[0]?.attachment && (
                      <div className="attachment-info">
                        <span className="file-name">
                          {LegalAdvisor?.client_cases[0]?.attachment.split("/").pop()}{" "}
                          {/* Extracting only the file name */}
                        </span>
                      </div>
                    )}
                    <FormErrorMessage>
                      {errors.case_attachment && errors.case_attachment.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
                </>
 
              )}
               {activeStep === 3 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <AbsoluteCenter>
                    <Img
                      alt="success"
                      width={300}
                      height={300}
                      src={successSVG}
                    />
                    <Heading size={"xs"}>
                      Pss Beneficiary has been successfully recorded.
                    </Heading>
                  </AbsoluteCenter>
                </div>
              )}


            </ModalBody>
            <ModalFooter>
              {/* Conditional rendering of buttons based on active step */}
              {/* Back button */}
              {activeStep !== 0 && activeStep !== 3 && <Button onClick={handleBack}>Back</Button>}
              {/* Save button on the 4th step */}
              {activeStep === 2 && (
                <Button
                  colorScheme="teal"
                  isLoading={isSubmitting || isLoading} 
                  type="submit"
                  ml={3}
                >
                  Update
                </Button>
              )}
              {/* Next button */}
              {activeStep < steps.length - 1 && activeStep !== 2 && (
                <Button colorScheme="blue" ml={3} onClick={handleNext} disabled={Object.values(fieldErrors).some((error) => !!error)}>
                  Next
                </Button>
              )}
              {/* Close button on the last step */}
              {activeStep === steps.length - 1 && (
                <Button
                  onClick={() => {
                    onClose();
                    reset();
                    setActiveStep(0);
                    setcloseDate(null);
                    setSelectPsychologicalIssueType([]);
                    setSelectedTools([]);
                    setSelectedInterventions([]);
                  }}
                  ml={3}
                >
                  Close
                </Button>
              )}
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default EditLegalAdvisor