import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Heading,
  Grid,
  GridItem,
  Box,
  Img,
} from "@chakra-ui/react";
import { Card, CardBody, CardFooter } from "@chakra-ui/react";
export default function ViewEmployeeModal({ isOpen, onClose, employee }) {


  if (!employee) {
    return null;
  }
  if (!employee) {
    return null;
  }

  return (
    <>
      <div>
        <Modal isOpen={isOpen} onClose={onClose} size="4xl" closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent bg={"gray.100"} borderRadius="md">
            <ModalHeader textAlign="center" borderBottomWidth="1px">
              Employee Details
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box w="100%">
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(7, 1fr)" }}
                  gap={4}
                >
                  <GridItem rowSpan={2} colSpan={{ base: "auto", md: 2 }}>
                    <Card maxW="sm" h={{ base: "auto", md: 525 }}>
                      <center>
                        <CardBody mt={{ base: "10", md: "20" }}>
                          <Img
                            src={`https://api.daweo.site/${employee?.photo}`}
                            alt="Employee Photo"
                            boxSize={{ base: "100px", md: "150px" }}
                            borderRadius="full"
                          />
                        </CardBody>
                        <CardFooter>
                          <Stack mt="6" spacing="3">
                            <Heading size="md">
                              {employee.name} {employee?.lastname}
                            </Heading>
                            <Heading size="md"> {employee?.position}</Heading>
                            <Heading size="md">
                              {employee?.branch?.branch_name}
                            </Heading>
                          </Stack>
                        </CardFooter>
                      </center>
                    </Card>
                  </GridItem>

                  <GridItem colSpan={{ base: "auto", md: 5 }}>
                    <Card maxW="full">
                      <CardBody>
                        <Grid templateColumns="repeat(5, 1fr)" gap={2}>
                          <GridItem
                            id="attributes"
                            w="100%"
                            colSpan={{ base: 2, md: 2 }}
                          >
                            <GridItem w="100%" h="10">
                              <Heading size="md">Father Name:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">Age:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">Tazkira Number:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">Gender:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">Marital Status:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">Educational Degree:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="20">
                              <Heading size="md">Address:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">Phone:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">Email:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">User name:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">password:</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                          </GridItem>

                          <GridItem w="100%" colSpan={{ base: 3, md: 3 }}>
                            <GridItem w="100%" h="10">
                              <Heading size="md">
                                {employee?.father_name}
                              </Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">{employee?.age}</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">
                                {employee?.tazkira_number}
                              </Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">{employee?.gender}</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">
                                {employee?.marital_status}
                              </Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">
                                {employee?.educational_degree}
                              </Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="20">
                              <Heading size="md">{employee?.address}</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">{employee?.phone}</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">{employee?.email}</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">{employee?.user?.username}</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                            <GridItem w="100%" h="10" mt={"1"}>
                              <Heading size="md">{employee?.user?.dec_password}</Heading>
                            </GridItem>
                            <hr className="mb-2" />
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Card>
                  </GridItem>
                </Grid>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
}
