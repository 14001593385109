/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { useAuthStore } from "store/use-auth.store";
import routes from "routes.js";
import { PssEmployeeRoutes } from "routes";
import { LegalAdvisorEmployeeRoutes } from "routes";
import { VocationalTrainerEmployeeRoutes } from "routes";
import { FemaleDoctorEmployeeRoutes } from "routes";
import { MonitoringEvaluationEmployeeRoutes } from "routes";
import { managerRoutes } from "routes";
import { kabulManagerRoutes } from "routes";

const Sidebar = ({ open, onClose }) => {
  const { user  } = useAuthStore();
  const getRoutes = () => {
    if(user?.role === 'ADMIN'){
      return routes?.filter((router) =>router.layout === '/admin')
    }

    if( user?.role === 'MANAGER' && user?.branch?.branch_name !== 'KABUL'){
      return managerRoutes
    }
   
    if( user?.role === 'MANAGER' && user?.branch?.branch_name === 'KABUL'){
      return kabulManagerRoutes
    }
    if( user?.role === 'MANAGER' && user?.branch?.branch_name !== 'KABUL'){
      return managerRoutes
    }
    if(user?.role === 'EMPLOYEE' && user?.employee?.position === 'PSYCHO-SOCIAL COUNSELLOR'){
      return PssEmployeeRoutes
    }

    if(user?.role === 'EMPLOYEE' && user?.employee?.position === 'LEGAL ADVISOR'){
      return LegalAdvisorEmployeeRoutes
    }
    if(user?.role === 'EMPLOYEE' && user?.employee?.position === 'VOCATIONAL TRAINER'){
      return VocationalTrainerEmployeeRoutes
    }
    if(user?.role === 'EMPLOYEE' && user?.employee?.position === 'FEMALE DOCTOR'){
      return FemaleDoctorEmployeeRoutes
    }
    if(user?.role === 'EMPLOYEE' && user?.employee?.position === 'MONITORING & EVALUATION'){
      return MonitoringEvaluationEmployeeRoutes
    }
  }
  
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          DAWEO <span class="font-medium">MIS</span>
        </div>
      </div>
      <div className={`mx-[10px] mt-[30px] flex flex-col items-center`}>
        {user.role === 'ADMIN' && (
          <div className="justify-center">{user?.role}</div>
        )}
        {!(user.role === 'ADMIN') && (
          <div className="justify-center">{user?.employee?.name} {user?.employee?.lastname}</div>
        )}
        <div className="justify-center">{user?.branch?.branch_name}</div>
      </div>

      <div class="mt-[30px] mb-3 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={getRoutes()} />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
