// queries/femaleDoctor.query.js
import { getRequest, deleteRequest ,postRequest } from "utils/http";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

// read data
export const useGetFemaleDocotrsData = (current, pageSize , searchValue , status, filterDateRange, gender, project, branch) => {
    return useQuery({
      queryKey: ["fetch-femaleDoctors", current, pageSize , searchValue , status, filterDateRange, gender, project, branch],
      queryFn: () => getFemaleDocotrsData(current, pageSize , searchValue ,status, filterDateRange, gender, project, branch),
    });
};

export const getFemaleDocotrsData = async (current, pageSize , searchValue ,status, filterDateRange, gender, project, branch) => {
    return await getRequest("app/femaleDoctors", {
      current: current,
      pageSize: pageSize,
      searchValue: searchValue,
      status:status,
      filterDateRange,
      gender, 
      project,
      branch

    });
};

// delete
export const deletefemaleDoctors = async (ids) => {
  return deleteRequest(`app/deletefemaleDoctors`, {
    params: {
      ids: ids.join(","),
    },
  });
};

export const useMultiDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids) => deletefemaleDoctors(ids),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-femaleDoctors"]);
    },
  });
};

export const useSingleDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => singleDelete(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-femaleDoctors"]);
    },
  });
};

const singleDelete = async (id) => {
  return deleteRequest(`app/femaleDoctors/${id}`, {
    params: {
      id,
    },
  });
};
// create
export const femaleDoctorsCreate = (params) => {
  return postRequest(`app/femaleDoctors`, params);
};
export const useFemaleDoctorsCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => femaleDoctorsCreate(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-femaleDoctors"]);
    },
  });
};
// Edit
export const updateFemaleDcotors = async (params) => {
  return postRequest(`app/femaleDoctors/${params.get("id")}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useUpdateFemaleDcotors = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => updateFemaleDcotors(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-femaleDoctors"]);
    },
  });
};

export const useGetFemaleDoctor = () => {
    return useQuery({
        queryKey: "doctors", // Adding a query key can help React Query distinguish between different queries
        queryFn: () => GetDoctors(),
        onError: (error) => {
            console.error('Error fetching doctors:', error);
        }
    });
};


export const GetDoctors = async () => {
  return await getRequest("app/getDoctors");
};