import React, { useState } from "react";
import { Button, ChakraProvider, Menu, MenuButton, MenuItem, MenuList, Spinner, useDisclosure , useToast} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import DataTable from "components/DataTable/DataTable";
import Card from "../../components/card/index";
import CreateButton from "components/Buttons/CreateButton";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import ViewButton from "components/Buttons/ViewButton";
import FilterButton from "components/Buttons/FilterButton";
import CustomPagination from "components/CustomPagenation/CustomPagenation";
import DownloadPDF from "views/DownloadPDF";
import DeleteConfirmation from "components/DeleteConfirmation/DeleteConfirmation";
import ToastNotification from "components/toast/toast.notification";
import FilterModal from "components/filter/filter";
import { useAuthStore } from "store/use-auth.store";
import { useGetMnUData } from "queries/monitoring_evaluations.query";
import { useMnESingleDelete } from "queries/monitoring_evaluations.query";
import { useMnEMultiDelete } from "queries/monitoring_evaluations.query";
import MonitoringUpdateFormModal from "./components/update.monitoring";
import ViewMonitoringModal from "./components/view.monitoring";
import CreateMnEFormModal from "./components/create.monitoring";
import { ChevronDownIcon } from "@chakra-ui/icons";
import DownloadExcel from "views/DownloadExcel";

export default function MonitoringEvaluations() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    searchValue: null,
    status: [],
    filterDateRange: null,
    gender: [],
    branch: [],
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const { mutate: singleDeleteMutation } = useMnESingleDelete();
  const { mutate: multiDeleteMutation } = useMnEMultiDelete();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedMnE, setSelectedMnE] = useState(null);
  const [toastNotification, setToastNotification] = useState(null);
  const [mneData , setMnEData] = useState(null);
  const toast = useToast();

  const { data, isLoading, isError } = useGetMnUData(
    pagination.current,
    pagination.pageSize,
    pagination.searchValue,
    pagination.status,
    pagination.filterDateRange,
    pagination.gender,
    pagination.branch
  );
  const { user } = useAuthStore();


  const showToastNotification = (title, description, status) => {
    setToastNotification({ title, description, status });
  };

  React.useEffect(
    (data) => {
      if (toastNotification) {
        const timer = setTimeout(() => {
          setToastNotification(null);
        }, 3000);

        return () => clearTimeout(timer);
      }
    },
    [toastNotification]
  );

  const title = "Monitoring and Evaluation";
  const DownloadData = data?.data?.data;
  const pdfName = "m&e.pdf";
  const xlFile = "m&e";
  const totalRecord = data?.data?.data?.length;

  const branchSpecificHeaders =
    user.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name ==="KABUL") ? ["BRANCH"] : [];
  const branchSpecificKeys =
    user.role === "ADMIN" || (user?.role ==="MANAGER" && user?.branch?.branch_name ==="KABUL") ? ["branch.branch_name"] : [];

  const headers = [
    "ID",
    "M&E OFFICER NAME",
    "LINE MANAGER",
    "REPORT MONTH",
    "UPDATE DATE",
    "KEY ACHIEVEMENTS",
    "VISITED PROJECT KEY FINDINGS",
    "KEY CHALLENGES AND SOLUTIONS",
    "NEXT MONTH VISITS DUE",
    ...branchSpecificHeaders,
    "CREATED BY",
  ];

  const keys = [
    "id",
    "mne_officer.name",
    "line_manager.name",
    "reporting_month",
    "update_date",
    "key_achievements",
    "visited_project_key_findings",
    "key_challenges_and_solutions",
    "next_month_visits_due",
    ...branchSpecificKeys,
    "created_by.username",
  ];
  const Dheaders = [
    "M&E OFFICER NAME",
    "LINE MANAGER",
    "REPORT MONTH",
    "UPDATE DATE",
    "KEY ACHIEVEMENTS",
    "VISITED PROJECT KEY FINDINGS",
    "KEY CHALLENGES AND SOLUTIONS",
    "NEXT MONTH VISITS DUE",
    ...branchSpecificHeaders,
  ];
  const Dkeys = [
    "mne_officer.name",
    "line_manager.name",
    "reporting_month",
    "update_date",
    "key_achievements",
    "visited_project_key_findings",
    "key_challenges_and_solutions",
    "next_month_visits_due",
    ...branchSpecificKeys,
  ];
  const Eheaders = [
    "M&E OFFICER NAME",
    "LINE MANAGER",
    "REPORT MONTH",
    "UPDATE DATE",
    "KEY ACHIEVEMENTS",
    "VISITED PROJECT KEY FINDINGS",
    "KEY CHALLENGES AND SOLUTIONS",
    "NEXT MONTH VISITS DUE",
    ...branchSpecificHeaders,
  ];
  const Ekeys = [
    "mne_officer.name",
    "line_manager.name",
    "reporting_month",
    "update_date",
    "key_achievements",
    "visited_project_key_findings",
    "key_challenges_and_solutions",
    "next_month_visits_due",
    ...branchSpecificKeys,
  ];


  const onCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleDelete = () => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.length === 1) {
        setConfirmMessage("Are you sure you want to delete this record?");
      } else {
        setConfirmMessage(
          `Are you sure you want to delete ${selectedRowKeys.length} records?`
        );
      }
      setAlertOpen(true);
    } else {
      showToastNotification(
        "NO Recode Selected",
        "Please select at least one record to delete.",
        "warning"
      );
    }
  };

  const handleDeleteConfirm = () => {
    if (selectedRowKeys.length > 0) {
      if (selectedRowKeys.length === 1) {
        singleDeleteMutation(selectedRowKeys[0], {
          onSuccess: () => {
            showToastNotification(
              "Delete",
              "One record was successfully deleted.",
              "success"
            );
            setSelectedRowKeys([]);
            onCloseAlert();
          },
          onError: () => {
            showToastNotification(
              "Delete",
              "Failed to delete the record.",
              "error"
            );
          },
        });
      } else {
        multiDeleteMutation(selectedRowKeys, {
          onSuccess: () => {
            showToastNotification(
              "Delete",
              `${selectedRowKeys.length} Records were successfully deleted.`,
              "success"
            );
            setSelectedRowKeys([]);
            onCloseAlert();
          },
          onError: () => {
            showToastNotification(
              "Delete",
              `${selectedRowKeys.length} Failed to delete the records.`,
              "error"
            );
          },
        });
      }
    } else {
      showToastNotification(
        "Delete",
        "Please select at least one record to delete.",
        "warning"
      );
    }
  };

  const handleView = () => {
    if (selectedRowKeys.length === 1) {
      const selectedMnEData = data?.data?.data.find(
        (MnE) => MnE.id === selectedRowKeys[0]
      );
      if (selectedMnEData) {
        setSelectedMnE(selectedMnEData);
        setViewModalOpen(true);
      } else {
        console.log("Selected M&E record not found");
      }
    } else if(selectedRowKeys.length > 1){
      toast({
        title: "Selected More Than One",
        description: "Please select Exactly one record to View.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }else {
      toast({
        title: "NO Recode Selected",
        description: "Please Selete At Least One Record To View.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = () => {
    if (selectedRowKeys.length === 1) {
      const selectedMnEData = data?.data?.data.find(
        (mneData) => mneData.id === selectedRowKeys[0]
      );
      if (selectedMnEData) {
        setMnEData(selectedMnEData);
        setUpdateModalOpen(true);
      } else {
        console.log("Selected M&E not found");
      }
    }  else if(selectedRowKeys.length > 1){
      toast({
        title: "Selected More Than One",
        description: "Please select Exactly one record to Edit.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }else {
      toast({
        title: "NO Recode Selected",
        description: "Please Selete At Least One Record To Edit.",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleFilter = () => {
    setFilterModalOpen(true);
  };
  const onSearch = (e) => {
    if (e.target.value.trim() === "") {
      setSearchInput(e.target.value);
      setPagination({ ...pagination, searchValue: e.target.value });
    }
    if (e.key === "Enter") {
      setSearchInput(e.target.value);
      setPagination({ ...pagination, searchValue: e.target.value });
    }
  };

  const onPageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
  };

  const onPageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value });
  };

  const onSelectRow = (selectedRows) => {
    setSelectedRowKeys(selectedRows);
  };
  const onSuccess = () => {
  };

  if (isError) {
    return <div>Error loading mne</div>;
  }

  return (
    <div className="mt-5 flex w-full flex-col gap-5">
      {/* Render the toast notification if available */}
      {toastNotification && (
        <ToastNotification
          title={toastNotification.title}
          description={toastNotification.description}
          status={toastNotification.status}
        />
      )}

      <Card>
        <div className="flex justify-between  p-4">
          <div className="flex items-center "></div>
          <div className="flex items-center space-x-1">
          <Menu className="bg-blue" isLazy>
              <MenuButton  as={Button} rightIcon={<ChevronDownIcon />} colorScheme='blue'>
                Download Reports
              </MenuButton>
              <MenuList>
                <MenuItem>
                  {DownloadData && (
                    <DownloadPDF headers={Dheaders} DownloadData={DownloadData} keys={Dkeys} title={title} pdfName={pdfName} totalRecord={totalRecord} />
                  )}
                </MenuItem>
                <MenuItem>
                  <DownloadExcel data={DownloadData} headers={Eheaders} keys={Ekeys} xlFile={xlFile} title={title} totalRecord={totalRecord} />
                </MenuItem>
              </MenuList>
            </Menu>
            <FilterButton onClick={handleFilter} />
            <CreateButton onClick={onOpen} />
            <CreateMnEFormModal
              isOpen={isOpen}
              onClose={onClose}
              onSuccess={onSuccess}
            />
          </div>
        </div>
      </Card>
      <Card>
        <div className="flex justify-between bg-indigo-200 p-4">
          <div className="flex items-center">
            <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="search"
                name="search-form"
                id="search-form"
                onChange={(e) => onSearch(e)}
                onKeyDown={(e) => onSearch(e)}
                defaultValue={searchInput}
                placeholder="Search beneficiary"
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <EditButton onClick={handleEdit} />
            <DeleteButton onClick={handleDelete} />
            <ViewButton onClick={handleView} />
          </div>
        </div>

        {isLoading ? (
          <div className="flex h-40 items-center justify-center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </div>
        ) : (
          <ChakraProvider>
            <div style={{ overflowX: "auto", maxHeight: "700px" }}>
              <DataTable
                data={data?.data?.data}
                headers={headers}
                keys={keys}
                selectedRowKeys={selectedRowKeys}
                onSelect={onSelectRow}
              />
            </div>
            <CustomPagination
              pageSize={pagination.pageSize}
              onPageSizeChange={onPageSizeChange}
              current={pagination.current}
              onPageChange={onPageChange}
              total={data?.data?.total}
            />
          </ChakraProvider>
        )}
      </Card>
      <DeleteConfirmation
        isOpen={alertOpen}
        onClose={onCloseAlert}
        confirmMessage={confirmMessage}
        onConfirm={handleDeleteConfirm}
      />
      <ViewMonitoringModal
      isOpen={viewModalOpen}
      onClose={() => setViewModalOpen(false)}
      mneData={selectedMnE}
      />
      <FilterModal
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        
        pagination={pagination}
        updatePagination={setPagination}
      />
      <MonitoringUpdateFormModal
       isOpen={updateModalOpen}
       onClose={() => setUpdateModalOpen(false)}
       mneData={mneData}
      />
    </div>
  );
}
