import React from "react";

import MainDashboard from "views/admin/default";
import { MdHome, MdPerson, MdAccessibility, MdLock } from "react-icons/md";
import SignIn from "views/auth/SignIn";
import Employee from "views/employee";
import PssBeneficiary from "views/pss/pss_beneficiary";
import VTBenificiary from "views/vtbenificiary";
import LegalAdvisor from "views/legal advisor";
import MonitoringEvaluations from "views/Monitoring Evaluations";
import FemaleDoctors from "views/Female Doctors";

export const adminRoutes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Employee",
    layout: "/admin",
    path: "employee",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Employee />,
  },
  {
    name: "PSS ‌Beneficiaries",
    layout: "/admin",
    path: "pss/beneficiary",
    icon: <MdAccessibility className="h-6 w-6" />,
    component: <PssBeneficiary />,
  },
  {
    name: "PSS ‌Beneficiaries",
    layout: "/pss",
    path: "pss/beneficiary",
    icon: <MdAccessibility className="h-6 w-6" />,
    component: <PssBeneficiary />, 
  },
  {
    name: "Vocational Training ",
    layout: "/admin",
    path: "vtbenificiary",
    icon: <MdPerson className="h-6 w-6" />,
    component: <VTBenificiary />,
  },
  {
    name: "Legal Advisor ",
    layout: "/admin",
    path: "LegalAdvisor",
    icon: <MdPerson className="h-6 w-6" />,
    component: <LegalAdvisor />,
  },
  {
    name: "Monitoring Evaluations ",
    layout: "/admin",
    path: "MonitoringEvaluations",
    icon: <MdPerson className="h-6 w-6" />,
    component: <MonitoringEvaluations />,
  },
  {
    name: "Female Doctors ",
    layout: "/admin",
    path: "FemaleDoctors",
    icon: <MdPerson className="h-6 w-6" />,
    component: <FemaleDoctors />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];


export const kabulManagerRoutes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Employee",
    layout: "/admin",
    path: "employee",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Employee />,
  },
  {
    name: "PSS ‌Beneficiaries",
    layout: "/admin",
    path: "pss/beneficiary",
    icon: <MdAccessibility className="h-6 w-6" />,
    component: <PssBeneficiary />,
  },
  {
    name: "PSS ‌Beneficiaries",
    layout: "/pss",
    path: "pss/beneficiary",
    icon: <MdAccessibility className="h-6 w-6" />,
    component: <PssBeneficiary />, 
  },
  {
    name: "Vocational Training ",
    layout: "/admin",
    path: "vtbenificiary",
    icon: <MdPerson className="h-6 w-6" />,
    component: <VTBenificiary />,
  },
  {
    name: "Legal Advisor ",
    layout: "/admin",
    path: "LegalAdvisor",
    icon: <MdPerson className="h-6 w-6" />,
    component: <LegalAdvisor />,
  },
  {
    name: "Monitoring Evaluations ",
    layout: "/admin",
    path: "MonitoringEvaluations",
    icon: <MdPerson className="h-6 w-6" />,
    component: <MonitoringEvaluations />,
  },
  {
    name: "Female Doctors ",
    layout: "/admin",
    path: "FemaleDoctors",
    icon: <MdPerson className="h-6 w-6" />,
    component: <FemaleDoctors />,
  },
];

export const managerRoutes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },

  {
    name: "PSS ‌Beneficiaries",
    layout: "/admin",
    path: "pss/beneficiary",
    icon: <MdAccessibility className="h-6 w-6" />,
    component: <PssBeneficiary />,
  },
  {
    name: "PSS ‌Beneficiaries",
    layout: "/pss",
    path: "pss/beneficiary",
    icon: <MdAccessibility className="h-6 w-6" />,
    component: <PssBeneficiary />, 
  },
  {
    name: "Vocational Training ",
    layout: "/admin",
    path: "vtbenificiary",
    icon: <MdPerson className="h-6 w-6" />,
    component: <VTBenificiary />,
  },
  {
    name: "Legal Advisor ",
    layout: "/admin",
    path: "LegalAdvisor",
    icon: <MdPerson className="h-6 w-6" />,
    component: <LegalAdvisor />,
  },

  {
    name: "Female Doctors ",
    layout: "/admin",
    path: "FemaleDoctors",
    icon: <MdPerson className="h-6 w-6" />,
    component: <FemaleDoctors />,
  },

];

export const PssEmployeeRoutes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "PSS ‌Beneficiaries",
    layout: "/admin",
    path: "pss/beneficiary",
    icon: <MdAccessibility className="h-6 w-6" />,
    component: <PssBeneficiary />,
  },
];
export const VocationalTrainerEmployeeRoutes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Vocational Training ",
    layout: "/admin",
    path: "vtbenificiary",
    icon: <MdPerson className="h-6 w-6" />,
    component: <VTBenificiary />,
  },
];
export const LegalAdvisorEmployeeRoutes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Legal Advisor ",
    layout: "/admin",
    path: "LegalAdvisor",
    icon: <MdPerson className="h-6 w-6" />,
    component: <LegalAdvisor />,
  },
];
export const FemaleDoctorEmployeeRoutes = [
  {
    name: "Female Doctors ",
    layout: "/admin",
    path: "FemaleDoctors",
    icon: <MdPerson className="h-6 w-6" />,
    component: <FemaleDoctors />,
  },
];
export const MonitoringEvaluationEmployeeRoutes = [
  {
    name: "Monitoring Evaluations ",
    layout: "/admin",
    path: "MonitoringEvaluations",
    icon: <MdPerson className="h-6 w-6" />,
    component: <MonitoringEvaluations />,
  },
];

export default adminRoutes;
