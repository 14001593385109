import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  Box,
  Heading,
  Button,
  Icon,
  Grid,
  GridItem,
  Center,
  ButtonGroup,
  Modal,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import { MdFilterListAlt } from "react-icons/md";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ReactSelect from "react-select";
import { useAuthStore } from "store/use-auth.store";

export default function FilterModal({
  isOpen,
  onClose,
  status, 
  gender,
  project,
  pagination,
  updatePagination,
}) {
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const { user } = useAuthStore();

  const handleDateRangeChange = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleClear = () => {
    setSelectedBranch([]);
    setSelectedGender([]);
    setSelectedProject([]);
    setSelectedStatus([]);
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };

  const handleApplyFilter = () => {
    // Adjust startDate and endDate by adding one day
    const adjustedStartDate = dateRange[0].startDate
      ? new Date(dateRange[0].startDate)
      : null;
    const adjustedEndDate = dateRange[0].endDate
      ? new Date(dateRange[0].endDate)
      : null;
    if (adjustedStartDate) {
      adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);
    }
    if (adjustedEndDate) {
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
    }

    const selectedStatusValues = selectedStatus.map((option) => option.value);
    updatePagination({
      ...pagination,
      status: selectedStatusValues,
      filterDateRange: [
        {
          startDate: adjustedStartDate,
          endDate: adjustedEndDate,
          key: "selection",
        },
      ],
      gender: selectedGender.map((option) => option.value),
      project: selectedProject.map((option) => option.value),
      branch: selectedBranch.map((option) => option.value),
    });

    onClose();
  };

  const statusOptions = status ? status.map((item) => ({
    value: item.value,
    label: item.title,
  })) : [];

  return (
    <Center>
      <Modal
        size="4xl"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalContent>
          <Card bgColor={"gray.100"} className="drop-shadow-lg" maxW="full">
            <Grid templateColumns="repeat(7, 1fr)" gap={4}>
              <GridItem colSpan={7} className="bg-cyan-500">
                <CardHeader>
                  <Flex spacing="4">
                    <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
                      <Icon as={MdFilterListAlt} fontSize="2xl" />
                      <Box>
                        <Heading size="md">Filter</Heading>
                        <ModalCloseButton className=" p-5 " />
                      </Box>
                    </Flex>
                  </Flex>
                </CardHeader>
              </GridItem>
            </Grid>
            <CardBody>
              <Grid templateColumns="repeat(7, 1fr)" gap={2}>
                <GridItem className="pl-3 drop-shadow-lg" colSpan={3}>
                {project &&(
                    <>
                  <Heading className="py-5 pr-5 pb-1 pl-3 italic " size={"md"}>
                    {" "}
                    Filter by project
                  </Heading>
                  <ReactSelect
                    id="project"
                    placeholder="Select project(s)"
                    value={selectedProject}
                    onChange={(selectedOptions) =>
                      setSelectedProject(selectedOptions || [])
                    }
                    className="input-field px-3 py-2"
                    isMulti
                    options={[
                      { value: "UN WOMEN", label: "UN WOMEN" },
                      { value: "UNDP", label: "UNDP" },
                      { value: "IDLO", label: "IDLO" },
                    ]}
                  />
                  </>
                )}
                  {status && (
                    <>
                      <Heading className="py-5 pr-5 pb-1 pl-3 italic " size={"md"}>
                        {" "}
                        Filter by status
                      </Heading>
                      <ReactSelect
                        id="status"
                        placeholder="Select status(es)"
                        value={selectedStatus}
                        onChange={(selectedOptions) =>
                          setSelectedStatus(selectedOptions || [])
                        }
                        className="input-field px-3 py-2"
                        isMulti
                        options={statusOptions}
                      />
                    </>
                  )}
                  {!status && (
                    <>
                      <Heading className="py-5 pr-5 pb-1 pl-3 italic " size={"md"}>
                        {" "}
                        Filter by status
                      </Heading>
                      <ReactSelect
                        id="status"
                        placeholder="Select status(es)"
                        value={selectedStatus}
                        onChange={(selectedOptions) =>
                          setSelectedStatus(selectedOptions || [])
                        }
                        className="input-field px-3 py-2"
                        isMulti
                        options={[]}
                        isDisabled
                      />
                    </>
                  )}
                  {gender &&(
                    <>
                  <Heading className="py-5 pr-5 pb-1 pl-3 italic " size={"md"}>
                    {" "}
                    Filter by gender
                  </Heading>
                  <ReactSelect
                    id="gender"
                    placeholder="Select gender(s)"
                    value={selectedGender}
                    onChange={(selectedOptions) =>
                      setSelectedGender(selectedOptions || [])
                    }
                    className="input-field px-3 py-2"
                    isMulti
                    options={[
                      { value: "MALE", label: "MALE" },
                      { value: "FEMALE", label: "FEMALE" },
                      { value: "OTHER", label: "OTHER" },
                    ]}
                  />
                  </>
                )}
                  {!gender &&(
                    <>
                  <Heading className="py-5 pr-5 pb-1 pl-3 italic " size={"md"}>
                    {" "}
                    Filter by gender
                  </Heading>
                  <ReactSelect
                    id="gender"
                    isDisabled
                    placeholder="Select gender(s)"
                    value={selectedGender}
                    onChange={(selectedOptions) =>
                      setSelectedGender(selectedOptions || [])
                    }
                    className="input-field px-3 py-2"
                    isMulti
                    options={[
                    ]}
                  />
                  </>
                )}
                        {/* Code for branch filter */}
                  { ( user.role === "ADMIN" || (user?.role === 'MANAGER' && user?.branch?.branch_name === 'KABUL') )&&  (
                    <>
                      <Heading
                        className="py-5 pr-5 pb-1 pl-3 italic"
                        size={"md"}
                      >
                        Filter by branch
                      </Heading>
                      <ReactSelect
                        id="branch"
                        placeholder="Select branch(es)"
                        value={selectedBranch}
                        onChange={(selectedOptions) =>
                          setSelectedBranch(selectedOptions || [])
                        }
                        className="input-field px-3 py-2"
                        isMulti
                        options={[
                          { value: "1", label: "KABUL" },
                          { value: "2", label: "BAMYAN" },
                          { value: "3", label: "PAKTIA" },
                        ]}
                      />
                    </>
                  )}
                  {!( user.role === "ADMIN" || (user?.role === 'MANAGER' && user?.branch?.branch_name === 'KABUL') ) && (
                    <>
                      <Heading
                        className="py-5 pr-5 pb-1 pl-3 italic"
                        size={"md"}
                      >
                        Filter by branch
                      </Heading>
                      {/* Code for branch filter */}
                      <ReactSelect
                        id="branch"
                        isDisabled
                        placeholder="Select branch(es)"
                        value={selectedBranch}
                        onChange={(selectedOptions) =>
                          setSelectedBranch(selectedOptions || [])
                        }
                        className="input-field px-3 py-2 pb"
                        isMulti
                        options={[
                        ]}
                      />
                    </>
                  )}
                </GridItem>
                <GridItem colSpan={4}>
                  <Center>
                    <Card className="drop-shadow-lg">
                      <Heading className="p-5 italic " size={"md"}>
                        {" "}
                        Filter by date range
                      </Heading>
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={handleDateRangeChange}
                      />
                    </Card>
                  </Center>
                </GridItem>
              </Grid>
            </CardBody>
            <CardFooter className="mt-10">
              <Grid width="100%">
                <GridItem>
                  <Flex justify="flex-end" width="100%">
                    <ButtonGroup gap="2">
                      <Button
                        className="justify-end"
                        colorScheme="red"
                        variant="outline"
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                      <Button
                        className="justify-end"
                        colorScheme="blue"
                        variant="outline"
                        onClick={handleApplyFilter}
                      >
                        Apply
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </GridItem>
              </Grid>
            </CardFooter>{" "}
          </Card>
        </ModalContent>
      </Modal>
    </Center>
  );
}
