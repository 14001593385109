import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Img,
  Tooltip,
  Box,
} from "@chakra-ui/react";

const DataTable = ({ data, headers, keys, selectedRowKeys, onSelect }) => {
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleCheckboxChange = (id) => {
    const newSelectedRows = [...selectedRowKeys];
    if (id === "all") {
      if (selectedRowKeys.length === data.length) {
        // If all rows are currently selected, deselect all
        onSelect([]);
      } else {
        // Otherwise, select all rows
        const allRowKeys = data.map((row) => row[keys[0]]);
        onSelect(allRowKeys);
      }
    } else {
      // If it's not 'all', toggle the selection of the specific row
      if (selectedRowKeys.includes(id)) {
        newSelectedRows.splice(newSelectedRows.indexOf(id), 1);
      } else {
        newSelectedRows.push(id);
      }
      onSelect(newSelectedRows); // Notify parent component about selection change
    }
  };

  const handleRowClick = (id) => {
    handleCheckboxChange(id); // Simulate checkbox change on row click
  };

  return (
    <Table variant="simple">
      <Thead
        style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}
      >
        <Tr>
          <Th>
            <Checkbox
              isChecked={selectedRowKeys.length === data.length}
              onChange={() => handleCheckboxChange("all")}
            />
          </Th>
          {headers.map((header, index) => (
            <Th key={index} style={{ whiteSpace: "nowrap" }}>
              {header}
            </Th>
          ))}
        </Tr>
      </Thead>
      {data.length === 0 ? (
        <Tbody>
          <Tr>
            <Td colSpan={headers.length + 1}>
              <Box textAlign="center">No data available</Box>
            </Td>
          </Tr>
        </Tbody>
      ) : (
        <Tbody>
          {data.map((row, index) => (
            <Tr
              key={row[keys[0]]}
              className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} ${selectedRowKeys.includes(row[keys[0]]) || hoveredRow === row[keys[0]] ? 'bg-blue-200' : ''
                }`}
              onClick={() => handleRowClick(row[keys[0]])}
              onMouseEnter={() => setHoveredRow(row[keys[0]])}
              onMouseLeave={() => setHoveredRow(null)}
              style={{ cursor: 'pointer' }}
            >
              <Td>
                <Checkbox
                  isChecked={selectedRowKeys.includes(row[keys[0]])}
                  onChange={() => handleCheckboxChange(row[keys[0]])}
                />
              </Td>
              {keys.map((key, index) => (
                <Td key={index} style={{ whiteSpace: 'nowrap' }}>
                  {key === 'photo' ? (
                    <Img src={`https://api.daweo.site/${row[key]}`} alt="Employee Photo" className="h-12 w-12 rounded-full" />
                  ) : key.includes('.') ? (
                    key.split('.').reduce((obj, prop) => obj && obj[prop], row)
                  ) : (
                    key === 'became_self_employeed' || key === 'is_package_received' ? (
                      <>
                        {console.log('Key:', key, 'Value:', row[key])}
                        {(row[key] === 1 || row[key] === "1") ? '✔️' : '❌'}
                      </>
                    ) : (
                      (key === 'address' || key === 'training_benefit' || key === 'prescription' || key === 'diagnosis' ||
                        key === "visited_project_key_findings" || key === "next_month_visits_due" || key === 'key_achievements' || key === 'key_challenges_and_solutions') && row[key] && row[key].length > 30 ? (
                        <Tooltip label={row[key]}>
                          {`${row[key].substring(0, 30)} .....`}
                        </Tooltip>
                      ) : (
                        <span>
                          {row[key]}
                        </span>
                      )
                    )
                  )}
                </Td>
              ))}

            </Tr>
          ))}
        </Tbody>
      )}
    </Table>
  );
};

export default DataTable;
