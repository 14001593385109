import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useVTrainersCreate,
  useGetClasses,
} from "queries/vt_benenficiary.query";
import { useGetTrainers } from "queries/vt_benenficiary.query";
import { useAuthStore } from "store/use-auth.store";

import { Switch } from "@chakra-ui/switch";
import CreatableSelect from "react-select/creatable";

function AddVTBeneficiary({ isOpen, onClose, onSuccess, checkData }) {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [becameSelfEmployed, setBecameSelfEmployed] = useState(false);
  const [isPackageReceived, setIsPackageReceived] = useState(false);
  const [selectedHomeA, setSelectedHomeA] = useState([]);
  const [selectedOutA, setSelectedOutA] = useState([]);
  const [selectedMain, setSelectedMain] = useState([]);
  const [selectedLearning, setSelectedLearing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchId, setBranchId] = useState({
    branch_id: null,
  });

  const { mutate } = useVTrainersCreate();
  const { data: classes } = useGetClasses();
  const { data: trainers , isLoading: isLoadingTrainers} = useGetTrainers(branchId.branch_id);
  const { user } = useAuthStore();

  const toast = useToast();

  const handleBranchChange = (event) => {
    const selectedBranchId = event.target.value;
    setBranchId({ branch_id: selectedBranchId });
  };

  const handleDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const mainUsagesOfLandValues = selectedMain.map((option) => option.value);
      const homeActivityValues = selectedHomeA.map((option) => option.value);
      const outsideActivityValues = selectedOutA.map((option) => option.value);
      const learningSkillInterestValues = selectedLearning.map(
        (option) => option.value
      );
      data.home_activity = homeActivityValues;
      data.outside_activity = outsideActivityValues;
      data.main_usages_of_land = mainUsagesOfLandValues;
      data.learning_skill_interest = learningSkillInterestValues;
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      const formattedStartDate = startDate
        ? `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${startDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : "";
      formData.append("class_start_date", formattedStartDate);

      const formattedEndDate = endDate
        ? `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`
        : "";
      formData.append("complete_date", formattedEndDate);

      formData.append("became_self_employed", becameSelfEmployed ? 1 : 0);
      formData.append("is_package_received", isPackageReceived ? 1 : 0);
      if (data.attachment) {
        formData.append("attachment", data.attachment[0]); // Append only the first file if multiple files are selected
      }
      mutate(formData, {
        onSuccess: () => {
          setIsLoading(false);
          showToast({
            status: "success",
            description: "Vocational Training added successfully",
          });
          onSuccess();
          onClose();
          reset();
          setStartDate(null);
          setEndDate(null);
          setSelectedLearing([]);
          setSelectedMain([]);
          setSelectedOutA([]);
          setSelectedHomeA([]);
        },
        onError: (error) => {
          setIsLoading(false);
          showToast({
            status: "error",
            description: "Failed to add Vocational Training",
          });
        },
      });
    } catch (error) {
      setIsLoading(false);
      showToast({
        status: "error",
        description: "Failed to add Vocational Training",
      });
    }
  };
  const showToast = ({ status, description }) => {
    toast({
      title: status === "success" ? "Success" : "Error",
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right", // Set the position to "top"
    });
  };

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalHeader textAlign="center" borderBottomWidth="1px">
          Creating New Vocational Training Beneficiary
        </ModalHeader>
        <ModalCloseButton
          onClick={() => {
            onClose();
            reset();
            setStartDate(null);
            setEndDate(null);
            setSelectedLearing([]);
            setSelectedMain([]);
            setSelectedOutA([]);
            setSelectedHomeA([]);
          }}
        />
        <ModalBody style={{ overflowX: "auto", maxHeight: "650px" }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto max-w-screen-xl rounded bg-white p-4 shadow-md"
          >
            <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2">
              <FormControl isInvalid={errors.name}>
                <FormLabel htmlFor="name">
                  Name <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="name"
                  placeholder="Name"
                  {...register("name", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.father_name}>
                <FormLabel htmlFor="father_name">
                  Father's Name <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="father_name"
                  placeholder="Father's Name"
                  {...register("father_name", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.father_name && errors.father_name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.age}>
                <FormLabel htmlFor="age">
                  Age <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="age"
                  placeholder="Age"
                  {...register("age", {
                    required: "This is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid age",
                    },
                    maxLength: {
                      value: 3,
                      message: "Age should be at most 3 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.age && errors.age.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.gender}>
                <FormLabel htmlFor="gender">
                  Gender <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="gender"
                  placeholder="Select Gender"
                  {...register("gender", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </Select>
                <FormErrorMessage>
                  {errors.gender && errors.gender.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.educational_degree}>
                <FormLabel htmlFor="educational_degree">
                  Educational Degree <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="educational_degree"
                  placeholder="Select Educational Degree"
                  {...register("educational_degree", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="ILLITERATE">Illiterate</option>
                  <option value="PRIMARY">Primary</option>
                  <option value="SECONDARY">Secondary</option>
                  <option value="HIGHER">Higher</option>
                </Select>
                <FormErrorMessage>
                  {errors.educational_degree &&
                    errors.educational_degree.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.marital_status}>
                <FormLabel htmlFor="marital_status">
                  Marital Status <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="marital_status"
                  placeholder="Select Marital Status"
                  {...register("marital_status", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="SINGLE">Single</option>
                  <option value="MARRIED">Married</option>
                  <option value="DIVORCED">Divorced</option>
                  <option value="SEPARATED">Separated</option>
                  <option value="VIDOWED">Vidowed</option>
                </Select>
                <FormErrorMessage>
                  {errors.marital_status && errors.marital_status.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.address}>
                <FormLabel htmlFor="address">
                  Address <span className="text-red-500">*</span>
                </FormLabel>
                <Textarea
                  id="address"
                  placeholder="Address"
                  {...register("address", {
                    required: "This is required",
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.address && errors.address.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.phone}>
                <FormLabel htmlFor="phone">
                  Phone 
                </FormLabel>
                <Input
                  id="phone"
                  placeholder="Phone"
                  {...register("phone", {
                  
                    pattern: {
                      value: /^[0-9+]+$/,
                      message: "Please enter only numbers and '+'",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.identification_type}>
                <FormLabel htmlFor="identification_type">
                  Identification Type 
                </FormLabel>
                <Select
                  id="identification_type"
                  placeholder="Select Identification Type"
                  {...register("identification_type", {
                  })}
                  className="input-field"
                >
                  <option value="PAPER TAZKIRA">Paper Tazkira</option>
                  <option value="ELECTORONIC TAZKIRA">
                    Electronic Tazkira
                  </option>
                  <option value="PASSPORT">Passport</option>
                  <option value="OTHER">Other</option>
                </Select>
                <FormErrorMessage>
                  {errors.identification_type &&
                    errors.identification_type.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.identification_number}>
                <FormLabel htmlFor="identification_number">
                  Identification Number 
                </FormLabel>
                <Input
                  id="identification_number"
                  placeholder="Identification Number"
                  {...register("identification_number", {
                    pattern: {
                      value: /^[0-9-]+$/,
                      message: "Please enter a valid Identification Number",
                    },
                    maxLength: {
                      value: 20,
                      message:
                        "Identification Number should be at most 8 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.identification_number &&
                    errors.identification_number.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.survey_type}>
                <FormLabel htmlFor="survey_type">
                  Survey Type <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="survey_type"
                  placeholder="Select Survey Type"
                  {...register("survey_type", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="INDIVIDUAL">Individual</option>
                  <option value="GROUP">Group</option>
                </Select>
                <FormErrorMessage>
                  {errors.survey_type && errors.survey_type.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.family_nature}>
                <FormLabel htmlFor="family_nature">
                  Family Nature <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="family_nature"
                  placeholder="Select Family Nature"
                  {...register("family_nature", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="JOINT">Joint</option>
                  <option value="ALONE">Alone</option>
                  <option value="RELATIVE HOUSE">Relative House</option>
                  <option value="OTHER">Other</option>
                </Select>
                <FormErrorMessage>
                  {errors.family_nature && errors.family_nature.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.house_hold_head}>
                <FormLabel htmlFor="house_hold_head">
                  House Hold Head <span className="text-red-500">*</span>
                </FormLabel>
                <Select
                  id="house_hold_head"
                  placeholder="Select House Hold Head"
                  {...register("house_hold_head", {
                    required: "This is required",
                  })}
                  className="input-field"
                >
                  <option value="HUSBAND">Husband</option>
                  <option value="FATHER">Father</option>
                  <option value="SELF">Self</option>
                  <option value="OTHER">Other</option>
                </Select>
                <FormErrorMessage>
                  {errors.house_hold_head && errors.house_hold_head.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.house_hold_size}>
                <FormLabel htmlFor="Total Family Members">
                  Total Family Members <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  id="house_hold_size"
                  placeholder="Total Family Members"
                  {...register("house_hold_size", {
                    required: "This is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid Total Family Members",
                    },
                    maxLength: {
                      value: 3,
                      message:
                        "Total Family Members should be at most 3 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.house_hold_size && errors.house_hold_size.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.boys_number}>
                <FormLabel htmlFor="Boys Number">
                  Boys Number 
                </FormLabel>
                <Input
                  id="boys_number"
                  placeholder="Boys Number"
                  {...register("boys_number", {
                    
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid Boys Number",
                    },
                    maxLength: {
                      value: 3,
                      message: "Boys Number should be at most 3 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.boys_number && errors.boys_number.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.girls_number}>
                <FormLabel htmlFor="Girls Number">
                  Girls Number 
                </FormLabel>
                <Input
                  id="girls_number"
                  placeholder="Girls Number"
                  {...register("girls_number", {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid Girls Number",
                    },
                    maxLength: {
                      value: 3,
                      message: "Girls Number should be at most 3 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.girls_number && errors.girls_number.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.family_working_members_number}>
                <FormLabel htmlFor="Family Working Members Number">
                  Family Working Members Number{" "}
                </FormLabel>
                <Input
                  id="family_working_members_number"
                  placeholder="Family Working Members Number"
                  {...register("family_working_members_number", {
                    pattern: {
                      value: /^[0-9]+$/,
                      message:
                        "Please enter a valid Family Working Members Number",
                    },
                    maxLength: {
                      value: 3,
                      message:
                        "Family Working Members Number should be at most 3 digits long",
                    },
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.family_working_members_number &&
                    errors.family_working_members_number.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.house_head_job}>
                <FormLabel htmlFor="House Head Job">
                  House Head Job 
                </FormLabel>
                <Input
                  id="house_head_job"
                  placeholder="House Head Job"
                  {...register("house_head_job", {
                  })}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.house_head_job && errors.house_head_job.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.livelihood_related_work}>
                <FormLabel htmlFor="livelihood_related_work">
                  Livelihood Related Work{" "}
                </FormLabel>
                <Select
                  id="livelihood_related_work"
                  placeholder="Select One"
                  {...register("livelihood_related_work", {
                  })}
                  className="input-field"
                >
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Select>
                <FormErrorMessage>
                  {errors.livelihood_related_work &&
                    errors.livelihood_related_work.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.home_activity}>
                <FormLabel htmlFor="home_activity">
                  If in the home, what activity?
                </FormLabel>
                <CreatableSelect
                  id="home_activity"
                  placeholder="Select or Type Other Home Activity"
                  value={selectedHomeA}
                  onChange={(selectedOptions) => {
                    setSelectedHomeA(selectedOptions);
                    setValue("home_activity", selectedOptions);
                  }}
                  isMulti
                  className={`${
                    errors.home_activity
                      ? "border-3 rounded-md border-red-500"
                      : ""
                  } border`}
                  options={[
                    { value: "Embroidery", label: "Embroidery" },
                    { value: "Knitting", label: "Knitting" },
                    { value: "Don't be asked", label: "Don't be asked" },
                  ]}
                />
                <FormErrorMessage>
                  {errors.home_activity && errors.home_activity.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.outside_activity}>
                <FormLabel htmlFor="outside_activity">
                  If outside the home, what activity?
                </FormLabel>
                <CreatableSelect
                  id="outside_activity"
                  placeholder="Select or Type Other Out Activity"
                  value={selectedOutA}
                  onChange={(selectedOptions) => {
                    setSelectedOutA(selectedOptions);
                    setValue("outside_activity", selectedOptions);
                  }}
                  isMulti
                  className={`${
                    errors.outside_activity
                      ? "border-3 rounded-md border-red-500"
                      : ""
                  } border`}
                  options={[
                    { value: "Domestic Help", label: "Domestic Help" },
                    { value: "Teacher", label: "Teacher" },
                    { value: "Don't be asked", label: "Don't be asked" },
                  ]}
                />
                <FormErrorMessage>
                  {errors.outside_activity && errors.outside_activity.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.learning_skill_interest}>
                <FormLabel htmlFor="learning_skill_interest">
                  Most interested skills in learning
                </FormLabel>
                <CreatableSelect
                  id="learning_skill_interest"
                  placeholder="Select One or Type Other"
                  value={selectedLearning}
                  onChange={(selectedOptions) => {
                    setSelectedLearing(selectedOptions);
                    setValue("learning_skill_interest", selectedOptions);
                  }}
                  isMulti
                  className={`${
                    errors.learning_skill_interest
                      ? "border-3 rounded-md border-red-500"
                      : ""
                  } border`}
                  options={[
                    { value: "Embroidery", label: "Embroidery" },
                    { value: "Knitting", label: "Knitting" },
                    { value: "Food Processing", label: "Food Processing" },
                    { value: "Green Houses", label: "Green Houses" },
                  ]}
                />
                <FormErrorMessage>
                  {errors.learning_skill_interest &&
                    errors.learning_skill_interest.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.does_anyone_have_land_in_house}>
                <FormLabel htmlFor="does_anyone_have_land_in_house">
                  Does anyone have land in house?{" "}
                </FormLabel>
                <Select
                  id="does_anyone_have_land_in_house"
                  placeholder="Select One"
                  {...register("does_anyone_have_land_in_house")}
                  className="input-field"
                >
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Select>
                <FormErrorMessage>
                  {errors.does_anyone_have_land_in_house &&
                    errors.does_anyone_have_land_in_house.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.main_usages_of_land}>
                <FormLabel htmlFor="main_usages_of_land">
                  What is the land mainly used for?
                </FormLabel>
                <CreatableSelect
                  id="main_usages_of_land"
                  placeholder="Select One or Type Other"
                  value={selectedMain}
                  onChange={(selectedOptions) => {
                    setSelectedMain(selectedOptions);
                    setValue("main_usages_of_land", selectedOptions);
                  }}
                  isMulti
                  className={`${
                    errors.main_usages_of_land
                      ? "border-3 rounded-md border-red-500"
                      : ""
                  } border`}
                  options={[
                    { value: "Embroidery", label: "Embroidery" },
                    { value: "Knitting", label: "Knitting" },
                    { value: "Food Processing", label: "Food Processing" },
                    { value: "Green Houses", label: "Green Houses" },
                  ]}
                />
                <FormErrorMessage>
                  {errors.main_usages_of_land &&
                    errors.main_usages_of_land.message}
                </FormErrorMessage>
              </FormControl>


              <FormControl
                isInvalid={errors.do_you_personally_do_agricultural_activities}
              >
                <FormLabel htmlFor="do_you_personally_do_agricultural_activities">
                  Does personally agricultural activities?{" "}
                </FormLabel>
                <Select
                  id="do_you_personally_do_agricultural_activities"
                  placeholder="Select One"
                  {...register("do_you_personally_do_agricultural_activities", {
                  
                  })}
                  className="input-field"
                >
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Select>
                <FormErrorMessage>
                  {errors.do_you_personally_do_agricultural_activities &&
                    errors.do_you_personally_do_agricultural_activities.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.need_training}>
                <FormLabel htmlFor="need_training">
                  Need to training?{" "}
                </FormLabel>
                <Select
                  id="need_training"
                  placeholder="Select One"
                  {...register("need_training", {
                  })}
                  className="input-field"
                >
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Select>
                <FormErrorMessage>
                  {errors.need_training && errors.need_training.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.is_allowed_to_go_training}>
                <FormLabel htmlFor="is_allowed_to_go_training">
                  Is allowed to go to the training?{" "}
                </FormLabel>
                <Select
                  id="is_allowed_to_go_training"
                  placeholder="Select One"
                  {...register("is_allowed_to_go_training", {
                  })}
                  className="input-field"
                >
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Select>
                <FormErrorMessage>
                  {errors.is_allowed_to_go_training &&
                    errors.is_allowed_to_go_training.message}
                </FormErrorMessage>
              </FormControl>
              {(user?.role === "ADMIN" ||
                (user?.role === "MANAGER" &&
                  user?.branch?.branch_name === "KABUL")) && (
                <FormControl isInvalid={errors.branch_id}>
                  <FormLabel htmlFor="branch_id">
                    Branch <span className="text-red-500">*</span>
                  </FormLabel>
                  <Select
                    id="branch_id"
                    placeholder="Select Branch"
                    {...register("branch_id", {
                      required: "This is required",
                    })}
                    className="input-field"
                    onChange={handleBranchChange}
                  >
                    <option value="1">Kabul</option>
                    <option value="2">Bamyan</option>
                    <option value="3">Paktia</option>
                  </Select>
                  <FormErrorMessage>
                    {errors.branch_id && errors.branch_id.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={errors.class}>
                <FormLabel htmlFor="class_id">Class</FormLabel>
                <Select
                  id="class_id"
                  placeholder="Select Class "
                  {...register("class_id")}
                  className="input-field"
                >
                  {classes?.map((classItem, index) => (
                    <option key={index} value={classItem.id}>
                      {classItem.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.class && errors.class.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.session}>
                <FormLabel htmlFor="session">Class Session</FormLabel>
                <Select
                  id="session"
                  placeholder="Select Sesstion"
                  {...register("session")}
                  className="input-field"
                >
                  <option value="MORNING">Morning</option>
                  <option value="EVENING">Evening</option>
                </Select>
                <FormErrorMessage>
                  {errors.session && errors.session.message}
                </FormErrorMessage>
              </FormControl>
              {(user?.role === "ADMIN" || user?.role === "MANAGER") && (
                <FormControl isInvalid={errors.trainer_id}>
                  <FormLabel htmlFor="trainer">Trainer</FormLabel>
                  <Skeleton isLoaded={!isLoadingTrainers}>
                  <Select
                    id="trainer_id"
                    placeholder="Select Trainer "
                    {...register("trainer_id")}
                    className="input-field"
                  >
                    {trainers?.map((trainerItem) => (
                      <option key={trainerItem.id} value={trainerItem.id}>
                        {trainerItem.name}
                      </option>
                    ))}
                  </Select>
                  </Skeleton>
                  <FormErrorMessage>
                    {errors.trainer_id && errors.trainer_id.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={errors.class_status}>
                <FormLabel htmlFor="class_status">Class Status <span className="text-red-500">*</span></FormLabel>
                <Select
                  id="class_status"
                  placeholder="Select Class Status"
                {...register("class_status", {
                  required: "This is required",
                })}
                  className="input-field"
                >
                  <option value="PENDING">Pending</option>
                  <option value="ONGOING">Ongoing</option>
                  <option value="COMPLETED">Completed</option>
                </Select>
                <FormErrorMessage>
                  {errors.class_status && errors.class_status.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.class_start_date}>
                <div className="flex flex-row space-x-4">
                  <div className="flex flex-col">
                    <FormLabel htmlFor="class_start_date">
                      Class Start Date
                    </FormLabel>
                    <DatePicker
                      id="class_start_date"
                      selected={startDate}
                      onChange={handleDateChange}
                      placeholderText="Select Class Start Date"
                      dateFormat="yyyy-MM-dd"
                      className="input-field"
                      customInput={<Input />}
                    />
                    <FormErrorMessage>
                      {errors.class_start_date &&
                        errors.class_start_date.message}
                    </FormErrorMessage>
                  </div>
                  <div className="flex flex-col">
                    <FormLabel htmlFor="complete_date">
                      Class End Date
                    </FormLabel>
                    <DatePicker
                      id="complete_date"
                      selected={endDate}
                      onChange={handleEndDateChange}
                      placeholderText="Select Class End Date"
                      dateFormat="yyyy-MM-dd"
                      className="input-field"
                      customInput={<Input />}
                    />
                  </div>
                </div>
              </FormControl>
              <FormControl isInvalid={errors.training_benefit}>
                <FormLabel htmlFor="training_benefit">
                  Training Benefit{" "}
                </FormLabel>
                <Textarea
                  id="training_benefit"
                  placeholder="training_benefit"
                  {...register("training_benefit")} // Register the textarea with react-hook-form
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.training_benefit && errors.training_benefit.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.attachment}>
                <FormLabel htmlFor="attachment">
                  Form Attachment (PDF only)
                </FormLabel>
                <Input
                  id="attachment"
                  type="file"
                  accept="application/pdf"
                  {...register("attachment", {})}
                  className="input-field"
                />
                <FormErrorMessage>
                  {errors.attachment && errors.attachment.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4}>
                <div className="flex flex-row space-x-4">
                  <div className="flex flex-col">
                    <FormLabel htmlFor="became_self_employed">
                      Became Self Employed
                    </FormLabel>
                    <Switch
                      id="became_self_employed"
                      isChecked={becameSelfEmployed}
                      onChange={() =>
                        setBecameSelfEmployed(!becameSelfEmployed)
                      }
                      colorScheme="teal"
                    />
                  </div>
                  <div className="flex flex-col">
                    <FormLabel htmlFor="is_package_received">
                      Is Package Received
                    </FormLabel>
                    <Switch
                      id="is_package_received"
                      isChecked={isPackageReceived}
                      onChange={() => setIsPackageReceived(!isPackageReceived)}
                      colorScheme="teal"
                    />
                  </div>
                </div>
              </FormControl>
            </div>
            <div className="mt-8 flex flex-row-reverse">
              <Button
                colorScheme="blue"
                onClick={() => {
                  onClose();
                  reset();
                  setStartDate(null);
                  setEndDate(null);
                  setSelectedLearing([]);
                  setSelectedMain([]);
                  setSelectedOutA([]);
                  setSelectedHomeA([]);
                }}
              >
                Close
              </Button>
              <Button
                colorScheme="teal"
                isLoading={isSubmitting || isLoading}
                type="submit"
                className=" mr-2"
              >
                Submit
              </Button>
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AddVTBeneficiary;
