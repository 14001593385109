import React from 'react';
import { Select, Button, ButtonGroup, Text } from '@chakra-ui/react';

function CustomPagination({ pageSize, onPageSizeChange, current, onPageChange, total }) {
  const totalPages = Math.ceil(total / pageSize);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page, pageSize);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    let startPage, endPage;

    if (totalPages <= 4) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (current <= 2) {
        startPage = 1;
        endPage = 4;
      } else if (current + 1 >= totalPages) {
        startPage = totalPages - 3;
        endPage = totalPages;
      } else {
        startPage = current - 1;
        endPage = current + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          variant={i === current ? 'solid' : 'outline'}
          colorScheme={i === current ? 'blue' : 'gray'}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>
      );
    }
    return pages;
  };

  return (
    <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="flex justify-between p-4">
        <div className="flex items-center">
          <Select value={pageSize} onChange={(e) => onPageSizeChange(parseInt(e.target.value))} style={{ width: 80, marginRight: '8px' }}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={total}>All</option>
          </Select>
        </div>
        <div className="flex items-center space-x-3">
          <ButtonGroup variant="outline" spacing="1">
            <Button
              onClick={() => handlePageChange(current - 4)}
              isDisabled={current <= 4}
            >
              &laquo; Prev 4
            </Button>
            <Button
              onClick={() => handlePageChange(current - 1)}
              isDisabled={current === 1}
            >
              Previous
            </Button>
            {renderPageNumbers()}
            <Button
              onClick={() => handlePageChange(current + 1)}
              isDisabled={current === totalPages}
            >
              Next
            </Button>
            <Button
              onClick={() => handlePageChange(current + 4)}
              isDisabled={current > totalPages - 4}
            >
              Next 4 &raquo;
            </Button>
          </ButtonGroup>
          <Text ml="5">Total Record: {total}</Text>
        </div>
      </div>
    </div>
  );
}

export default CustomPagination;
