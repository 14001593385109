import { getRequest, deleteRequest ,postRequest } from "utils/http";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

// read data
export const useGetLegalAdvisorsData = (current, pageSize , searchValue , status, filterDateRange, gender, branch) => {
    return useQuery({
      queryKey: ["fetch-legalAdvisors", current, pageSize , searchValue , status, filterDateRange, gender, branch],
      queryFn: () => getLegalAdvisorsData(current, pageSize , searchValue , status, filterDateRange, gender, branch),
    });
};

export const getLegalAdvisorsData = async (current, pageSize , searchValue , status, filterDateRange, gender, branch) => {
    return await getRequest("app/legalAdvisors", {
      current: current,
      pageSize: pageSize,
      searchValue: searchValue,
      status,
      filterDateRange,
      gender, branch
    });
};

// get Advisor 
export const getAdvisors = async () => {
  return await getRequest("app/getAdvisors");
}; 
export function useGetAdvisors() {
  return useQuery({
    queryKey: ["fetch-getAdvisors"],
    queryFn: () => getAdvisors(),
  });
}
// create
export const legalAdvisorCreate = (params) => {
  return postRequest(`app/legalAdvisors`, params);
};
export const useLegalAdvisorCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => legalAdvisorCreate(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-legalAdvisors"]);
    },
  });
};
// Edit
export const updateLegalAdvisor = async (params) => {
  return postRequest(`app/legalAdvisors/${params.get("id")}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const useUpdateLegalAdvisor = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => updateLegalAdvisor(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-legalAdvisors"]);
    },
  });
};
// delete
export const deleteLegalAdvisor = async (ids) => {
    return deleteRequest(`app/deleteLegalAdvisors`, {
      params: {
        ids: ids.join(","),
      },
    });
  };
  
  export const useMultiDelete = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (ids) => deleteLegalAdvisor(ids),
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-legalAdivsors"]);
      },
    });
  };
  
  export const useSingleDelete = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (id) => singleDelete(id),
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-legalAdvisor"]);
      },
    });
  };
  
  const singleDelete = async (id) => {
    return deleteRequest(`app/legalAdvisors/${id}`, {
      params: {
        id,
      },
    });
  };


 