import { getRequest} from "utils/http";
import { useQuery} from '@tanstack/react-query';

// read data
// employee, pssBeneficairy , legalAdvisor ,vtBenenficiary, mnEvaluations, fDoctors

export const useDashboard = (branch ,year, dateRange) => {
    return useQuery({
      queryKey: ["fetch-dashboad",branch ,year, dateRange],
      queryFn: () => getDashboard(branch ,year, dateRange),
    });
};
export const getDashboard = async (branch ,year, dateRange) => {
    return await getRequest("app/dashboard" ,{
      branch ,year, dateRange
    });
};
export const useGetTotalRecords = () => {
    return useQuery({
      queryKey: ["fetch-dashboad" ],
      queryFn: () => getTotalRecords(),
    });
};
export const getTotalRecords = async () => {
    return await getRequest("app/totalRecords");
};