// queries/vt_beneficiary.query.js
import { getRequest, deleteRequest ,postRequest } from "utils/http";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

// read data
export const useGetVTBeneficiaryData = (current, pageSize , searchValue, status, filterDateRange, gender, branch) => {
    return useQuery({
      queryKey: ["fetch-vtrainers", current, pageSize , searchValue, status, filterDateRange, gender, branch],
      queryFn: () => getVTBeneficiaryData(current, pageSize , searchValue, status, filterDateRange, gender, branch),
    });
};

export const getVTBeneficiaryData= async (current, pageSize , searchValue, status, filterDateRange, gender, branch) => {
    return await getRequest("app/vtrainers", {
      current: current,
      pageSize: pageSize,
      searchValue: searchValue,
      status:status,
      filterDateRange,
      gender,
      branch
    });
};

// delete
export const deleteVTrainers = async (ids) => {
    return deleteRequest(`app/deleteVTrainers`, {
      params: {
        ids: ids.join(","),
      },
    });
  };
  
  export const useMultiDelete = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (ids) => deleteVTrainers(ids),
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-vtrainers"]);
      },
    });
  };
  
  export const useSingleDelete = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (id) => singleDelete(id),
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-vtrainers"]);
      },
    });
  };
  
  const singleDelete = async (id) => {
    return deleteRequest(`app/vtrainers/${id}`, {
      params: {
        id,
      },
    });
  };
  // create
  export const vtrainersCreate = (params) => {
    return postRequest(`app/vtrainers`, params);
  };
  export const useVTrainersCreate = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (params) => vtrainersCreate(params),
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-vtrainers"]);
      },
    });
  };
  // Edit
  export const updateVTrainers = async (params) => {
    return postRequest(`app/vtrainers/${params.get("id")}`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };
  
  export const useUpdateVTrainers = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (params) => updateVTrainers(params),
      onSuccess: () => {
        queryClient.invalidateQueries(["fetch-vtrainers"]);
      },
    });
  };

  export const useGetClasses = () => {
    return useQuery({
        queryFn: () => GetClasses(),
    });
};

  export const GetClasses = async () => {
    return await getRequest("app/getClass");
  };

  export const useGetTrainers = (branch_id) => {
    return useQuery({
        queryKey: ["trainers", branch_id], // Adding a query key can help React Query distinguish between different queries
        queryFn: () => GetTrainers(branch_id),
        onError: (error) => {
            console.error('Error fetching trainers:', error);
        }
    });
};


export const GetTrainers = async (branch_id) => {
  return await getRequest("app/getTrainers" , {branch_id});
};
