import React from "react";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ChakraProvider } from '@chakra-ui/react'



import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";

import NotFound from "views/not-found/NotFound";
import { ErrorBoundary } from "components/Error/ErrorBoundry";
const queryClient = new QueryClient();
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <ErrorBoundary>
          <Routes>
            <Route caseSensitive path="/" element={<AuthLayout />} />
            <Route caseSensitive path="auth/*" element={<AuthLayout />} />
            <Route caseSensitive path="admin/*" element={<AdminLayout />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </ErrorBoundary>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
