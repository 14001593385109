import React from 'react';
import Chart from 'react-apexcharts';

const GraphStatusReports = ({ data, title, colors }) => {
  const labels = data.map(({ label }) => label);
  const values = data.map(({ value }) => parseFloat(value));

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4" style={{ padding: "15px" }}>{title}</h2>
      <Chart
        options={{
          plotOptions: {
            bar: {
              verticale: true,
            }
          },
          colors: colors,
          xaxis: {
            categories: labels,
          },
          legend: {
            show: true,
            position: "bottom",
          },
        }}
        series={[{ data: values }]}
        type="bar"
        width="100%"
        height="300px"
      />
    </div>
  );
};

export default GraphStatusReports;
