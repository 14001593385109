import React, { useEffect, useState } from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Divider,
  ModalFooter,
  ModalCloseButton,
  Textarea,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
  Heading,
  Img,
  AbsoluteCenter,
  useToast,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  StepSeparator,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import successSVG from "../../../assets/svg/success.svg";
import { usePssBeneficiaryCreate } from "queries/pss_beneficiary.query";
import { useGetDoctors } from "queries/pss_beneficiary.query";
import { useAuthStore } from "store/use-auth.store";

const steps = [
  { title: "Basic Info" },
  { title: "Case Info" },
  { title: "Doc Visit" },
  { title: "Result" },
  { title: "Done" },
];

const initialFormData = {
  name: "",
  father_name: "",
  registration_date: "",
  contact_type: "",
  session_duration: "",
  main_activity: "",
  closing_date: null,
  visitDate: null,
  result: "",
  case_attachment: null,
};

export default function PssBeneficiaryFormModal({
  isOpen,
  onClose,
  onSuccess,
  checkData
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [closeDate, setcloseDate] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(null);
  const [roomRecords, setRoomRecords] = useState([initialFormData]);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedTools, setSelectedTools] = useState([]);
  const isDuplicateTazkiraNumber = (value) => {
    let count = 0;
    for (let i = 0; i < checkData.length; i++) {
      if (checkData[i].tazkira_number === value) {
        count++;
      }
    }
    return count > 0;
  };
  const [
    selectedPsychologicalInterventions,
    setSelectedPsychologicalInterventions,
  ] = useState([]);
  const [selectedPsychologicalIssues, setSelectPsychologicalIssueType] =
    useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const {
    data: doctors
  } = useGetDoctors();
  const { user } = useAuthStore();
  const { mutate } = usePssBeneficiaryCreate();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm();
  const toast = useToast();
  const showToast = ({ status, description }) => {
    toast({
      title: status === "success" ? "Success" : "Error",
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      data.used_tool = selectedTools;
      data.appropriate_psychological_intervention =
        selectedPsychologicalInterventions;
      data.psychological_issue_type = selectedPsychologicalIssues;
      const formData = new FormData();

      roomRecords.forEach((record, index) => {
        const visitDate = record.visitDate;
        const formattedVisitDate = `${visitDate.getFullYear()}-${(
          visitDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${visitDate
          .getDate()
          .toString()
          .padStart(2, "0")}`;
        formData.append(`visit_date_${index}`, formattedVisitDate);
        formData.append(
          `session_duration_${index}`,
          parseInt(record.session_duration, 10) // Convert to integer here
        );
        formData.append(`main_activity_${index}`, record.main_activity);
        formData.append(`result_${index}`, record.result);
      });

      const formattedCloseDate = closeDate
        ? `${closeDate.getFullYear()}-${(closeDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${closeDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : "";
      formData.append("closing_date", formattedCloseDate);
      const formattedRegisterationDate = registrationDate
        ? `${registrationDate.getFullYear()}-${(registrationDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${registrationDate
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : "";
      formData.append("registration_date", formattedRegisterationDate);

      if (data.case_attachment) {
        formData.append("case_attachment", data.case_attachment[0]); // Append only the first file if multiple files are selected
      }
      if (data.form_attachment) {
        formData.append("form_attachment", data.form_attachment[0]); // Append only the first file if multiple files are selected
      }

      Object.entries(data).forEach(([key, value]) => {
        if (!data[key]) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [key]: "This field is required",
          }));
        }
        if (key !== "case_attachment") {
          if (key !== "form_attachment") {
            if (Array.isArray(value)) {
              formData.append(
                key,
                value.map((option) => option.value).join(",")
              );
            } else {
              formData.append(key, value);
            }
          }
        }
      });

      mutate(formData, {
        onSuccess: () => {
          setIsLoading(false);
          showToast({
            status: "success",
            description: "Pss Beneficiary was added successfully.",
          });
          onSuccess();

          handleNext();
          reset();
        },
        onError: (error) => {
          setIsLoading(false);
          showToast({
            status: "error",
            description: "Failed to add Pss Beneficiary.",
          });
        },
      });
    } catch (error) {
      setIsLoading(false);
      showToast({
        status: "error",
        description: "Failed to add Pss Beneficiary.",
      });
    }
  };

  const validateFamilyMembers = () => {
    const totalFamilyMembers = parseInt(getValues("total_family_members")) || 0;
    const maleFamilyMembers = parseInt(getValues("male_member")) || 0;
    const femaleFamilyMembers = parseInt(getValues("female_member")) || 0;
  
    if (maleFamilyMembers + femaleFamilyMembers !== totalFamilyMembers) {
      return "The sum of male and female family members should be equal to total family members";
    }
  
    return true;
  };
  

  const handleSessionDurationChange = (event, index) => {
    const valueAsString = event.target.value;
    const updatedRecords = [...roomRecords];
    updatedRecords[index].session_duration = valueAsString; // Keep it as string
    setRoomRecords(updatedRecords);
  };

  const handleMainActivityChange = (value, index) => {
    const updatedRecords = [...roomRecords];
    updatedRecords[index].main_activity = value;
    setRoomRecords(updatedRecords);
  };

  const handleResultChange = (value, index) => {
    const updatedRecords = [...roomRecords];
    updatedRecords[index].result = value;
    setRoomRecords(updatedRecords);
  };

  const handleDateChange = (date) => {
    setcloseDate(date);
  };
  const handleRegisterDateChange = (date) => {
    setRegistrationDate(date);
  };
  const handleVisitDateChange = (date, index) => {
    const updatedRecords = [...roomRecords];
    updatedRecords[index].visitDate = date;
    setRoomRecords(updatedRecords);
  };

  useEffect(() => {
    if (isOpen) {
      register("used_tool", { required: "This is required" });
      register("appropriate_psychological_intervention", {
        required: "This is required",
      });
      register("psychological_issue_type", { required: "This is required" });
    }
  }, [register, isOpen]);

  const handleNext = async () => {
    let fieldsToValidate = [];
    switch (activeStep) {
      case 0:
        fieldsToValidate = [
          "name",
          "father_name",
          "last_name",
          "gender",
          "age",
          "birth_place",
          "marital_status",
          "educational_degree",
          "job",
          "phone",
          "total_family_members",
          "male_member",
          "female_member",
          "branch_id",
          "address",
          "tazkira_number",
          'project',
        ];
        break;
      case 1:
        fieldsToValidate = [
          "registration_date",
          "contact_type",
          "used_tool",
          "intervention_type",
          "appropriate_psychological_intervention",
          "psychological_issue_type",
          "doctor_id",
          "case_description",
        ];
        break;
      case 2:
        // Validate if at least one roomRecord exists
        if (roomRecords.length === 0) {
          showToast({
            status: "error",
            description: "Please add at least one room record.",
          });
          return;
        }
        // Validate each roomRecord
        const isRoomRecordsValid = roomRecords.every((record) => {
          return (
            record.visitDate &&
            record.session_duration &&
            record.main_activity &&
            record.result
          );
        });
        if (!isRoomRecordsValid) {
          showToast({
            status: "error",
            description: "Please fill all fields in the room records.",
          });
          return;
        }
        break;
      case 3:
        fieldsToValidate = ["examination_result", "case_status"];
        break;
      default:
        break;
    }
    const isValid = await trigger(fieldsToValidate);
    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleAddRoom = () => {
    setRoomRecords((prevRecords) => [
      ...prevRecords,
      {
        ...initialFormData,
        visitDate: null,
        session_duration: "",
        main_activity: "",
        result: "",
      },
    ]);
  };

  const handleDeleteRoom = (index) => {
    setRoomRecords((prevRecords) => prevRecords.filter((_, i) => i !== index));
  };

  return (
    <>
      <Modal
        size="4xl"
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
         
          setFormData(initialFormData);
          setActiveStep(0);
          setcloseDate(null);
          setRoomRecords([]);
          setSelectPsychologicalIssueType([]);
          setSelectedTools([]);
          setRegistrationDate(null);
          setSelectedPsychologicalInterventions([]);
          reset();
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottomWidth="1px">
            Create New PSS Beneficiary
            <ModalCloseButton />
            <Divider className="mb-6 mt-3 w-full" />
            <Stepper index={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody
              style={{ height: "580px" }}
              maxHeight="580px"
              overflowY="auto"
              className="mb-8"
            >
              {activeStep === 0 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 mb-8 md:grid-cols-2"
                  style={{ height: "560px" }}
                >
                  <FormControl isInvalid={errors.name}>
                    <FormLabel htmlFor="name">
                      Name <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="name"
                      placeholder="Name"
                      {...register("name", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.father_name}>
                    <FormLabel htmlFor="father_name">
                      Father's Name <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="father_name"
                      placeholder="Father's Name"
                      {...register("father_name", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.father_name && errors.father_name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.age}>
                    <FormLabel htmlFor="age">
                      Age <span className="text-red-500">*</span>
                    </FormLabel>
                    <Input
                      id="age"
                      placeholder="Age"
                      {...register("age", {
                        required: "This is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid age",
                        },
                        maxLength: {
                          value: 3,
                          message: "Age should be at most 3 digits long",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.age && errors.age.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.gender}>
                    <FormLabel htmlFor="gender">
                      Gender <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="gender"
                      placeholder="Select Gender"
                      {...register("gender", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.gender && errors.gender.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.marital_status}>
                    <FormLabel htmlFor="marital_status">
                      Marital Status <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="marital_status"
                      placeholder="Select Marital Status"
                      {...register("marital_status", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="SINGLE">Single</option>
                      <option value="MARRIED">Married</option>
                      <option value="DIVORCED">Divorced</option>
                      <option value="WIDOW">Widow</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.marital_status && errors.marital_status.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.educational_degree}>
                    <FormLabel htmlFor="educational_degree">
                      Educational Degree <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="educational_degree"
                      placeholder="Select Educational Degree"
                      {...register("educational_degree", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="ILLITERATE">Illiterate</option>
                      <option value="PRIMARY">Primary</option>
                      <option value="SECONDARY">Secondary</option>
                      <option value="HIGHER">Higher</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.educational_degree &&
                        errors.educational_degree.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.address}>
                    <FormLabel htmlFor="address">
                      Address <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="address"
                      placeholder="Address"
                      {...register("address", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.address && errors.address.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.job}>
                    <FormLabel htmlFor="job">
                      Job 
                    </FormLabel>
                    <Input
                      id="job"
                      placeholder="Job"
                      {...register("job", {
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.job && errors.job.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.phone}>
                    <FormLabel htmlFor="phone">
                      Phone 
                    </FormLabel>
                    <Input
                      id="phone"
                      placeholder="Phone"
                      {...register("phone", {
                        pattern: {
                          value: /^[0-9+]+$/,
                          message: "Please enter only numbers and '+'",
                        },
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.phone && errors.phone.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.tazkira_number}>
                    <FormLabel htmlFor="tazkira_number">
                      Tazkira Number 
                    </FormLabel>
                    <Input
                      id="tazkira_number"
                      placeholder="Tazkira Number"
                      {...register("tazkira_number", {
                        pattern: {
                          value: /^[0-9-]+$/,
                          message: "Please enter only numbers and '-'",
                        },
                        validate: (value) => {
                          // If the value is provided, check for uniqueness
                          if (value && value.trim() !== "") {
                            return isDuplicateTazkiraNumber(value) ? "This tazkira number already exists." : true;
                          }
                          // If no value is provided, it's valid
                          return true;
                        }
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.tazkira_number && errors.tazkira_number.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.total_family_members}>
                    <FormLabel htmlFor="total_family_members">
                      Total Family Member{" "}
                      <span className="text-red-500">*</span>
                    </FormLabel>

                    <NumberInput max={100} min={1}>
                      <NumberInputField
                        id="total_family_members"
                        placeholder=""
                        {...register("total_family_members", {
                          required: "This is required",
                        })}
                        className="input-field"
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>
                      {errors.total_family_members &&
                        errors.total_family_members.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.female_member}>
                    <FormLabel htmlFor="female_member">
                      Female Member 
                    </FormLabel>

                    <NumberInput max={100} min={0}>
                      <NumberInputField
                        id="female_member"
                        placeholder=""
                        {...register("female_member", {
                          validate: (value) => {
                            if (value !== undefined && value !== "") {
                              const parsedValue = parseInt(value);
                              if (isNaN(parsedValue)) {
                                return "Value must be a number";
                              }
                              if (parsedValue > parseInt(getValues("total_family_members"))) {
                                return "Female members should be equal to or less than total family members";
                              }
                              return validateFamilyMembers(value);
                            }
                            return true; // No error if the field is empty
                          },
                        })}
                        className="input-field"
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>
                      {errors.female_member && errors.female_member.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.male_member}>
                    <FormLabel htmlFor="male_member">
                      Male Member 
                    </FormLabel>

                    <NumberInput max={100} min={1}>
                      <NumberInputField
                        id="male_member"
                        placeholder=""
                        {...register("male_member", {
                         
                          validate: (value) => {
                            if (value !== undefined && value !== "") {
                              const parsedValue = parseInt(value);
                              if (isNaN(parsedValue)) {
                                return "Value must be a number";
                              }
                              return validateFamilyMembers(value);
                            }
                            return true; // No error if the field is empty
                          },
                        })}
                        className="input-field"
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>
                      {errors.male_member && errors.male_member.message}
                    </FormErrorMessage>
                  </FormControl>

                 
                  <FormControl className="mb-5" isInvalid={errors.project}>
                    <FormLabel htmlFor="project">
                      Project <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="project"
                      placeholder="Select project"
                      {...register("project", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="UN WOMEN">UN WOMEN</option>
                      <option value="UNDP">UNDP</option>
                      <option value="IDLO">IDLO</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.project && errors.project.message}
                    </FormErrorMessage>
                  </FormControl>
                  {(user?.role === "ADMIN" ||
                    (user?.role === "MANAGER" &&
                      user?.branch?.branch_name === "KABUL")) && (
                    <FormControl isInvalid={errors.branch_id}>
                      <FormLabel htmlFor="branch_id">
                        Branch <span className="text-red-500">*</span>
                      </FormLabel>
                      <Select
                        id="branch_id"
                        placeholder="Select Branch"
                        {...register("branch_id", {
                          required: "This is required",
                        })}
                        className="input-field"
                      >
                        <option value="1">Kabul</option>
                        <option value="2">Bamyan</option>
                        <option value="3">Paktia</option>
                      </Select>
                      <FormErrorMessage>
                        {errors.branch_id && errors.branch_id.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  <FormControl isInvalid={errors.form_attachment}>
                    <FormLabel htmlFor="form_attachment">
                      Form Attachment (PDF only)
                    </FormLabel>
                    <Input
                      id="form_attachment"
                      type="file"
                      accept="application/pdf"
                      {...register("form_attachment", {})}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.form_attachment && errors.form_attachment.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
              )}

              {activeStep === 1 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <FormControl isInvalid={errors.registration_date}>
                    <FormLabel htmlFor="registration_date">
                      Registration Date
                    </FormLabel>
                    <DatePicker
                      id="registration_date"
                      selected={registrationDate}
                      onChange={handleRegisterDateChange}
                      placeholderText="Select Registration Date"
                      dateFormat="yyyy-MM-dd"
                      className="input-field"
                      customInput={<Input />}
                    />
                    <FormErrorMessage>
                      {errors.registration_date &&
                        errors.registration_date.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.contact_type}>
                    <FormLabel htmlFor="contact_type">
                      Contact Type <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="contact_type"
                      placeholder="Select contact type"
                      {...register("contact_type", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="PHONE">PHONE</option>
                      <option value="IN PERSON">IN PERSON</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.contact_type && errors.contact_type.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.used_tool}>
                    <FormLabel htmlFor="used_tool">
                      Used Tool <span className="text-red-500">*</span>
                    </FormLabel>
                    <ReactSelect
                      id="used_tool"
                      placeholder="Select tool(s)"
                      value={selectedTools}
                      onChange={(selectedOptions) => {
                        setSelectedTools(selectedOptions);
                        setValue("used_tool", selectedOptions);
                      }}
                      isMulti
                      className={`${
                        errors.used_tool
                          ? "border-3 rounded-md border-red-500"
                          : ""
                      } border`}
                      options={[
                        { value: "GHQ-12", label: "GHQ-12" },
                        { value: "INTERVIEW", label: "DIAGNOSTIC INTERVIEW" },
                      ]}
                    />
                    <FormErrorMessage>
                      {errors.used_tool && errors.used_tool.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.intervention_type}>
                    <FormLabel htmlFor="intervention_type">
                      Intervention Type <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="intervention_type"
                      placeholder="Select intervention type"
                      {...register("intervention_type", {
                        required: "This is required",
                      })}
                      className={`${
                        errors.intervention_type
                          ? "border-3 rounded-md border-red-500"
                          : ""
                      } border`}
                    >
                      <option value="INDIVIDUAL">INDIVIDUAL</option>
                      <option value="GROUP">GROUP</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.intervention_type &&
                        errors.intervention_type.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={errors.appropriate_psychological_intervention}
                  >
                    <FormLabel htmlFor="appropriate_psychological_intervention">
                      Appropriate Psychological Intervention{" "}
                      <span className="text-red-500">*</span>
                    </FormLabel>
                    <ReactSelect
                      id="appropriate_psychological_intervention"
                      placeholder="Select psychological intervention"
                      value={selectedPsychologicalInterventions}
                      onChange={(selectedOptions) => {
                        setSelectedPsychologicalInterventions(selectedOptions);
                        setValue(
                          "appropriate_psychological_intervention",
                          selectedOptions
                        );
                      }}
                      isMulti
                      className={`${
                        errors.appropriate_psychological_intervention
                          ? "border-3 rounded-md border-red-500"
                          : ""
                      } border`}
                      options={[
                        {
                          value: "PSYCHOTHERAPY COUNSELING",
                          label: "PSYCHOTHERAPY COUNSELING",
                        },
                        {
                          value: "FAMILY COUNSELING",
                          label: "FAMILY COUNSELING",
                        },
                        { value: "PM+", label: "PM+" },
                        { value: "TEACHING SKILLS", label: "TEACHING SKILLS" },
                        { value: "REFERRAL", label: "REFERRAL" },
                      ]}
                    />
                    <FormErrorMessage>
                      {errors.appropriate_psychological_intervention &&
                        errors.appropriate_psychological_intervention.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.psychological_issue_type}>
                    <FormLabel htmlFor="psychological_issue_type">
                      Psychological Issue Type{" "}
                      <span className="text-red-500">*</span>
                    </FormLabel>
                    <ReactSelect
                      id="psychological_issue_type"
                      placeholder="Select psychological issue types"
                      value={selectedPsychologicalIssues}
                      onChange={(selectedOptions) => {
                        setSelectPsychologicalIssueType(selectedOptions);
                        setValue("psychological_issue_type", selectedOptions);
                      }}
                      className={`${
                        errors.psychological_issue_type
                          ? "border-3 rounded-md border-red-500"
                          : ""
                      } border`}
                      isMulti
                      options={[
                        { value: "DEPRESSION", label: "DEPRESSION" },
                        { value: "ANXIETY", label: "ANXIETY" },
                        { value: "PTSD", label: "PTSD" },
                        {
                          value: "LACK OF LEARNING",
                          label: "LACK OF LEARNING",
                        },
                        { value: "STRESS", label: "STRESS" },
                        { value: "PSYCHOSIS", label: "PSYCHOSIS" },
                        { value: "ECONOMIC ISSUES", label: "ECONOMIC ISSUES" },
                        { value: "OCD", label: "OCD" },
                      ]}
                    />
                    <FormErrorMessage>
                      {errors.psychological_issue_type &&
                        errors.psychological_issue_type.message}
                    </FormErrorMessage>
                  </FormControl>

                  {(user.role === "ADMIN" || user.role === "MANAGER") && (
                    <FormControl isInvalid={errors.doctor_id}>
                      <FormLabel htmlFor="doctor_id">
                        Doctor <span className="text-red-500">*</span>
                      </FormLabel>
                      <Select
                        id="doctor_id"
                        placeholder="Select the doctor"
                        {...register("doctor_id", {
                          required: "This is required",
                        })}
                        className="input-field"
                      >
                        {doctors.map((doctorItem) => (
                          <option key={doctorItem.id} value={doctorItem.id}>
                            {doctorItem.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.doctor_id && errors.doctor_id.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  <FormControl isInvalid={errors.case_description}>
                    <FormLabel htmlFor="case_description">
                      Case Description
                    </FormLabel>
                    <Textarea
                      id="case_description"
                      placeholder="Case Description ..."
                      {...register("case_description", {})}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.case_description &&
                        errors.case_description.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
              )}

              {activeStep === 2 && (
                <>
                  <div style={{ height: "500px" }}>
                    <div>
                      {roomRecords.map((record, index) => (
                        <>
                          <div className="my-2">
                            <Heading
                              as="h5"
                              size="sm"
                              className="text-blue-600"
                            >
                              Meeting: {index + 1}
                            </Heading>
                          </div>

                          <div
                            key={index}
                            className="mb-2 grid grid-cols-1  gap-x-4 md:grid-cols-2"
                            style={{ height: "250px" }}
                          >
                            <FormControl isInvalid={errors.visit_date}>
                              <FormLabel htmlFor={`visit_date_${index}`}>
                                Visit Date{" "}
                                <span className="text-red-500">*</span>
                              </FormLabel>
                              <DatePicker
                                selected={record.visitDate}
                                onChange={(date) =>
                                  handleVisitDateChange(date, index)
                                }
                                placeholderText="Select Visit Date"
                                dateFormat="yyyy-MM-dd"
                                className="input-field"
                                customInput={<Input />}
                              />
                              <FormErrorMessage>
                                {errors.visit_date && errors.visit_date.message}
                              </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.session_duration}>
                              <FormLabel htmlFor="session_duration">
                                Session Duration{" "}
                                <span className="text-red-500">*</span>
                              </FormLabel>
                              <Input
                                value={record.session_duration}
                                onChange={(value) =>
                                  handleSessionDurationChange(value, index)
                                }
                                id="session_duration"
                                placeholder="Insert duration in minute"
                                className="input-field"
                              />
                              <FormErrorMessage>
                                {errors.session_duration &&
                                  errors.session_duration.message}
                              </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.main_activity}>
                              <FormLabel htmlFor="main_activity">
                                Main Activities{" "}
                                <span className="text-red-500">*</span>
                              </FormLabel>
                              <Textarea
                                value={record.main_activity}
                                onChange={(e) =>
                                  handleMainActivityChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                id="main_activity"
                                placeholder="Main Activities ..."
                                className="input-field"
                              />
                              <FormErrorMessage>
                                {errors.main_activity &&
                                  errors.main_activity.message}
                              </FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.result}>
                              <FormLabel htmlFor="result">
                                Result <span className="text-red-500">*</span>
                              </FormLabel>
                              <Textarea
                                value={record.result}
                                onChange={(e) =>
                                  handleResultChange(e.target.value, index)
                                }
                                id="result"
                                placeholder="Result ..."
                                className="input-field"
                              />

                              <FormErrorMessage>
                                {errors.result && errors.result.message}
                              </FormErrorMessage>
                            </FormControl>
                            <div maxWidth="full">
                              {index !== 0 && (
                                <Button
                                  variant="link"
                                  colorScheme="red"
                                  onClick={() => handleDeleteRoom(index)}
                                >
                                  <DeleteIcon />
                                </Button>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                    <Button
                      colorScheme="teal"
                      className="mb-5"
                      style={{ float: "right" }}
                      onClick={handleAddRoom}
                    >
                      Add Meeting
                    </Button>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <FormControl isInvalid={errors.examination_result}>
                    <FormLabel htmlFor="examination_result">
                      Examination Result <span className="text-red-500">*</span>
                    </FormLabel>
                    <Textarea
                      id="examination_result"
                      placeholder="Examination Result ..."
                      {...register("examination_result", {
                        required: "This is required",
                      })}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.examination_result &&
                        errors.examination_result.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.case_status}>
                    <FormLabel htmlFor="case_status">
                      Case Status <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select
                      id="case_status"
                      placeholder="Select Case Status"
                      {...register("case_status", {
                        required: "This is required",
                      })}
                      className="input-field"
                    >
                      <option value="IN PROCESS">In Process</option>
                      <option value="CLOSED">Closed</option>
                      <option value="REFERRED">Referred</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.case_status && errors.case_status.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.closing_date}>
                    <FormLabel htmlFor="closing_date">Closing Date</FormLabel>
                    <DatePicker
                      id="closing_date"
                      selected={closeDate}
                      onChange={handleDateChange}
                      placeholderText="Select Closing Date"
                      dateFormat="yyyy-MM-dd"
                      className="input-field"
                      customInput={<Input />}
                    />
                    <FormErrorMessage>
                      {errors.closing_date && errors.closing_date.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.referred_organization}>
                    <FormLabel htmlFor="referred_organization">
                      Referred Organization
                    </FormLabel>
                    <Input
                      id="referred_organization"
                      placeholder="Referred Organization"
                      {...register("referred_organization", {})}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.referred_organization &&
                        errors.referred_organization.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.referred_description}>
                    <FormLabel htmlFor="referred_description">
                      Referred Description
                    </FormLabel>
                    <Textarea
                      id="referred_description"
                      placeholder="Referred Description ..."
                      {...register("referred_description", {})}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.referred_description &&
                        errors.referred_description.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.case_attachment}>
                    <FormLabel htmlFor="case_attachment">
                      Case Attachment (PDF only)
                    </FormLabel>
                    <Input
                      id="case_attachment"
                      type="file"
                      accept="application/pdf" // Limit to accept only PDF files
                      {...register("case_attachment", {})}
                      className="input-field"
                    />
                    <FormErrorMessage>
                      {errors.case_attachment && errors.case_attachment.message}
                    </FormErrorMessage>
                  </FormControl>
                </div>
              )}

              {activeStep === 4 && (
                <div
                  className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2"
                  style={{ height: "500px" }}
                >
                  <AbsoluteCenter>
                    <Img
                      alt="success"
                      width={300}
                      height={300}
                      src={successSVG}
                    />
                    <Heading size={"xs"}>
                      Pss Beneficiary has been successfully recorded.
                    </Heading>
                  </AbsoluteCenter>
                </div>
              )}
            </ModalBody>

            <ModalFooter className="">
              {/* Conditional rendering of buttons based on active step */}
              {/* Back button */}
              {activeStep !== 0 && activeStep !== 4 && (
                <Button onClick={handleBack}>Back</Button>
              )}
              {/* Save button on the 4th step */}
              {activeStep === 3 && (
                <Button
                  colorScheme="teal"
                  isLoading={isSubmitting || isLoading} 
                  type="submit"
                  ml={3}
                >
                  Submit
                </Button>
              )}
              {/* Next button */}
              {activeStep < steps.length - 1 && activeStep !== 3 && (
                <Button
                  colorScheme="blue"
                  ml={3}
                  onClick={handleNext}
                  disabled={Object.values(fieldErrors).some((error) => !!error)}
                >
                  Next
                </Button>
              )}
              {/* Close button on the last step */}
              {activeStep === steps.length - 1 && (
                <Button
                  onClick={() => {
                   
                    setFormData(initialFormData);
                    setActiveStep(0);
                    setcloseDate(null);
                    setRoomRecords([]);
                    setSelectPsychologicalIssueType([]);
                    setSelectedTools([]);
                    setRegistrationDate(null);
                    setSelectedPsychologicalInterventions([]);
                    reset();
                    onClose();
                  }}
                  ml={3}
                >
                  Close
                </Button>
              )}
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
