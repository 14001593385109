// queries/employee.query.js
import { getRequest, deleteRequest ,postRequest } from "utils/http";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

// read data
export const useGetEmployeesData = (current, pageSize , searchValue , status, filterDateRange, gender, branch) => {
    return useQuery({
      queryKey: ["fetch-employees", current, pageSize , searchValue , status, filterDateRange, gender, branch],
      queryFn: () => getEmployeesData(current, pageSize , searchValue ,status, filterDateRange, gender, branch),
    });
};

export const getEmployeesData = async (current, pageSize , searchValue ,status, filterDateRange, gender, branch) => {
    return await getRequest("app/employees", {
      current: current,
      pageSize: pageSize,
      searchValue: searchValue,
      status:status,
      filterDateRange,
      gender,
      branch

    });
};

// delete
export const deleteEmployees = async (ids) => {
  return deleteRequest(`app/deleteEmployees`, {
    params: {
      ids: ids.join(","),
    },
  });
};

export const useMultiDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (ids) => deleteEmployees(ids),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-employees"]);
    },
  });
};

export const useSingleDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => singleDelete(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-employees"]);
    },
  });
};

const singleDelete = async (id) => {
  return deleteRequest(`app/employees/${id}`, {
    params: {
      id,
    },
  });
};
// create
export const employeeCreate = (params) => {
  return postRequest(`app/employees`, params);
};
export const useEmployeeCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => employeeCreate(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-employees"]);
    },
  });
};
// Edit
export const updateEmployee = async (params) => {
  return postRequest(`app/employees/${params.get("id")}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useUpdateEmployee = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params) => updateEmployee(params),
    onSuccess: () => {
      queryClient.invalidateQueries(["fetch-employees"]);
    },
  });
};

//
export const useCheckUser = () => {
  return useQuery({
    queryKey: ["fetch-employees"],
    queryFn: () => getUsesrs(),
  });
}

export const getUsesrs = async () => {
  return await getRequest("app/getUsers");
}; 