import { Box, Card } from '@chakra-ui/react';
import React from 'react';
const colorToGradient = {
    blue: 'from-blue-400 to-blue-600',
    green: 'from-green-400 to-green-600',
    red: 'from-red-400 to-red-600',
    purple: 'from-purple-400 to-purple-600',
    cyan: 'from-cyan-400 to-cyan-600',
    orange: 'from-orange-600 to-orange-700',
};
function Statistic({ title, femaleCount, maleCount, otherCount, totalCount ,bgColor}) {
    
    const gradient = colorToGradient[bgColor] || colorToGradient['blue']; 
    return (
        <div className="grid grid-cols-1 gap-5 rounded-[20px] shadow-md">
            <Card className={`p-6 rounded-lg shadow-lg text-white bg-gradient-to-br ${gradient}`}>
                <h2 className="text-xl font-semibold mb-4">{title}</h2>
                <div className="grid grid-cols-3 gap-4 mt-7">
                    {/* Female */}
                    <Box className="flex flex-col items-center justify-center rounded-lg bg-white bg-opacity-25 p-2">
                        <span className="text-lg font-semibold text-gray-200">Female</span>
                        <span className="text-2xl font-bold">{femaleCount}</span>
                    </Box>
                    {/* Male */}
                    <Box className="flex flex-col items-center justify-center rounded-lg bg-white bg-opacity-25 p-2">
                        <span className="text-lg font-semibold text-gray-200">Male</span>
                        <span className="text-2xl font-bold">{maleCount}</span>
                    </Box>
                    {/* Other */}
                    <Box className="flex flex-col items-center justify-center rounded-lg bg-white bg-opacity-25 p-2">
                        <span className="text-lg font-semibold text-gray-200">Other</span>
                        <span className="text-2xl font-bold">{otherCount}</span>
                    </Box>
                    {/* Total */}
                    <div className="flex flex-col items-center justify-center rounded-lg bg-white bg-opacity-25 p-2 col-span-3">
                        <span className="text-lg font-semibold text-gray-200">Total</span>
                        <span className="text-4xl font-bold">{totalCount}</span> {/* Decreased font size for total */}
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Statistic;
